import { Box, Typography } from "@mui/material";
import React from "react";
import StarsIcons from "./StarsIcons";

const CompanyRating = ({ ratings }) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "repeat(auto-fit, 1fr)", sm: "1fr 1fr" },
        gap: "1rem",
      }}
    >
      {/* COMPANY INFORMATION */}

      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
          <Typography fontWeight={700} flexGrow={1}>
            {ratings.name}
          </Typography>
          <Typography>{ratings.address}</Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
          <Typography fontWeight={700}>Current Rating:</Typography>
          <Typography>{ratings.rating}</Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
          <Typography fontWeight={700}>No. of Reviews:</Typography>
          <Typography>{ratings.reviews}</Typography>
        </Box>
      </Box>

      {/* STARS */}

      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <StarsIcons stars={5} value={ratings?.five_star ?? 0} />
        <StarsIcons stars={4} value={ratings?.four_star ?? 0} />
        <StarsIcons stars={3} value={ratings?.three_star ?? 0} />
        <StarsIcons stars={2} value={ratings?.two_star ?? 0} />
        <StarsIcons stars={1} value={ratings?.one_star ?? 0} />
      </Box>
    </Box>
  );
};

export default CompanyRating;
