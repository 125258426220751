import axios from "axios";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getHeader } from "../shared/Authorization";
import { ToastDataContext } from "../component/UnderRoot";
import { ErrorHandler } from "../shared/CustomFunc";

const ROOT_API_URL = process.env.REACT_APP_API_URL;
const BRANDS_URL = ROOT_API_URL + "/brands";

export const useGetBrands = () => {
  return useQuery({
    queryKey: ["brands"],
    queryFn: async () => {
      const result = await axios.get(BRANDS_URL, {
        headers: getHeader(),
      });
      return result.data.brands;
    },
    initialData: [],
    refetchOnReconnect: true,
  });
};

export const useGetSelectedBrand = (id) => {
  return useQuery({
    enabled: id ? true : false,
    queryKey: ["brand", { id }],
    queryFn: async ({ signal }) => {
      const result = await axios.get(BRANDS_URL + `/${id}`, {
        headers: getHeader(),
        signal,
      });
      return result.data.brand;
    },
    refetchOnReconnect: true,
    staleTime: Infinity,
  });
};

export const useCreateBrand = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    mutationFn: (payload) => {
      return axios.post(BRANDS_URL, payload, { headers: getHeader() });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["brands"]);
      customToast("Successfully created brand", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useUpdateBrand = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    mutationFn: (arg) => {
      return axios.patch(BRANDS_URL + `/${arg.id}`, arg.payload, {
        headers: getHeader(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["brands"]);
      queryClient.invalidateQueries(["brand"]);
      customToast("Successfully updated brand", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useUpdateBrandImage = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    mutationFn: (payload) => {
      return axios.post(BRANDS_URL + `/upload/${payload.id}`, payload.payload, {
        headers: getHeader(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["brand"]);
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useDeleteBrand = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    mutationFn: (payload) => {
      return axios.delete(BRANDS_URL + `/${payload.id}`, {
        headers: getHeader(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["brands"]);
      customToast("Deleted brand", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};
