/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { dateCleaner, timeExtract } from "../../../shared/CustomFunc";
import TextEditor from "../../../sharedComponent/TextEditor";
import { cleanReadingMessage, fetchMessages, fetchReadingMessageInbox, fetchReadingMessageSent } from "../../../store/messagesSlice";
import './MessageRead.scss';

const MessageRead = () => {
    const params = useParams()
    const navigate = useNavigate()

    const user = useSelector( state => state.user.value )
    const dispatch = useDispatch()
    const readingMessage = useSelector( state => state.messages.value.reading )

    const [ messageId, setMessageId ] = useState();
    const [ sender, setSender ] = useState("")

    useEffect( () => {
        if ( "messageId" in params ) {

            const paramsMessageId = Number( params.messageId )
            const from = params.from

            if ( isNaN( paramsMessageId ) ) navigate( "/dashboard" )
            if ( messageId !== paramsMessageId ) {
                setMessageId( paramsMessageId )
                setSender( from )
            }
        }
    }, [ params ] )

    useEffect( () => {
        if ( messageId && sender ) {
            if ( sender === 'self' ) dispatch( fetchReadingMessageSent( messageId ) )
            else dispatch( fetchReadingMessageInbox( messageId ) )
        }
        

        return () => {
            if ( sender === 'self' ) dispatch( fetchMessages() )
            dispatch( cleanReadingMessage() )
        }
    }, [ messageId, sender ] )

    return (
        <div className="-read-message">
            <div className="message-header">    
                <h4>{ readingMessage.subject }</h4>
                <p>Sent on { timeExtract( readingMessage.created_at ) } UTC { dateCleaner( readingMessage.created_at ) } </p>
            </div>
            <TextEditor className="message-body" content={ readingMessage?.message || "" } editable={ false } />
            <p className="sender-signature">
                <span>
                    {
                        user?.id === readingMessage?.sender_id ? `To: ${ readingMessage.recipient_email }` :
                        `From: ${ readingMessage.sender_email }`
                    }
                </span>
            </p>
        </div>
    )
}

export default MessageRead;