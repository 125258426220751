import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import UnderRoot from "./component/UnderRoot";

// store
import { Provider } from "react-redux";
import store from "./store";

// date picker
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          dateLibInstance={dayjs.utc}
        >
          <UnderRoot />
        </LocalizationProvider>
      </Router>
    </Provider>
  );
}

export default App;
