/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { cleanSentMessages, fetchSentMessages } from "../../../store/messagesSlice";
import SectionWrapper from "../../../wrapper/SectionWrapper"
import MessageInbox from "./MessageInbox";
import MessageModal from "./MessageModal";
import MessageNav from "./MessageNav";
import MessageRead from "./MessageRead";
import MessageSent from "./MessageSent";
import './MessageSystem.scss';

const MessageSystem = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const user = useSelector( state => state.user.value )

    useEffect( () => {
        if ( user.id ) {
            if ( user.role !== 3 ) {
                navigate("/")
            }
        }
    }, [ user ] )

    const [ isOpen, setIsOpen ] = useState( false )

    useEffect( () => {
        // put the fetch for sent message on this parent component,
        // since the values are dependent on the user itself, not by others
        // therefore we don't need to keep updating
        dispatch( fetchSentMessages() )

        return () => {
            cleanSentMessages()
            dispatch( cleanSentMessages() )
        }
    }, [] )

    const pathShowMessages = useMemo( () => {
        if ( pathname === "/message" ) return true
        if ( pathname === "/message/sent" ) return true

        return false
    }, [ pathname ] )

    const readingMessage = useSelector( state => state.messages.value.reading )

    const [ defaultSubject, setDefaultSubject ] = useState("")
    const [ sendToDefault, setSendToDefault ] = useState( null )
    
    const handleReplay = () => {
        if ( user?.id === readingMessage.sender_id )  setSendToDefault( readingMessage.recipient_email )
        else setSendToDefault( readingMessage.sender_email )

        setDefaultSubject( readingMessage.subject )
    }

    useEffect( () => {
        if ( sendToDefault ) setIsOpen( true )
    }, [ sendToDefault ] )

    return (
        <SectionWrapper sectionClass={"message-system"}>
            <Modal  
                open={ isOpen }
                className={'modal-parent -message'}>
                    <Box 
                        className='modal-box -message-modal' 
                        sx={ { width: "100%", maxWidth: "35rem;" }}>

                            <MessageModal 
                                isOpen={ isOpen }
                                sendToDefault={ sendToDefault }
                                defaultSubject={ defaultSubject }
                                handleClose={ () => {
                                    setIsOpen( false )
                                    setSendToDefault( null )
                                    setDefaultSubject( "" )
                                } } />

                    </Box>
            </Modal>
            <div  className="margin--top-standard"></div>
            {
                pathShowMessages && <MessageNav />
                // <Box className="tabBox" sx={{ width: "100%", maxWidth: "33rem", borderBottom: 1, borderColor: '#C5C5C5', marginBottom: "2rem" }}>
                //     <Tabs value={ pathname || "" }>
                //         <Tab 
                //             value="/message" 
                //             label="My Inbox" 
                //             onClick={ () => navigate("/message") } >
                //         </Tab>
                //         <Tab 
                //             value="/message/sent"
                //             label="Sent Messages" 
                //             onClick={ () => navigate("/message/sent") } >
                //         </Tab>
                //     </Tabs>
                // </Box>
            }
            {
                user?.role === 3 &&
                <Routes>
                    <Route index element={ <MessageInbox /> } />
                    <Route path="/sent" element={ <MessageSent /> } />
                    <Route path="/read/:from/:messageId" element={ <MessageRead /> } />
                </Routes>
            }
            {
                pathShowMessages ? 
                    <Button variant="contained" sx={ { marginTop: "2rem", width: "100%", maxWidth: "13rem" } } onClick={ () => setIsOpen( true )  }>
                        Create New Message
                    </Button>
                    :
                    <div className="message-base-btns fd">
                        <Button 
                            variant="contained" 
                            sx={ { marginTop: "2rem", width: "100%", maxWidth: "13rem" } }  
                            onClick={ () => navigate( -1 )  }>
                                Go Back
                        </Button>
                        <Button 
                            variant="contained"
                            sx={ { marginTop: "2rem", width: "100%", maxWidth: "13rem" } }  
                            onClick={ () => handleReplay()  }>
                                Reply
                        </Button>
                    </div>
            }
            
        </SectionWrapper>
    )
}

export default MessageSystem;