const HeaderLogged = ( { children, submitHandler } ) => {
    return (
        <>
            <img className="icon" src="loginicon.png" alt="G-Rocket OMSv1.0" />
            <form onSubmit={ submitHandler }>
                {
                    children
                }
            </form>
        </>
    )
}

export default HeaderLogged