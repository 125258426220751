import './LandPage.scss';

const Landpage = () => {
    return (
        <div className="landpage-con">

        </div>
    )
}

export default Landpage;