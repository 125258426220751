import { Autocomplete, Box, TextField } from "@mui/material"

const ManagementOrderModalSeller = ({
    order_id="",
    user_role,
    // disabledProps,
    sellerInfo,
    // date,
    // setDate,
    sellers,
    seller_email,
    setSellerEmail,
    seller_email_selected,
    setSellerEmailSelected,
    seller_name,
    setSellerName,
    tabSellerValid}) => {
    return <>
        <li className='fd'>
            {
                user_role !== 3 ?
                <>
                    <Box>
                        <Autocomplete
                            id="seller-email"
                            options={ sellers }
                            getOptionLabel={ option => option?.email || "" }
                            freeSolo
                            disableClearable
                            value={ seller_email_selected || null }
                            onChange={ ( e, newValue ) => {
                                setSellerEmailSelected( newValue.email )
                            } }
                            inputValue={ seller_email || "" }
                            onInputChange={ ( e, newValue, reason ) => {
                                if ( reason === "reset" && !newValue  ) return
                                setSellerEmail( newValue )
                            } }
                            renderOption={ ( params, option ) => 
                                <li {...params} key={option.id}>
                                        {option.email}
                                </li>
                            }
                            renderInput={ params => 
                                <TextField
                                    {...params}
                                    label="SELLER EMAIL"
                                    variant='standard'
                                    required /> } />
                        { !tabSellerValid &&
                            <span style={{ fontSize: '0.75rem', color: 'red' }}>Please enter valid email address</span> }
                    </Box>
                    <Box>
                        <TextField
                            id="seller-name"
                            label="SELLER NAME"
                            value={ seller_name }
                            onChange={ e => setSellerName( e.target.value ) }
                            variant='standard'
                            required 
                            fullWidth
                            /> 
                        { !seller_name && 
                            <span style={{ fontSize: '0.75rem', color: 'red', display: 'block', marginTop: '0.25rem' }}>Please enter seller name</span> }
                    </Box>
                    </> 
            :
            <>
                <Autocomplete
                    id="seller-email"
                    options={ sellerInfo?.emails || [] }
                    getOptionLabel={ option => option || "" }
                    disableClearable

                    disabled={ order_id ? true : false }
                    value={ seller_email_selected || "" }
                    inputValue={ seller_email_selected || "" }
                    onChange={ ( e, newValue ) => setSellerEmailSelected( newValue ) }

                    isOptionEqualToValue={ ( option, value ) => option === value }
                    renderOption={ ( params, option ) => 
                        <li {...params} key={ option }>
                                { option }
                        </li>
                    }
                    renderInput={ params => 
                        <TextField
                            {...params}
                            label="SELLER EMAIL"
                            variant='standard'
                            required /> } />
                <TextField 
                    id="seller-name"
                    label="SELLER NAME"
                    value= { seller_name }
                    variant="standard"
                    disabled
                    inputProps={ {
                        readOnly: true
                    } }
                    />
                </>
            }
        </li>
    </>
}

export default ManagementOrderModalSeller;