import React, { useMemo, useEffect } from "react";

import DataCard from "../../../sharedComponent/DataCard";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import "./ReportsOrder.scss";
import PieChart from "./PieChart";
import useReports from "../../../useHooks/useReports";

const paymentStatusColors = [
  "rgba(217, 217, 217, 1)",
  "rgba(242, 199, 68, 1)",
  "rgba(72, 209, 111, 1)",
  "rgba(255, 102, 0, 1)",
  "rgba(0, 0, 0, 1)",
  "rgba(255, 0, 0, 1)",
];

const reviewStatusColors = [
  "rgba(134, 48, 255, 1)",
  "rgba(255, 48, 167, 1)",
  "rgba(255, 134, 48, 1)",
  "rgba(168, 255, 48, 1)",
  "rgba(48, 255, 135, 1)",
  "rgba(48, 168, 255, 1)",
];

const arrangeChartColors = (
  originalLabels = [],
  sortedLabels = [],
  status = "payment"
) => {
  const colorsToUse =
    status === "payment" ? paymentStatusColors : reviewStatusColors;
  const sortedColors = sortedLabels.map(
    (color) => colorsToUse[originalLabels.indexOf(color)]
  );

  return sortedColors;
};

const ReportsOrders = ({ dateFrom, dateTo }) => {
  const params = {
    startDate: dateFrom.format("YYYY-MM-DD"),
    endDate: dateTo.format("YYYY-MM-DD"),
  };
  const {
    seller_email_selected,
    orderReportCharts,
    orderReportStats,
    fetchOrderCount,
    fetchOrderStats,
    fetchOrderPaymentAndReviewStatus,
  } = useReports();

  const ordersAggregatedStats = useMemo(() => {
    const data = [];

    const initialReport = {
      total_orders: 0,
      paid_orders: 0,
      average_amount_of_reviews: 0,
      average_unit_cost: 0,
    };

    const reducer = (acc, report) => ({
      total_orders: report?.total_orders + acc.total_orders,
      paid_orders: report?.paid_orders + acc.paid_orders,
      average_amount_of_reviews:
        report?.average_amount_of_reviews + acc.average_amount_of_reviews,
      average_unit_cost: report?.average_unit_cost + acc.average_unit_cost,
    });

    const aggregatedReport = orderReportStats.reduce(reducer, initialReport);

    data.push({
      label: "Total Orders",
      value: orderReportStats.length > 0 ? aggregatedReport.total_orders : 0,
    });

    data.push({
      label: "Paid Orders",
      value: orderReportStats.length > 0 ? aggregatedReport.paid_orders : 0,
    });

    data.push({
      label: "Avg Amt of Reviews",
      value:
        orderReportStats.length > 0
          ? parseFloat(
              (
                aggregatedReport.average_amount_of_reviews /
                orderReportStats.length
              ).toFixed(2)
            )
          : 0,
    });

    data.push({
      label: "Avg Unit Cost",
      value:
        orderReportStats.length > 0
          ? parseFloat(
              (
                aggregatedReport.average_unit_cost / orderReportStats.length
              ).toFixed(2)
            )
          : 0,
    });

    return data;
  }, [orderReportStats]);

  const lineChartData = useMemo(() => {
    const length = Object.keys(orderReportCharts.orders)?.length || 30;

    const dateRange = Array.from({ length: length }, (_, i) => {
      const date = dateTo.subtract(i, "day").format("YYYY-MM-DD");
      return date;
    }).reverse();

    const finalData = {
      labels: dateRange,
      datasets: [
        {
          label: "Paid Orders",
          data: dateRange.map(
            (date) => orderReportCharts?.paid_orders[date] || 0
          ),
          borderColor: "#48d16fbf",
          fill: true,
          backgroundColor: "rgba(72, 209, 111, 0.3)",
          // tension: 0.3,
        },
        {
          label: "Orders",
          data: dateRange.map((date) => orderReportCharts?.orders[date] || 0),
          borderColor: "#30A8FF",
          fill: true,
          backgroundColor: "rgba(48, 168, 255, 0.3)",
          // tension: 0.3,
        },
      ],
    };

    return finalData;
  }, [orderReportCharts.orders, orderReportCharts.paid_orders, dateTo]);

  const originalLabels = useMemo(() => {
    const payment = orderReportCharts.order_payment_status.map((payment) => {
      return payment.payment_status;
    });

    const review = orderReportCharts.order_review_status.map((review) => {
      return review.review_status;
    });

    return { payment, review };
  }, [
    orderReportCharts.order_payment_status,
    orderReportCharts.order_review_status,
  ]);

  const barChartData = useMemo(() => {
    const sortedPaymentStatusCount =
      orderReportCharts.order_payment_status.slice();
    sortedPaymentStatusCount.sort((a, b) => b.count - a.count);

    const sortedPaymentStatusAmount =
      orderReportCharts.order_payment_status.slice();
    sortedPaymentStatusAmount.sort((a, b) => b.amount - a.amount);

    const sortedReviewStatusCount =
      orderReportCharts.order_review_status.slice();
    sortedReviewStatusCount.sort((a, b) => b.count - a.count);

    const sortedReviewStatusAmount =
      orderReportCharts.order_review_status.slice();
    sortedReviewStatusAmount.sort((a, b) => b.amount - a.amount);

    const orderPaymentStatusCountLabels = sortedPaymentStatusCount.map(
      (payment_status) => {
        return payment_status.payment_status;
      }
    );

    const orderPaymentStatusAmountLabels = sortedPaymentStatusAmount.map(
      (payment_status) => {
        return payment_status.payment_status;
      }
    );

    const orderReviewStatusCountLabels = sortedReviewStatusCount.map(
      (review_status) => {
        return review_status.review_status;
      }
    );

    const orderReviewStatusAmountLabels = sortedReviewStatusAmount.map(
      (review_status) => {
        return review_status.review_status;
      }
    );

    const finalData = {
      orderPaymentStatusCount: {
        labels: orderPaymentStatusCountLabels,
        datasets: [
          {
            data: sortedPaymentStatusCount.map(
              (payment_status) => payment_status.count || 0
            ),
            // backgroundColor: paymentStatusColors,
            backgroundColor: arrangeChartColors(
              originalLabels.payment,
              orderPaymentStatusCountLabels,
              "payment"
            ),
          },
        ],
      },
      orderPaymentStatusAmount: {
        labels: orderPaymentStatusAmountLabels,
        datasets: [
          {
            data: sortedPaymentStatusAmount.map(
              (payment_status) => payment_status.amount || 0
            ),
            backgroundColor: arrangeChartColors(
              originalLabels.payment,
              orderPaymentStatusAmountLabels,
              "payment"
            ),
          },
        ],
      },
      orderReviewStatusCount: {
        labels: orderReviewStatusCountLabels,
        datasets: [
          {
            data: sortedReviewStatusCount.map(
              (review_status) => review_status.count || 0
            ),
            backgroundColor: arrangeChartColors(
              originalLabels.review,
              orderReviewStatusCountLabels,
              "review"
            ),
          },
        ],
      },
      orderReviewStatusAmount: {
        labels: orderReviewStatusAmountLabels,
        datasets: [
          {
            data: sortedReviewStatusAmount.map(
              (review_status) => review_status.amount || 0
            ),
            backgroundColor: arrangeChartColors(
              originalLabels.review,
              orderReviewStatusAmountLabels,
              "review"
            ),
          },
        ],
      },
    };

    return finalData;
  }, [
    orderReportCharts.order_payment_status,
    orderReportCharts.order_review_status,
    originalLabels,
  ]);

  const pieChartData = useMemo(() => {
    const sortedPaymentData = orderReportCharts.order_payment_status.slice();
    sortedPaymentData.sort((a, b) => b.count - a.count);
    const sortedReviewData = orderReportCharts.order_review_status.slice();
    sortedReviewData.sort((a, b) => b.count - a.count);

    const orderPaymentStatusLabels = orderReportCharts.order_payment_status
      .filter((payment_status) => payment_status.percentage !== 0)
      .sort((a, b) => b.count - a.count)
      .map(
        (payment_status) =>
          `${payment_status.payment_status} - ${payment_status.percentage}%`
      );

    const orderReviewStatusLabels = orderReportCharts.order_review_status
      .filter((review_status) => review_status.percentage !== 0)
      .sort((a, b) => b.count - a.count)
      .map(
        (review_status) =>
          `${review_status.review_status} - ${review_status.percentage}%`
      );

    const originalPaymentLabelsWithPercentage =
      orderReportCharts.order_payment_status.map(
        (payment_status) =>
          `${payment_status.payment_status} - ${payment_status.percentage}%`
      );

    const originalReviewLabelsWithPercentage =
      orderReportCharts.order_review_status.map(
        (review_status) =>
          `${review_status.review_status} - ${review_status.percentage}%`
      );

    const finalData = {
      orderPaymentStatus: {
        labels: orderPaymentStatusLabels,
        datasets: [
          {
            data: sortedPaymentData.map(
              (payment_status) => payment_status.percentage || 0
            ),
            backgroundColor: arrangeChartColors(
              originalPaymentLabelsWithPercentage,
              orderPaymentStatusLabels,
              "payment"
            ),
          },
        ],
      },
      orderReviewStatus: {
        labels: orderReviewStatusLabels,
        datasets: [
          {
            data: sortedReviewData.map(
              (review_status) => review_status.percentage || 0
            ),
            backgroundColor: arrangeChartColors(
              originalReviewLabelsWithPercentage,
              orderReviewStatusLabels,
              "review"
            ),
          },
        ],
      },
    };

    return finalData;
  }, [
    orderReportCharts.order_payment_status,
    orderReportCharts.order_review_status,
  ]);

  useEffect(() => {
    fetchOrderStats(params);
    fetchOrderCount(params);
    fetchOrderPaymentAndReviewStatus(params);
    //eslint-disable-next-line
  }, [dateFrom, dateTo, seller_email_selected]);

  return (
    <>
      <div className="data-card-container -management-system margin--top-standard">
        <ul className="fd">
          {ordersAggregatedStats.map((data, i) => {
            return (
              <li key={i}>
                <DataCard
                  label={data.label}
                  value={data.value}
                  color="#30A8FF"
                />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="margin--top-standard">
        <h4>Orders Count</h4>
        <LineChart
          datasetIdKey="orders"
          displayLegend={true}
          data={lineChartData}
        />
      </div>

      <div className="margin--top-standard charts-container">
        <div>
          <h4>Orders Payment Status Count</h4>
          <BarChart data={barChartData.orderPaymentStatusCount} />
        </div>
        <div>
          <h4>Orders Payment Status Amount &#40;€&#41;</h4>
          <BarChart data={barChartData.orderPaymentStatusAmount} />
        </div>
        <div>
          <PieChart data={pieChartData.orderPaymentStatus} />
        </div>
      </div>

      <div className="margin--top-standard charts-container">
        <div>
          <h4>Orders Review Status Count</h4>
          <BarChart data={barChartData.orderReviewStatusCount} />
        </div>
        <div>
          <h4>Orders Review Status Amount &#40;€&#41;</h4>
          <BarChart data={barChartData.orderReviewStatusAmount} />
        </div>
        <div>
          <PieChart data={pieChartData.orderReviewStatus} />
        </div>
      </div>
    </>
  );
};

export default ReportsOrders;
