import React, { useState } from "react";
import SectionWrapper from "../../../wrapper/SectionWrapper";
import { Box, Button, Dialog, Typography } from "@mui/material";
import BrandModal from "./BrandModal";
import BrandTable from "./BrandTable";
import { useGetBrands } from "../../../useHooks/useBrands";

const BrandSystem = () => {
  const [brandId, setBrandId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { data: brands } = useGetBrands();

  const handleEdit = (id) => {
    setBrandId(id);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setBrandId(null);
  };

  return (
    <SectionWrapper sectionClass={"task-system"}>
      <Box
        sx={{
          display: "flex",
          padding: "0 0 2rem",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem 2rem",
        }}
      >
        <Typography
          sx={{ fontSize: "2rem", width: { xs: "100%", sm: "unset" } }}
        >
          Brands
        </Typography>
        <Button
          variant="contained"
          sx={{ p: "0.5rem 1.5rem" }}
          onClick={() => setOpenModal(true)}
        >
          Create Brand
        </Button>
      </Box>

      <BrandTable handleEdit={handleEdit} brands={brands} />

      <Dialog
        open={openModal}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        scroll="body"
      >
        <BrandModal brandId={brandId} handleClose={handleClose} />
      </Dialog>
    </SectionWrapper>
  );
};

export default BrandSystem;
