import './FidgetSPinner.scss';

const FidgetSpinner = ( { style={}, fidgetStyle={}, fidgetClass="" } ) => {
    return (
        
    <div style={ style } className={ `loader-container fd ${ fidgetClass }` }>
        <span className="loader" style={ fidgetStyle }></span>
    </div>
    )
}

export default FidgetSpinner;