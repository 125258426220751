import { 
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TablePagination,
    MenuItem,
    Select,
} from '@mui/material';
import {
    useState,
} from 'react';
import './UserTable.scss';
// import ManagementOrderModal from "./ManagementOrderModal";

const TABLE_HEAD = [
    {
        label: "ID"
    },
    {
        label: "EMAIL"
    },
    {
        label: "NAME"
    },
    {
        label: "ROLE"
    },
    {
        label: "STATUS"
    },
    {
        label: "ACTIONS"
    }
]

const KEYS = [ 
    "id",
    "email",
    "name",
    "role",
    "status",
]

const ROLE_DROPDOWN = [
    {
        label: "Admin",
        value: 1
    },
    {
        label: "Accountant",
        value: 2
    },
    {
        label: "Seller",
        value: 3
    }
]

const STATUS_DROPDOWN = [
    {
        label: "Active",
        value: 1
    },
    {
        label: "Delete",
        value: 0
    }
]

const UserTable = ( { users, handleUpdate, handleUser, setModalType } ) => {
    // function context

    // function context

    const [ currentPage, setCurrentPage ] = useState( 0 );

    const handleUpdateUserOpen = (id, modalType) => {
        // find order and set it
        if ( !users.length ) return
        if ( !modalType ) return

        setModalType(modalType)

        const userFound = users.find( _user => Number( _user.id ) === Number( id ) )
        if ( userFound ) handleUser( userFound )
    }

    const cellGenerator = ( user, i, key, keyI ) => {
        
        if ( keyI === 1 ) return user?.[key].toLowerCase()
        if ( keyI === 2 ) {
            const val = user?.[key];
            const valArray = val.split(" ");

            const _newVal = valArray.map( item => item.slice(0, 1).toUpperCase() + item.slice(1).toLowerCase() )
            
            let returnVal = "";

            _newVal.forEach( ( item, i )  => {
                returnVal = returnVal + item

                if ( i!== ( _newVal.length - 1 ) ) returnVal = returnVal + " "
            });

            return returnVal
        }
        if ( keyI === 3 ) {
            return <Select
                        id={ `role_${ i }` }
                        label="ROLE"
                        sx={ { width: "max-content" } }
                        variant="standard"
                        value={ Number( user?.[key] ) }
                        onChange={ e => handleUpdate( { role: e.target.value }, user.id ) } >
                            {
                                ROLE_DROPDOWN.map( ( role, roleI ) => 
                                    <MenuItem key={ roleI }
                                        value={ role.value }>
                                        { role.label }
                                    </MenuItem> )
                            }

                    </Select>

            // return roleDropDown.find( orderStatus => orderStatus.value === Number( product?.[key] ) )?.label || ""
        }
        
        if ( keyI === 4 ) {
            return <Select
                        id={ `status${ i }` }
                        label="STATUS"
                        sx={ { width: "max-content" } }
                        variant="standard"
                        value={ Number( user?.[key] ) }
                        onChange={ e => handleUpdate( { status: e.target.value }, user.id ) } >
                            {
                                STATUS_DROPDOWN.map( ( status, statusI ) => 
                                    <MenuItem key={ statusI }
                                        value={ status.value }>
                                        { status.label }
                                    </MenuItem> )
                            }

                    </Select>
            

            // return statusDropDown.find( paymentStatus => paymentStatus.value === Number( product?.[key] ) )?.label || ""
        }

        return user?.[key]
    }

    return (
        <>
            <TableContainer 
                className='margin--top-standard table-container' 
                component={ Paper }
            >
                <Table
                    sx={ { width: "100%" } }>
                    <TableHead>
                        <TableRow>
                            {
                                TABLE_HEAD.map( ( head, i ) => <TableCell key={ i } align="left"> { head.label } </TableCell> )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            users
                            .slice( currentPage * 10, currentPage * 10 + 10 )
                            .map( 
                                ( user, i ) => <TableRow key={ i }>
                                    {
                                        KEYS.map( ( key, keyI ) => <TableCell key={ keyI } align="left">
                                            { cellGenerator( user, i, key, keyI ) }
                                        </TableCell>  )
                                    }
                                    <TableCell sx={ { minWidth: "150px" } } align='left' className='last-row'>
                                        <span className="row-action-btns fd">
                                            <span 
                                                className='view-btn'
                                                onClick={ () => handleUpdateUserOpen( user.id, 'view' ) }>View</span>
                                        </span>
                                    </TableCell>
                                </TableRow> )
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={ [] }
                    rowsPerPage={ 10 }
                    count={ users.length }
                    page={ currentPage }
                    onPageChange={ ( e, newPage ) => setCurrentPage( newPage ) }
                />
            </TableContainer>
        </>
    )
}

export default UserTable