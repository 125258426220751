import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
} from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";

// instead of a array of string
// we are using array of obj for complexity in the future
const TABLE_HEAD = [
  "ID",
  "EMAIL",
  "NAME",
  "ORDERS",
  "TOTAL AMOUNT",
  "DATE REGISTERED",
];

const KEYS = [
  "selected",
  "id",
  "email",
  "name",
  "orders",
  "total_price",
  "created_at",
];

const ClientsTable = forwardRef(
  ({ clients = [], handleClient = () => "", clientsLoading = false }, ref) => {
    const [current_page, setCurrentPage] = useState(0);
    const [clientsCheckbox, setClientsCheckbox] = useState([]);
    const [idsToTransfer, setIdsToTransfer] = useState([]);

    useImperativeHandle(ref, () => ({
      clients: idsToTransfer,
    }));

    useEffect(() => {
      setClientsCheckbox(
        clients.map((client) => {
          return { ...client, selected: false };
        })
      );

      setIdsToTransfer([]);
    }, [clients]);

    const handleCheck = (id) => {
      const index = clientsCheckbox.findIndex((client) => client.id === id);
      const updatedClients = [...clientsCheckbox];
      const foundClient = updatedClients[index];

      if (foundClient.selected === false) {
        setIdsToTransfer([...idsToTransfer, foundClient.id]);
      } else {
        const newIds = idsToTransfer.filter((addedId) => addedId !== id);
        setIdsToTransfer(newIds);
      }

      foundClient.selected = !foundClient.selected;
      updatedClients[index] = foundClient;

      setClientsCheckbox(updatedClients);
    };

    const handleCheckAll = () => {
      let newClientsCheckbox = [];

      if (
        idsToTransfer.length > 0 ||
        idsToTransfer.length === clientsCheckbox.length
      ) {
        newClientsCheckbox = clientsCheckbox.map((client) => {
          return { ...client, selected: false };
        });

        setIdsToTransfer([]);
      } else {
        const newIds = [];

        newClientsCheckbox = clientsCheckbox.map((client) => {
          newIds.push(client.id);
          return { ...client, selected: true };
        });

        setIdsToTransfer(newIds);
      }

      setClientsCheckbox(newClientsCheckbox);
    };

    const findClient = (id) => {
      if (clients.length < 1) return;

      const foundClient = clients.find((client) => client.id === id);

      handleClient(foundClient);
    };

    const cellGenerator = (client, key) => {
      if (key === "orders") return client[key]?.length;
      if (key === "total_price") {
        const total_price = client?.orders?.reduce(
          (subTotal, item) => subTotal + Number(item?.total_price || "0"),
          0
        );

        return total_price;
      }

      return client[key];
    };

    return (
      <>
        <TableContainer
          className="table-container -clients"
          sx={{ marginBottom: "2rem", marginTop: "2rem" }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    indeterminate={
                      idsToTransfer.length > 0 &&
                      idsToTransfer.length < clientsCheckbox.length
                    }
                    checked={
                      idsToTransfer.length > 0 &&
                      idsToTransfer.length === clientsCheckbox.length
                    }
                    onChange={handleCheckAll}
                  />
                </TableCell>
                {TABLE_HEAD.map((head, i) => (
                  <TableCell key={i}> {head} </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clientsLoading ? (
                <TableRow>
                  <TableCell colSpan={KEYS.length}>
                    <FidgetSpinner />
                  </TableCell>
                </TableRow>
              ) : (
                clientsCheckbox
                  .slice(current_page * 10, current_page * 10 + 10)
                  .map((client, i) => (
                    <TableRow
                      className={"clickable-row"}
                      key={i}
                      onClick={() => findClient(client.id)}
                    >
                      <TableCell>
                        <Checkbox
                          checked={client.selected}
                          onChange={() => handleCheck(client.id)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </TableCell>
                      {KEYS.map((key, keyI) => {
                        if (keyI === 0) return null;
                        return (
                          <TableCell key={keyI}>
                            {" "}
                            {cellGenerator(client, key)}{" "}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            rowsPerPage={10}
            count={clientsCheckbox.length}
            page={current_page}
            onPageChange={(e, newPage) => setCurrentPage(newPage)}
          />
        </TableContainer>
      </>
    );
  }
);

export default ClientsTable;
