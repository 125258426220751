import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";

import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useImportCSV } from "../../../useHooks/useProspects";

const ImportCsvModal = ({ handleClose }) => {
  const { mutateAsync: importCSV, isLoading: isSaving } = useImportCSV();

  // const onDrop = useCallback((acceptedFiles) => {
  //   // Do something with the files
  //   console.log(acceptedFiles);
  // }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone();

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("csv", acceptedFiles[0]);
    const result = await importCSV(formData);
    if (result.status === 200) handleClose();
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <DialogTitle>
        <Typography variant="body1" fontSize="1.5rem">
          Import CSV
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ mt: "1rem" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Typography variant="body1">
            You can import prospects data using this form.
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Typography variant="body1">
              The CSV data must follow this format sequence:
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              &nbsp;URL, Business Name, Webiste, Phone
            </Typography>
          </Box>
        </Box>

        <Box
          {...getRootProps()}
          sx={{
            mt: "2rem",
            p: "2rem",
            border: "2px dotted #000000",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "baseline",
            }}
          >
            <FontAwesomeIcon
              icon={solid("upload")}
              size="2xl"
              style={{
                color: "#30A8FF",
              }}
            />
            {acceptedFiles[0]?.name ? (
              <Typography variant="body1" fontSize="1.5rem">
                {acceptedFiles[0].name}
              </Typography>
            ) : isDragActive ? (
              <Typography variant="body1" fontSize="1.5rem">
                Drop your file here
              </Typography>
            ) : (
              <Typography variant="body1" fontSize="1.5rem">
                Click or drag here to upload file
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            mt: "2rem",
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Button
            variant="contained"
            type="button"
            className="-gray"
            onClick={handleClose}
            sx={{
              width: { xs: "100%", md: "unset" },
              minWidth: "8rem",
              p: "0.5rem 1.5rem",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="button"
            disabled={isSaving}
            onClick={handleSave}
            sx={{
              width: { xs: "100%", md: "unset" },
              minWidth: "8rem",
              p: "0.5rem 1.5rem",
            }}
          >
            {isSaving ? (
              <FidgetSpinner fidgetStyle={{ width: "1rem", height: "1rem" }} />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </DialogContent>
    </Box>
  );
};

export default ImportCsvModal;
