import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material"

const TABLE_HEAD = [
    {
        label: "Client Email"
    },
    {
        label: "Date"
    }, 
    {
        label: "Tier"
    },
    {
        label: "Status"
    }
]

const KEYS = [
    "email",
    "approved_date",
    "tier_text",
    "status_text"
]

const ManagementApplicationTable = ( { applications=[], applicationPagination={}, fetchTableData=()=>"", rowClickHandler=()=>"" } ) => {
    return (
        <TableContainer
            component={ Paper }
            className={ "table-container" }
            sx={ { marginTop: "2rem", marginBottom: "2rem" } }>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            TABLE_HEAD.map( ( head, i ) => <TableCell key={ i }>{ head.label }</TableCell> )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                        {
                            applications.map( 
                                ( application, applicationI ) => 
                                <TableRow className="clickable-row" key={ applicationI } onClick={ () => rowClickHandler( application ) } >
                                    {
                                        KEYS.map( ( key, keyI ) => <TableCell key={ keyI }>
                                            { application?.[key] || "" }
                                        </TableCell> )
                                    }
                                </TableRow> )
                        }
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                rowsPerPage={10}
                count={ applicationPagination?.total || 0 }
                page={ applicationPagination?.current_page - 1 || 0 }
                onPageChange={ ( e, newPage ) => fetchTableData( newPage + 1 ) }
            />
        </TableContainer>
    )
}

export default ManagementApplicationTable