/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ToastDataContext } from "../../UnderRoot";
import { getHeader } from "../../../shared/Authorization";
import axios from "axios";
import { ErrorHandler } from "../../../shared/CustomFunc";
import { useGetBrands } from "../../../useHooks/useBrands";

const ROOT_URL = process.env.REACT_APP_API_URL;

const ManagementOrderModalClient = ({
  disabledProps,
  clients,
  client_email_selected,
  setClientEmailSelected,
  client_email,
  setClientEmail,
  client_name,
  setClientName,
  client_phone,
  setClientPhone,
  third_party_id,
  setThirdPartyId,
  unit_cost,
  setUnitCost,
  tabClientValid,
  industry_id,
  setClientIndustry,
  source_id,
  setClientSource,
  brand_code,
  setClientBrand,
}) => {
  const { customToast } = useContext(ToastDataContext);

  const [industries, setIndustries] = useState([]);
  const [sources, setSources] = useState([]);
  const { data: brands } = useGetBrands();

  const fetchIndustries = async () => {
    const headers = getHeader();

    await axios
      .get(ROOT_URL + "/client/industries", { headers })
      .then((resp) => {
        setIndustries(resp.data.industries);
        return { ok: true };
      })
      .catch((err) => {
        console.log(err);
        return ErrorHandler(err, customToast, "Error fetching industries");
      });
  };

  const fetchSources = async () => {
    const headers = getHeader();

    await axios
      .get(ROOT_URL + "/client/sources", { headers })
      .then((resp) => {
        setSources(resp.data.client_sources);
        return { ok: true };
      })
      .catch((err) => {
        console.log(err);
        return ErrorHandler(err, customToast, "Error fetching origin list");
      });
  };

  useEffect(() => {
    fetchIndustries();
    fetchSources();
  }, []);

  const industrySelectHandler = (id) => {
    const foundIndustry = industries?.find((_industry) => _industry.id === id);

    if (!foundIndustry?.id) {
      setClientIndustry("");
      return;
    }

    setClientIndustry(foundIndustry.id);
  };

  const sourceSelectHandler = (id) => {
    const foundSource = sources?.find((_source) => _source.id === id);

    if (!foundSource?.id) {
      setClientSource("");
      return;
    }

    setClientSource(foundSource.id);
  };

  const brandSelectHandler = (code) => {
    const foundBrand = brands?.find((_brand) => _brand.code === code);

    if (!foundBrand?.code) {
      setClientBrand("");
      return;
    }

    setClientBrand(foundBrand.code);
  };

  return (
    <>
      <li className="fd">
        <Box>
          <Autocomplete
            id="client-email"
            options={clients}
            getOptionLabel={(option) => option?.email || ""}
            freeSolo
            disabled={disabledProps}
            disableClearable
            value={client_email_selected || null}
            onChange={(e, newValue) => setClientEmailSelected(newValue.email)}
            inputValue={client_email || ""}
            onInputChange={(e, newValue, reason) => {
              if (reason === "reset" && !newValue) return;
              setClientEmail(newValue);
            }}
            renderOption={(params, option) => (
              <li {...params} key={option.id}>
                {option.email}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="CLIENT EMAIL"
                variant="standard"
                required
              />
            )}
          />
          {!tabClientValid && (
            <span style={{ fontSize: "0.75rem", color: "red" }}>
              Please enter valid email address
            </span>
          )}
        </Box>
        <Box>
          <TextField
            id="client-name"
            label="CLIENT NAME"
            value={client_name || ""}
            disabled={disabledProps}
            onChange={(e) => setClientName(e.target.value)}
            variant="standard"
            required
            fullWidth
          />
          {!client_name && (
            <span
              style={{
                fontSize: "0.75rem",
                color: "red",
                display: "block",
                marginTop: "0.25rem",
              }}
            >
              Please enter client name
            </span>
          )}
        </Box>
      </li>
      <li className="fd">
        <TextField
          id="client-phone"
          label="CLIENT PHONE"
          disabled={disabledProps}
          value={client_phone || ""}
          onChange={(e) => setClientPhone(e.target.value)}
          variant="standard"
        />
        <TextField
          id="third-party-id"
          label="3RD PARTY ID"
          disabled={disabledProps}
          value={third_party_id || ""}
          onChange={(e) => setThirdPartyId(e.target.value)}
          variant="standard"
        />
      </li>
      <li className="fd">
        <FormControl variant="standard">
          <InputLabel id="client_industry" sx={{ p: 0 }}>
            INDUSTRY
          </InputLabel>
          <Select
            labelId="client_industry"
            variant="standard"
            value={industry_id ?? ""}
            onChange={(e) => industrySelectHandler(e.target.value)}
          >
            {industries.map((industry, i) => (
              <MenuItem key={i} value={industry.id}>
                {" "}
                {industry.name.toUpperCase()}{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard">
          <InputLabel id="client_source">CLIENT ORIGIN</InputLabel>
          <Select
            labelId="client_source"
            variant="standard"
            value={source_id ?? ""}
            onChange={(e) => sourceSelectHandler(e.target.value)}
          >
            {sources.map((_source, i) => (
              <MenuItem key={i} value={_source.id}>
                {" "}
                {_source.name.toUpperCase()}{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </li>
      <li className="fd">
        <FormControl variant="standard">
          <InputLabel id="client_brand" sx={{ p: 0 }}>
            BRAND
          </InputLabel>
          <Select
            labelId="client_brand"
            variant="standard"
            value={brand_code ?? ""}
            onChange={(e) => brandSelectHandler(e.target.value)}
          >
            {brands.map((brand, i) => (
              <MenuItem key={i} value={brand.code}>
                {" "}
                {brand.name.toUpperCase()}{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="unit_price"
          label="UNIT COST"
          disabled={disabledProps}
          value={unit_cost || 0}
          InputProps={{ inputProps: { min: 0 } }}
          type="number"
          sx={{ width: "100%" }}
          onChange={(e) => setUnitCost(e.target.value)}
          variant="standard"
        />
      </li>
    </>
  );
};

export default ManagementOrderModalClient;
