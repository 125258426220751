/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Modal,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
} from "@mui/material";
// import axios from 'axios';
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import { getHeader } from '../../../shared/Authorization';
import { ErrorHandler } from "../../../shared/CustomFunc";
// import DataCard from '../../../sharedComponent/DataCard';
import useClients, {
  useFetchClients,
  useTransferClients,
} from "../../../useHooks/useClients";
import useSellers from "../../../useHooks/useSellers";
import SectionWrapper from "../../../wrapper/SectionWrapper";
import { ToastDataContext } from "../../UnderRoot";
import { FunctionContext } from "../Main";
import ClientsModal from "./ClientsModal";
import ClientsModalHistory from "./ClientsModalHistory";
import ClientsModalInfo from "./ClientsModalInfo";
// import ClientsModalOrder from './ClientsModalOrder';
import ClientsTable from "./ClientsTable";
import "./ClientsSystem.scss";
import useInputSearch from "../../../useHooks/useInputSearch";
import { useDebounce } from "../../../useHooks/useDebounce";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";

const FILTER_BY_OPTION = [
  {
    value: "all",
    label: "SEARCH",
  },
  {
    value: "seller",
    label: "SELLER",
  },
];

const ClientsSystem = () => {
  const { modalOpen } = useContext(FunctionContext);
  const user = useSelector((state) => state.user.value);

  const { customToast } = useContext(ToastDataContext);

  const {
    // clients,
    // clientsLoading,
    // fetchClients,
    addClient,
    updateClient,
    addClientLinks,
    updateClientLink,
    removeClientLink,
  } = useClients();

  const [client, setClient] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [localTab, setLocalTab] = useState("2");

  useEffect(() => {
    setLocalTab("2");
  }, [isOpen]);

  const handleClient = (client) => setClient(client);

  const idsToTransferRef = useRef(null);

  const [showLoggedIn, setShowLoggedIn] = useState(false);
  const [filterBy, setFilterBy] = useState("all");
  const [keyword, setKeyword] = useState("");
  const [email, setEmail] = useState("");
  const [emailTransfer, setEmailTransfer] = useState("");

  const debouncedKeyword = useDebounce(keyword, 500);
  const debouncedEmail = useDebounce(email, 500);

  const params = useMemo(() => {
    const params = {};

    if (showLoggedIn) {
      params.clientLoggedIn = showLoggedIn;
    }

    if (filterBy === "all") {
      params.keyword = debouncedKeyword;
    } else {
      params.keyword = debouncedEmail;
      params.filter = filterBy;
    }

    return params;
  }, [debouncedEmail, debouncedKeyword, showLoggedIn]);

  const {
    data: clients = [],
    isInitialLoading: clientsLoading,
    refetch: refetchClients,
  } = useFetchClients(params);

  const { items: filteredSellers, searchFunc: fetchFilteredSellers } =
    useInputSearch("/seller/search/");

  useEffect(() => {
    if (email) fetchFilteredSellers(email);
  }, [email]);

  useEffect(() => {
    if (emailTransfer) fetchFilteredSellers(emailTransfer);
  }, [emailTransfer]);

  const showLoggedInHandler = (event) => {
    setShowLoggedIn(event.target.checked);
  };

  useEffect(() => modalOpen(isOpen), [isOpen]);

  const { sellers, fetchSellers } = useSellers();

  useEffect(() => {
    if (client?.id) {
      if (!isOpen) {
        setIsOpen(true);

        if (client.id === "none") {
          if (user?.role !== 3) {
            fetchSellers();
          }
        }
      }
    } else {
      if (isOpen) {
        setIsOpen(false);
        setLocalTab("2");
      }
    }
  }, [client, isOpen]);

  const addCompanyLinksHandler = async (
    company_links,
    client_id,
    successMessage = false,
    reloadFetch = false
  ) => {
    const status = await addClientLinks(company_links, client_id);

    if (status.ok) {
      if (successMessage) customToast(successMessage, "success");

      await refetchClients();

      const foundClient = clients.find((_client) => _client.id === client?.id);

      handleClient(foundClient);
    } else {
      ErrorHandler(status.err, customToast, "Error adding client links");
    }
  };

  const removeClientLinkHandler = async (link_id) => {
    const status = await removeClientLink(link_id);

    if (status.ok) {
      customToast("Successfully removed client link", "success");
      await refetchClients();

      const foundClient = clients.find((_client) => _client.id === client?.id);

      handleClient(foundClient);
    } else {
      ErrorHandler(status.err, customToast, "Error removing client link");
    }
  };

  const updateClientLinkHandler = async (link_id, _data) => {
    const status = await updateClientLink(link_id, _data);

    if (status.ok) {
      customToast("Successfully updated client link", "success");
      await refetchClients();

      const foundClient = clients.find((_client) => _client.id === client?.id);

      handleClient(foundClient);

      return { ok: true };
    } else {
      return ErrorHandler(
        status.err,
        customToast,
        "Error updating client link"
      );
    }
  };

  const addClientHandler = async (new_client, company_links = []) => {
    const status = await addClient(new_client);

    if (status.ok) {
      customToast("Successfully added client", "success");

      if (company_links.length > 0)
        await addClientLinks(company_links, status.client.id);

      setClient({});
      refetchClients();

      return { ok: true };
    } else {
      ErrorHandler(status.err, customToast, "Error adding client");

      return { ok: false };
    }
  };

  const updateClientHandler = async (_data) => {
    const status = await updateClient(client.id, _data);

    if (status.ok) {
      await refetchClients();

      const foundClient = clients.find((_client) => _client.id === client?.id);

      handleClient(foundClient);

      customToast("Successfully updated client.", "success");

      return { ok: true };
    } else {
      ErrorHandler(status.err, customToast, "Error updating client.");

      return { ok: false };
    }
  };

  const cancelButton = useMemo(() => {
    return (
      <Button
        className="-gray"
        type="button"
        variant="contained"
        sx={{ maxWidth: { xs: "unset", md: "6rem" }, width: "100%" }}
        onClick={() => setClient({})}
      >
        Cancel
      </Button>
    );
  }, []);

  const { mutateAsync: transferClients, isLoading: isTransferring } =
    useTransferClients();

  const handleTransferClient = () => {
    const _clients = idsToTransferRef?.current?.clients;
    if (
      !window.confirm(
        `Confirm transferring ${_clients?.length} clients to ${emailTransfer}`
      )
    ) {
      return;
    }

    const clients = JSON.stringify(_clients);
    const payload = { clients, to_seller_email: emailTransfer };

    transferClients(payload);
  };

  return (
    <SectionWrapper sectionClass={"clients-system"}>
      <Modal
        open={isOpen}
        onClose={() => setClient({})}
        className={`modal-parent -client -not-empty tab_${localTab}`}
      >
        <Box className="modal-box" sx={{ width: "850px" }}>
          <ClientsModal
            client={client}
            localTab={localTab}
            handleTab={(newTab) => setLocalTab(newTab)}
          >
            {(localTab === "2" ||
              (localTab === "1" && client?.id === "none")) && (
              <ClientsModalInfo
                client={client}
                sellers={sellers}
                addClientHandler={addClientHandler}
                updateClientHandler={updateClientHandler}
                addCompanyLinksHandler={addCompanyLinksHandler}
                updateClientLinkHandler={updateClientLinkHandler}
                removeClientLinkHandler={removeClientLinkHandler}
              >
                {cancelButton}
              </ClientsModalInfo>
            )}
            {localTab === "3" && (
              <ClientsModalHistory client={client}>
                {cancelButton}
              </ClientsModalHistory>
            )}
          </ClientsModal>
        </Box>
      </Modal>
      <div className="client-header fd margin--top-standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={showLoggedIn} onChange={showLoggedInHandler} />
            }
            label="Show Logged In"
          />
        </FormGroup>

        <Box sx={{ display: "flex", gap: "1rem" }}>
          <FormControl variant="standard">
            <InputLabel id="filter_label">FILTER</InputLabel>
            <Select
              labelId="filter_label"
              id="filter-by"
              label="FILTER"
              value={filterBy}
              sx={{ minWidth: "10rem" }}
              onChange={(e) => setFilterBy(e.target.value)}
            >
              {FILTER_BY_OPTION.map((filterOption, i) => (
                <MenuItem key={i} value={filterOption.value}>
                  {filterOption.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {filterBy === "all" && (
            <TextField
              label={"KEYWORD"}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              variant="standard"
              margin="none"
            />
          )}
          {filterBy === "seller" && (
            <Autocomplete
              id="seller-email"
              options={filteredSellers}
              getOptionLabel={(option) => option?.email || ""}
              filterOptions={(x) => x}
              freeSolo
              value={email}
              onChange={(_, newValue) => setEmail(newValue?.email ?? "")}
              inputValue={email || ""}
              onInputChange={(_, newValue, reason) => {
                if (reason === "reset" && !newValue) return;
                setEmail(newValue);
              }}
              renderOption={(params, option) => (
                <li {...params} key={option.id}>
                  {option.email}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="EMAIL"
                  variant="standard"
                  sx={{ minWidth: 200 }}
                />
              )}
            />
          )}
        </Box>
      </div>
      <ClientsTable
        clients={clients}
        handleClient={handleClient}
        clientsLoading={clientsLoading}
        ref={idsToTransferRef}
      />
      <Box
        sx={{
          mb: "2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          flexDirection: { xs: "column", sm: "row" },
          gap: "1rem",
        }}
      >
        <Button
          sx={{ width: { xs: "100%", sm: "12rem" } }}
          type="button"
          variant="contained"
          onClick={() => setClient({ id: "none" })}
        >
          Add Client
        </Button>
        {user?.role === 1 && (
          <Box
            sx={{
              display: "flex",
              gap: { xs: "1rem", sm: "2rem" },
              width: { xs: "100%", sm: "unset" },
              alignItems: { xs: "flex-start", sm: "center" },
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Autocomplete
              id="transfer-seller-email"
              options={filteredSellers}
              getOptionLabel={(option) => option?.email || ""}
              freeSolo
              value={emailTransfer}
              fullWidth
              onChange={(_, newValue) =>
                setEmailTransfer(newValue?.email ?? "")
              }
              inputValue={emailTransfer || ""}
              onInputChange={(_, newValue, reason) => {
                if (reason === "reset" && !newValue) return;
                setEmailTransfer(newValue);
              }}
              renderOption={(params, option) => (
                <li {...params} key={option.id}>
                  {option.email}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="TRANSFER TO SELLER"
                  variant="standard"
                  sx={{ minWidth: 200 }}
                />
              )}
            />
            <Button
              sx={{
                width: { xs: "100%", sm: "20rem" },
                whiteSpace: "nowrap",
              }}
              type="button"
              variant="contained"
              onClick={handleTransferClient}
              disabled={isTransferring}
            >
              {isTransferring ? (
                <FidgetSpinner
                  fidgetStyle={{ width: "1rem", height: "1rem" }}
                />
              ) : (
                "Transfer Clients"
              )}
            </Button>
          </Box>
        )}
      </Box>
    </SectionWrapper>
  );
};

export default ClientsSystem;
