import React, { useEffect, useMemo } from 'react'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import DataCard from "../../../sharedComponent/DataCard"
import LineChart from "./LineChart"
import useReports from '../../../useHooks/useReports';

dayjs.extend(utc);

const ReportsRevenue = ({ dateFrom, dateTo }) => {
  const params = { startDate: dateFrom.format('YYYY-MM-DD'), endDate: dateTo.format('YYYY-MM-DD') };
  

  const { 
    seller_email_selected,
    revenueReportStats,
    revenueReportCharts,
    fetchRevenueStats,
    fetchRevenuePaid,
  } = useReports();

  const revenueAggregatedData = useMemo(() => {
    const data = [];

    const initialReport = {
        average_price: 0,
        average_customer_value: 0,
        average_amount_of_reviews: 0,
        total_revenue: 0
    };

    const reducer = (acc, report) => ({
        average_price: report?.average_price + acc.average_price,
        average_customer_value: report?.average_customer_value + acc.average_customer_value,
        average_amount_of_reviews: report?.average_amount_of_reviews + acc.average_amount_of_reviews,
        total_revenue: report?.total_revenue + acc.total_revenue
    });

    const aggregatedReport = revenueReportStats.reduce(reducer, initialReport);

    data.push({
        label: 'Avg Price',
        value: revenueReportStats.length > 0 
            ? parseFloat((aggregatedReport.average_price / revenueReportStats.length).toFixed(2)) : 0
    });

    data.push({
        label: 'Avg Customer Value',
        value: revenueReportStats.length > 0 
            ? parseFloat((aggregatedReport.average_customer_value / revenueReportStats.length).toFixed(2)) : 0
    });

    data.push({
        label: 'Avg Amt of Reviews',
        value: revenueReportStats.length > 0 
            ? parseFloat((aggregatedReport.average_amount_of_reviews / revenueReportStats.length).toFixed(2)) : 0
    });

    data.push({
        label: 'Total Revenue',
        value: parseFloat(aggregatedReport.total_revenue.toFixed(2))
    });

    return data;
  }, [revenueReportStats]);

  const chartsData = useMemo(() => {
    const length = Object.keys(revenueReportCharts.paid_counts)?.length || 30;

    const dateRange = Array.from({ length: length }, (_, i) => {
      const date = dateTo.subtract(i, 'day').format('YYYY-MM-DD');
      return date;
    }).reverse();

    const paidCountData = {
      labels: dateRange,
      datasets: [
        {
          data: dateRange.map((date) => revenueReportCharts?.paid_counts[date] || 0),
          fill: true,
          borderColor: '#30A8FF',
          backgroundColor: 'rgba(48, 168, 255, 0.3)',
          // tension: 0.3,
        },
      ]
    }

    const paidAmountData = {
      labels: dateRange,
      datasets: [
        {
          data: dateRange.map((date) => parseFloat(revenueReportCharts?.paid_amounts[date]) || 0),
          fill: true,
          borderColor: '#30A8FF',
          backgroundColor: 'rgba(48, 168, 255, 0.3)',
          // tension: 0.3,
        },
      ]
    }

    return {
      paidCount: paidCountData,
      paidAmount: paidAmountData
    }
  }, [revenueReportCharts, dateTo]);

  useEffect( () => {
    fetchRevenueStats(params);
    fetchRevenuePaid(params);
    //eslint-disable-next-line
  }, [dateFrom, dateTo, seller_email_selected] )

  return (
    <>
      <div className="data-card-container -management-system margin--top-standard">
          <ul className="fd">
              { revenueAggregatedData.map((data, i) => {
                  return <li key={i}>
                      <DataCard 
                          label={data.label}
                          value={data.value}
                          color='#30A8FF'
                      />
                  </li>
              })}
          </ul>
      </div>
      <div className="margin--top-standard">
          <h4>Paid Orders Count</h4>
          <LineChart 
            datasetIdKey='revenue-orders-count'
            data={chartsData.paidCount}
          />
      </div>
      <div className="margin--top-standard">
          <h4>Paid Orders Amount</h4>
          <LineChart
            datasetIdKey='revenue-orders-amount'
            data={chartsData.paidAmount}
          />
      </div>
    </>
  )
}

export default ReportsRevenue