import { useState } from "react";
import axios from 'axios';
// import qs from 'qs';
import { getHeader } from '../shared/Authorization';

const ROOT_URL = process.env.REACT_APP_API_URL

const useSellers = () => {
    const [ sellersLoading, setSellersLoading ] = useState( false )
    const [ sellers, setSellers ] = useState( [] )

    const fetchSellers = async ( ) => {
        const headers = getHeader()

        setSellersLoading( true )

        const status = await axios.get( ROOT_URL + '/seller', { headers } )
        .then( resp => {
            setSellers( resp.data?.sellers || [] )

            return { ok: true, sellers: resp.data?.sellers || [] }
        } )
        .catch( err => {
            return { ok: false, err }
        } )

        setSellersLoading( false )

        return status
    }

    const addSeller = async ( new_seller ) => {
        const headers = getHeader( );

        const formData = new FormData()

        Object.keys( new_seller ).forEach( key => formData.append( key, new_seller[key] ) )

        return await axios.post( ROOT_URL + '/seller', formData, { headers }  )
        .then( ( resp ) => {
            const { seller } = resp.data;

            return { ok: true, seller }
        } )
        .catch( err => {
            return { ok: false, err }
        } )
    }

    const deleteSeller = ( id ) => {
        axios.put( ROOT_URL + '/seller/' + id, { status: "delete" } )
        .then( () => {
            return { ok: true }
        } )
        .catch( err => {
            return { ok: false, err }
        } )
    }
    return { sellersLoading, sellers, fetchSellers, addSeller, deleteSeller }
}

export default useSellers;