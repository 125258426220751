/* eslint-disable react-hooks/exhaustive-deps */
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Routes, Route } from "react-router-dom";
import Header from "./header/Header";
import Main from "./main/Main";
import Landpage from "./main/LandPage";
import ArchiveSystem from "./main/archiveSystem/ArchiveSystem";
import ManagementSystem from "./main/managementSystem/ManagementSystem";
// import SellersSystem from './main/sellersSystem/SellersSystem';
import ClientsSystem from "./main/clientSystem/ClientsSystem";
// import CompaniesSystem from './main/companiesSystem/CompaniesSystem';
import Profile from "./main/profile/Profile";
import { createContext, useEffect, useState } from "react";
import { loadUser } from "../store/userSlice";
import { useDispatch } from "react-redux";
import UserManagement from "./main/userManagement/UserManagement";
import ReportsSystem from "./main/reportsSystem/ReportsSystem";
import MessageSystem from "./main/messageSystem/MessageSystem";
import TaskSystem from "./main/taskSystem/TaskSystem";
import ProspectSystem from "./main/prospectSystem/ProspectSystem";
import {
  // MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import BrandSystem from "./main/brandSystem/BrandSystem";
// import { ErrorHandler } from "../shared/CustomFunc";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (err) => {
      // ErrorHandler(
      //   err,
      //   customToast,
      //   `Something went wrong: ${err.response.data.message}`
      // );
      console.log(err);
    },
  }),
  // mutationCache: new MutationCache({
  //   onError: (err) => {
  //     ErrorHandler(
  //       err,
  //       customToast,
  //       `Something went wrong: ${err.response.data.message}`
  //     );
  //   },
  // }),
});

// we need to manually close toast since we are putting it elsewhere in our dom
const ToastDataContext = createContext(null);

const UnderRoot = () => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    dispatch(loadUser());
    setChecked(true);
  }, []);

  const ToastParent = document.querySelector(".Toastify");
  const root = document.querySelector("#root");
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    if (!ToastParent) return;

    if (ToastParent.parentElement !== root) return;

    const body = document.querySelector("body");

    // we remove toastify from body and move it to our body
    // to make the dom order correct for our toastify
    try {
      body.appendChild(ToastParent);
    } catch (error) {
      console.log(error);
    }
    // we set the state
    // save toastify
    // we will be using this to keep track of everything
  }, [ToastParent, root]);

  const customToast = (message = "", type = "error") => {
    if (!message) return;
    if (!type) return;

    let toastId = "toast_" + (toasts.length + 1);

    if (type in toast) {
      try {
        toast[type](message, { toastId });
        setToasts([...toasts, toastId]);

        setTimeout(() => {
          toast.dismiss(toastId);
          setToasts(toasts.filter((_toastId) => _toastId !== toastId));
        }, 1500);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <ToastDataContext.Provider value={{ customToast }}>
        <QueryClientProvider client={queryClient}>
          <ToastContainer
            className={"toaster-container"}
            position="bottom-right"
            autoClose={1500}
            closeOnClick
            pauseOnFocusLoss={false}
            pauseOnHover={false}
            hideProgressBar
            rtl={false}
          />
          {checked && <Header />}
          {checked && (
            <Routes>
              <Route path="/*" element={<Main />}>
                <Route index element={<Landpage />} />
                <Route path="reset_password/*" element={<Landpage />} />
                <Route path="reports/*" element={<ReportsSystem />} />
                <Route path="dashboard" element={<ManagementSystem />} />
                <Route path="archive" element={<ArchiveSystem />} />
                {/* <Route path='sellers' element={ <SellersSystem /> } /> */}
                <Route path="clients" element={<ClientsSystem />} />
                {/* <Route path='companies' element={ <CompaniesSystem /> } /> */}
                <Route path="profile" element={<Profile />} />
                <Route path="users" element={<UserManagement />} />
                <Route path="brands" element={<BrandSystem />} />
                <Route path="message/*" element={<MessageSystem />} />
                <Route path="task" element={<TaskSystem />} />
                <Route path="prospects/*" element={<ProspectSystem />} />
              </Route>
            </Routes>
          )}
        </QueryClientProvider>
      </ToastDataContext.Provider>
    </>
  );
};

export default UnderRoot;
export { ToastDataContext };
