import { Button, TextField } from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorHandler } from "../../shared/CustomFunc";
import { ToastDataContext } from "../UnderRoot";
import HeaderLogged from "./HeaderLogged";

const ROOT_URL = process.env.REACT_APP_API_URL

const HeaderResetStep1 = () => {
    const navigate = useNavigate()

    const { customToast } = useContext( ToastDataContext )

    const [ email, setEmail ] = useState("");

    const submitHandler = e => {
        e.preventDefault();

        axios.post( ROOT_URL + '/user/forgot-password', { email } )
        .then( () => {
            customToast( "We have sent a link to reset your password. Please check your email.", "success" )
            setEmail("")
        } )
        .catch( err => ErrorHandler( err, customToast, "Error sending link to your password." ) )
    }

    return (
        <HeaderLogged submitHandler={ submitHandler }>
            <TextField 
                sx={ { 
                    display: "block",
                    marginBottom: "2rem",
                } }
                fullWidth
                type={ "email" }
                label="Email" 
                variant="standard"
                value={ email }
                required
                onChange={ (e) => setEmail( e.target.value ) }
                InputLabelProps={
                    {
                        style: {
                            color: "white"
                        }
                    }
                } />
            <Button
                className="-green"
                variant="contained"
                type="submit"
                sx={ { marginBottom: "1rem" } }
                fullWidth>
                Request Reset Password
            </Button>
            <Button
                className="-white"
                variant="contained"
                fullWidth
                onClick={ () => navigate("/") }>
                Go Back
            </Button>
        </HeaderLogged>
    )
}

export default HeaderResetStep1;