import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderLogged from "./HeaderLogged";
import axios from "axios";
import { ToastDataContext } from "../UnderRoot";

const ROOT_URL = process.env.REACT_APP_API_URL

const HeaderResetStep2 = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { customToast } = useContext( ToastDataContext )

    const [ showPassword, setShow ] = useState( false );
    const [ password, setPassword ] = useState("");

    const submitHandler = e => {
        e.preventDefault();

        const code = location?.search.replace("?code=", "")

        axios.post( ROOT_URL + '/user/update-password', { password, code } )
        .then( resp => {
            const status = resp.status;
            if ( status === 200 ) {
                customToast("Updated password successfully!", "success")
                navigate("/")
            } else {
                customToast("Code already used. Please request for a new code.")
                navigate("/")
            }
        } )
        .catch( err => {
            // console.log( err )
            customToast("Error updating password. Please try again later...")
        } )
    }

    return (
        <HeaderLogged submitHandler={ submitHandler } >
            <TextField 
                sx={ { 
                    display: "block",
                    marginBottom: "2rem",
                } }
                fullWidth
                type={ showPassword ? "text" : "password" }
                label="Password" 
                variant="standard"
                value={ password }
                required
                onChange={ e => setPassword( e.target.value ) }
                InputLabelProps={ {
                    style: {
                        color: "white"
                    },
                } } 
                InputProps={ {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={ () => setShow( !showPassword ) }>
                                { showPassword ? <Visibility /> : <VisibilityOff /> }
                            </IconButton>
                        </InputAdornment>
                    )
                } }
            />
            <Button
                className="-green"
                variant="contained"
                type="submit"
                sx={ { marginBottom: "1rem" } }
                fullWidth>
                Change Password
            </Button>
            <Button
                className="-white"
                variant="contained"
                fullWidth
                onClick={ () => navigate("/") }>
                Go Back
            </Button>
        </HeaderLogged>
    )
}

export default HeaderResetStep2;