import "./Loading.scss";

const Loading = () => {
  return (
    <div className="loading-container">
      <span>Getting Google reviews</span>
      <div className="dot-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default Loading;
