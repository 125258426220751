import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";

import {
  useAddEmailTemplate,
  useGetEmailTemplateById,
  useUpdateEmailImage,
  useUpdateEmailTemplate,
} from "../../../useHooks/useProspects";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ToastDataContext } from "../../UnderRoot";

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const THUMBNAIL_STYLE = {
  display: "flex",
  height: 75,
  minWidth: 0,
  overflow: "hidden",
};

const IMG_STYLE = {
  display: "block",
  width: "auto",
  height: "100%",
};

const IMAGE_BTN_STYLE = {
  p: "0.25rem 1.5rem !important",
  textTransform: "unset",
  background: "none !important",
  borderRadius: "15px",
  border: "1px solid #30A8FF",
  minHeight: "unset !important",
  fontSize: "0.75rem",
};

const INITIAL_DATA = {
  name: "",
  subject: "",
  content: "",
  header_image: "",
};

const EmailTemplateModal = ({ templateId, handleClose }) => {
  const { customToast } = useContext(ToastDataContext);

  const {
    data: selectedTemplate,
    isInitialLoading: isFetchingTemplate,
    isError,
  } = useGetEmailTemplateById(templateId);

  const { mutateAsync: addEmailTemplate, isLoading: isAdding } =
    useAddEmailTemplate();
  const { mutateAsync: updateEmailTemplate, isLoading: isUpdating } =
    useUpdateEmailTemplate();
  const { mutateAsync: updateImage } = useUpdateEmailImage();

  const [imageFile, setImageFile] = useState(null);
  const [formState, setFormState] = useState(INITIAL_DATA);

  const { open, acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "image/*": [],
      },
      onDrop: (acceptedFiles) => {
        const file = acceptedFiles[0];
        setImageFile(
          Object.assign(file, { preview: URL.createObjectURL(file) })
        );

        if (templateId) {
          const formData = new FormData();
          formData.append("header_image", acceptedFiles[0]);

          updateImage({ id: templateId, payload: formData });
        }
      },
    });

  const previewImage = useMemo(() => {
    if (imageFile) {
      if (acceptedFiles[0]?.name) {
        return imageFile.preview;
      }
    }

    if (selectedTemplate && selectedTemplate?.header_image) {
      return STORAGE_URL + selectedTemplate.header_image;
    }

    return null;
  }, [selectedTemplate, imageFile, acceptedFiles]);

  const handleChange = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDeleteImage = () => {
    if (!window.confirm("Do you want to delete header image?")) return;

    if (selectedTemplate?.header_image) {
      // handle delete image to the API later
      const formData = new FormData();
      formData.append("image_delete", true);

      updateImage({ id: templateId, payload: formData });
    }

    setImageFile(null);
  };

  const handleSave = async () => {
    if (!formState.name) {
      customToast("Template name is required");
      return;
    }

    if (!formState.subject) {
      customToast("Email subject is required");
      return;
    }

    if (!formState.content) {
      customToast("Email content is required");
      return;
    }

    const formattedContent = formState.content.replace(/\n/g, "<br/>");
    const finalContent = `<p>${formattedContent}</p>`;

    const formData = new FormData();

    formData.append("name", formState.name);
    formData.append("subject", formState.subject);
    formData.append("content", finalContent);

    if (!templateId && acceptedFiles[0]?.name) {
      formData.append("header_image", acceptedFiles[0]);
    }

    let result;

    if (!templateId) {
      result = await addEmailTemplate(formData);
    } else {
      result = await updateEmailTemplate({
        id: templateId,
        payload: { ...formState, content: finalContent },
      });
    }

    if (result.status === 200 || result.status === 201) {
      handleClose();
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      setFormState({
        name: selectedTemplate.name,
        subject: selectedTemplate.subject,
        content: selectedTemplate.content,
        header_image: selectedTemplate.header_image,
      });
    }
    //eslint-disable-next-line
  }, [selectedTemplate]);

  // Close modal if fetch errors
  if (isError) handleClose();
  // Spinner when fetching
  if (isFetchingTemplate) {
    return (
      <Box sx={{ padding: "2rem" }}>
        <FidgetSpinner fidgetStyle={{ width: "3rem", height: "3rem" }} />
      </Box>
    );
  }

  if (!isError && !isFetchingTemplate) {
    return (
      <Box sx={{ padding: { xs: "1rem", md: "2rem" } }}>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <TextField
              fullWidth
              variant="standard"
              label="Template Name"
              value={formState.name}
              onChange={(e) => handleChange("name", e.target.value)}
              inputProps={{ style: { fontSize: "1.5rem" } }}
              autoComplete="off"
            />

            <TextField
              fullWidth
              variant="standard"
              label="Email Subject"
              value={formState.subject}
              onChange={(e) => handleChange("subject", e.target.value)}
              inputProps={{ style: { fontSize: "1.5rem" } }}
              autoComplete="off"
            />

            <TextField
              fullWidth
              variant="standard"
              label="Email Content"
              value={formState.content}
              onChange={(e) => handleChange("content", e.target.value)}
              multiline
              autoComplete="off"
            />

            <Box>
              <Typography fontSize={"0.75rem"} color={"rgba(0, 0, 0, 0.6)"}>
                Email Header Image
              </Typography>

              <Box
                {...getRootProps()}
                sx={{
                  p: previewImage ? "0" : "2rem",
                  mt: "0.5rem",
                  border: previewImage ? "none" : "2px dotted #000000",
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: previewImage
                    ? "fit-content"
                    : { xs: "100%", md: "50%" },
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  {previewImage ? (
                    <Box sx={THUMBNAIL_STYLE}>
                      <img
                        src={previewImage}
                        style={IMG_STYLE}
                        alt={"Preview email header"}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          URL.revokeObjectURL(previewImage);
                        }}
                      />
                    </Box>
                  ) : (
                    <FontAwesomeIcon
                      icon={solid("upload")}
                      size="lg"
                      style={{
                        color: "#30A8FF",
                      }}
                    />
                  )}

                  {previewImage || imageFile?.name ? null : isDragActive ? (
                    <Typography variant="body1" fontSize="0.875rem">
                      Drop your file here
                    </Typography>
                  ) : (
                    <Typography variant="body1" fontSize="0.875rem">
                      Click or drag here to upload file
                    </Typography>
                  )}
                </Box>
              </Box>

              {previewImage && (
                <Box sx={{ mt: "1rem", display: "flex", gap: "1rem" }}>
                  <Button
                    type="button"
                    disableRipple
                    onClick={open}
                    sx={IMAGE_BTN_STYLE}
                  >
                    Replace
                  </Button>
                  <Button
                    type="button"
                    disableRipple
                    onClick={handleDeleteImage}
                    sx={{
                      ...IMAGE_BTN_STYLE,
                      color: "red",
                      borderColor: "red",
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              mt: "2rem",
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Button
              variant="contained"
              type="button"
              className="-gray"
              onClick={handleClose}
              sx={{
                width: { xs: "100%", md: "unset" },
                minWidth: "8rem",
                p: "0.5rem 1.5rem",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              disabled={isAdding || isUpdating}
              onClick={handleSave}
              sx={{
                width: { xs: "100%", md: "unset" },
                minWidth: "8rem",
                p: "0.5rem 1.5rem",
              }}
            >
              {isAdding || isUpdating ? (
                <FidgetSpinner
                  fidgetStyle={{ width: "1rem", height: "1rem" }}
                />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </DialogContent>
      </Box>
    );
  }
};

export default EmailTemplateModal;
