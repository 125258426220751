import { TextField } from "@mui/material";


const INPUT_LABEL_PROPS = {
  shrink: true,
}

const ManagementOrderModalInfoAdd = ({
    children,
    order_id,
    user_role,
    disabledProps,
    date,
    setDate,
}) => {
    return <ul className="info">
        <li className="fd">
            <TextField 
                label="DATE" 
                value={ date || "" }
                disabled={ disabledProps } 
                onChange={ e => setDate( e.target.value ) }  
                InputLabelProps={ INPUT_LABEL_PROPS } 
                type="date"
                required={ order_id ? true : false }
                variant="standard" />
            {
                order_id && user_role !== 3 ? 
                <TextField 
                    label="ORDER ID" 
                    value={ order_id || "" } 
                    disabled
                    variant="standard" /> : <div></div>
            }
        </li>
        { children }
    </ul>
}

export default ManagementOrderModalInfoAdd;