import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React from "react";

const StarsIcons = ({
  totalStars = 5,
  stars = 0,
  value = null,
  showLabels = true,
}) => {
  return (
    <Box sx={{ width: "100%", display: "flex", gap: "1.5rem" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        {Array.from({ length: totalStars }, (_, index) => {
          return (
            <FontAwesomeIcon
              key={index}
              icon={solid("star")}
              style={{ color: stars > index ? "#fdca2f" : "#cbcbcb" }}
            />
          );
        })}
      </Box>
      {showLabels && (
        <>
          <Typography fontWeight={700} whiteSpace="nowrap">{`${stars}${
            stars === 1 ? " Star: " : " Stars: "
          }`}</Typography>
          <Typography sx={{ ml: "auto" }}>{value ?? 0}</Typography>
        </>
      )}
    </Box>
  );
};

export default StarsIcons;
