import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ToastDataContext } from '../../UnderRoot';
// import TextArray from '../../sharedComponent/TextArray';
import './UserManagementModal.scss';

const ROLE_DROPDOWN = [
    {
        label: "Admin",
        value: 1
    },
    {
        label: "Accountant",
        value: 2
    },
    {
        label: "Seller",
        value: 3
    }
]

const STATUS_DROPDOWN = [
    {
        label: "ACTIVE",
        value: 1
    },
    {
        label: "DELETE",
        value: 0
    }
]

const UserManagementModal = ( { selectedUser={}, handleCreate, handleUpdate, handleClose } ) => {
    const { customToast } = useContext( ToastDataContext )
    
    const [ email, setEmail ] = useState( "" )
    const [ name, setName ] = useState( "" )

    const [ role, setRole ] = useState( 1 )
    const [ status, setStatus ] = useState( 1 )

    // const [ alternate_emails, setAlternateEmails ] = useState( [] )

    const [ showPassword, setShow ] = useState( false )
    const [ password, setPassword ] = useState( "" )

    const [ phone, setPhone ] = useState( "" )
    const [ contact_url, setContactUrl ] = useState( "" )

    // const addAlternate = email => {
    //     setAlternateEmails( [ ...alternate_emails, email ] )
    // }

    // const deleteAlternate = email => {
    //     const filteredEmails = alternate_emails.filter( _email => _email !== email )

    //     setAlternateEmails( filteredEmails )
    // }

    useEffect( () => {
        if ( !selectedUser.id ) return

        if ( selectedUser?.id !== "none" ) {
            setEmail( selectedUser.email )
            setName( selectedUser.name )
            setRole( selectedUser.role )
            setStatus( selectedUser.status )
            // setAlternateEmails( selectedUser.alternate_emails )
            setPhone( selectedUser?.phone || "" )
            setContactUrl( selectedUser?.contact_url || "" )
        }
    }, [ selectedUser ] )

    const submitHandler = async e => {
        e.preventDefault()

        if ( !email ) {
            customToast("Email must not be empty")
            return
        }

        if ( !name ) {
            customToast("Name must not be empty")
            return
        }

        if ( selectedUser?.id !== "none" ) {
            const data = {}

            if ( email !== selectedUser.email ) data.email = email
            if ( name !== selectedUser.name ) data.name = name
            if ( role !== selectedUser.role ) data.role = role
            if ( status !== selectedUser.status ) data.status = status
            if ( phone !== selectedUser.phone ) data.phone = phone
            if ( contact_url !== selectedUser.contact_url ) data.contact_url = contact_url

            // let alternate_changes = false;

            // if ( alternate_emails.length !== selectedUser.alternate_emails.length ) alternate_changes = true
            
            // if ( !alternate_changes ) {
            //     alternate_emails.forEach( _email => {
            //         let matched = false;

            //         selectedUser.alternate_emails.forEach( _alt_sel_emails => {
            //             if ( _alt_sel_emails === _email ) matched = true
            //         } )

            //         if ( !matched ) alternate_changes = true
            //     } )
            // }

            // if ( alternate_changes ) data.alternate_emails = alternate_emails

            await handleUpdate( data, selectedUser.id )
            handleClose()

        } else {
            if ( !password ) {
                customToast("Password field must not be empty")
                return
            }

            const data = {
                email,
                name,
                // alternate_emails,
                password,
                password_confirmation: password,
                phone,
                contact_url
            }

            if ( role === 1 ) data.role = "admin"
            if ( role === 2 ) data.role = "accountant"
            if ( role === 3 ) data.role = "seller"
            
            if ( status === 1 ) data.status = "admin"
            if ( status === 2 ) data.status = "accountant"

            const formData = new FormData()

            Object.keys( data ).forEach( key => formData.append( key, data[ key ] ) )
            await handleCreate( formData )
            handleClose()
        }
    }

    return (
        <form className='user-management-modal' onSubmit={ submitHandler }>
            <ul>
                <li>
                    <TextField 
                        id="email" 
                        value={ email } 
                        label="Email"
                        variant='standard'
                        fullWidth
                        onChange={ e => setEmail( e.target.value ) } />
                </li>
                <li>
                    <TextField 
                        id="name" 
                        value={ name } 
                        label="Name"
                        variant='standard'
                        fullWidth
                        onChange={ e => setName( e.target.value ) } />
                </li>
                <li>
                    <TextField 
                        id="phone" 
                        value={ phone } 
                        label="Phone"
                        variant='standard'
                        fullWidth
                        onChange={ e => setPhone( e.target.value ) } />
                </li>
                <li>
                    <TextField 
                        id="contact_url" 
                        value={ contact_url } 
                        label="Contact URL"
                        variant='standard'
                        fullWidth
                        onChange={ e => setContactUrl( e.target.value ) } />
                </li>
                {
                    selectedUser?.id === "none" &&
                    <li>
                        <TextField 
                            id="password"
                            type={ showPassword ? "text" : "password" }
                            label="Password"
                            value={ password }
                            variant="standard"
                            fullWidth
                            onChange={ e => setPassword( e.target.value ) }
                            InputProps={ {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={ () => setShow( !showPassword ) }>
                                            { showPassword ? <Visibility /> : <VisibilityOff /> }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            } } />
                    </li>
                }
                <li>
                    <FormControl
                        fullWidth>
                        <InputLabel 
                            id="role_label"
                            sx={ {
                                marginLeft: "-.9rem"
                            } }>Role
                        </InputLabel>
                        <Select
                            id={ `role_user` }
                            label="ROLE"
                            labelId='role_label'
                            variant="standard"
                            fullWidth
                            value={ role }
                            onChange={ e => setRole(e.target.value) } >
                                {
                                    ROLE_DROPDOWN.map( ( role, roleI ) =>
                                        <MenuItem key={ roleI }
                                            value={ role.value }>
                                            { role.label }
                                        </MenuItem> )
                                }

                        </Select>
                    </FormControl>
                </li>
                <li>
                    <FormControl
                        fullWidth>
                        <InputLabel 
                            id="status_label"
                            sx={ {
                                marginLeft: "-.9rem"
                            } }>Status
                        </InputLabel>
                        <Select
                            id={ `status_user` }
                            label="STATUS"
                            variant="standard"
                            value={ status }
                            onChange={ e => setStatus(e.target.value) } >
                                {
                                    STATUS_DROPDOWN.map( ( status, statusI ) =>
                                        <MenuItem key={ statusI }
                                            value={ status.value }>
                                            { status.label }
                                        </MenuItem> )
                                }

                        </Select>
                    </FormControl>
                </li>
                {/* <li>
                    <label htmlFor='alternate_emails'>
                        Alternate Emails
                    </label>
                    <TextArray 
                        id="alternate_emails" 
                        boxStyle="standard"
                        arrayValue={ alternate_emails }
                        handleAdd={ addAlternate }
                        handleDelete={ deleteAlternate }
                        handleError={ customToast }
                        sx={ { width: "25rem" } } />
                </li> */}
            </ul>
            <Box className='base-btns fd' sx={{ gap: '1rem' }}>
                <Button className='-gray' fullWidth variant="contained" onClick={ () => handleClose() }>
                    Cancel
                </Button>
                <Button fullWidth type="submit" variant="contained">
                    { selectedUser?.id === "none" ? "Create Account" : "Update Account" }
                </Button>
            </Box>
        </form>
    )
}

export default UserManagementModal;