/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { useCallback, useState } from "react";
import axios from 'axios';
// import qs from 'qs';
import { getHeader } from '../shared/Authorization';
import debounce from "lodash.debounce";

const ROOT_URL = process.env.REACT_APP_API_URL

const useInputSearch = ( endpoint, key="", second_key="" ) => {
    const [ items, setItems ] = useState([])

    const _searchFunc = ( searchVal ) => {
        if ( !searchVal ) return

        const headers = getHeader()

        axios.get( ROOT_URL + endpoint + searchVal, { headers } )
        .then( resp => {
            if ( key ) {
                if ( second_key ) setItems( resp?.data?.[key]?.[second_key] || [] )
                else setItems( resp?.data?.[key] || [] )
            }
            else setItems( resp?.data || [] )
        } )
        .catch( err => {
            setItems([])
        } )
    }

    const debouncedSearchFunc = useCallback( debounce( _searchFunc, 500 ), [ ] )

    return { items, searchFunc:debouncedSearchFunc }
}

export default useInputSearch;