import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeader } from '../shared/Authorization';

const ROOT_URL = process.env.REACT_APP_API_URL

export const messagesSlice = createSlice( {
    name: 'messages',
    initialState: {
        value: {
            received: [],
            sent: [],
            reading: {},
            clients: []
        }
    },
    reducers: {
        loadMessages: ( state, action ) => {
            state.value.received = action.payload.messages
        },
        loadSentMessages: ( state, action ) => {
            state.value.sent = action.payload.messages
        },
        loadReadingMessage: ( state, action ) => {
            state.value.reading = action.payload.message
        },
        loadClients: ( state, action ) => {
            state.value.clients = action.payload.clients
        },
        cleanMessages: state => {
            state.value.received = []
        },
        cleanSentMessages: state => {
            state.value.sent = []
        },
        cleanReadingMessage: ( state ) => {
            state.value.reading = {}
        },
        cleanClients: state => {
            state.value.clients = []
        }
    }
} )

export const { 
    loadMessages, 
    loadSentMessages, 
    loadReadingMessage,
    loadClients, 
    cleanMessages, 
    cleanSentMessages,
    cleanReadingMessage,
    cleanClients
} = messagesSlice.actions

export const fetchMessages = () => async dispatch => {
    const headers = getHeader();

    return await axios.get( ROOT_URL + '/message/inbox', { headers } )
    .then( resp => {
        dispatch( loadMessages( resp.data ) )
        return { ok: true }
    } )
    .catch( err => console.error(err?.message) )
}

export const fetchSentMessages = () => async dispatch => {
    const headers = getHeader();

    return await axios.get( ROOT_URL + '/message/sent', { headers } )
    .then( resp => {
        dispatch( loadSentMessages( resp.data ) )
        return { ok: true }
    } )
    .catch( err => console.error(err?.message) )
}

export const fetchReadingMessageSent = id => async dispatch => {
    const  headers = getHeader();

    return await axios.get( ROOT_URL + '/message/inbox/' + id, { headers } )
    .then( resp => {
        dispatch( loadReadingMessage( resp.data ) )
        return { ok: true }
    } )
    .catch( err => console.error(err?.message) )
}

export const fetchReadingMessageInbox = id => async dispatch => {
    const  headers = getHeader();

    return await axios.put( ROOT_URL + '/message/inbox/' + id, {},  { headers } )
    .then( resp => {
        dispatch( loadReadingMessage( resp.data ) )
        return { ok: true }
    } )
    .catch( err => console.error(err?.message) )
}

export const sendMessage = data => async dispatch => {
    const headers = getHeader();

    return await axios.post( ROOT_URL + '/message/send-message', data, { headers } )
    .then( () => { return { ok: true, message: "Message sent" } } )
    .catch( err => console.error(err?.message) )
}

export const fetchMessageClients = () => async dispatch => {
    const headers = getHeader()

    return await axios.get( ROOT_URL + '/message/clients', { headers } )
    .then( resp => {
        dispatch( loadClients( resp.data ) )
        return { ok: true }
    } )
    .catch( err => console.error(err?.message) )
}

export default messagesSlice.reducer