import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './LineChart.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const LineChart = ({ datasetIdKey='id', displayLegend=false, data, height = '18rem' }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          padding: 16,
          autoSkip: true,
          maxTicksLimit: 5,
          align: 'inner'
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        title: {
          display: false,
        },
        ticks: {
          padding: 16,
          maxTicksLimit: 5
        },
      },
    },
    plugins: {
      legend: {
        display: displayLegend,
        // position: 'bottom',
      }
    }
  };

  return (
    <div style={{ height }}>
      <Line datasetIdKey={datasetIdKey} data={data} options={options} />
    </div>
  );
};

export default LineChart;