import { useContext, useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Box,
  TablePagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getHeader } from "../../../shared/Authorization";
import axios from "axios";
import { ToastDataContext } from "../../UnderRoot";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import { StyledTableCell } from "../../../shared/CustomFunc";

const ROOT_URL = process.env.REACT_APP_API_URL;

const TableHeader = ({ headers, role }) => {
  return (
    <TableHead>
      <TableRow sx={{ borderTop: "solid 1px rgba(224, 224, 224, 1)" }}>
        {headers?.map((header, index) => {
          if (role !== 2 && role !== 3 && header.id === "notes") return null;
          return (
            <StyledTableCell key={index} sx={{ whiteSpace: "nowrap" }}>
              {header.label}
            </StyledTableCell>
          );
        })}
        <StyledTableCell>
          <span style={{ marginLeft: "0.5rem" }}>Actions</span>
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

const TaskTable = ({
  showCompleted,
  fetchTasks,
  tasks,
  tasksPagination,
  role,
  handleOpenModal,
}) => {
  const { customToast } = useContext(ToastDataContext);
  const [newTasks, setNewTasks] = useState([]);

  useEffect(() => {
    setNewTasks([...tasks]);
  }, [tasks]);

  const [editId, setEditId] = useState("");
  const rowsPerPage = 10;
  const [spinner, setSpinner] = useState("");

  const handleCheck = async (task) => {
    setEditId(task.id);
    setSpinner("completeTask");
    const headers = getHeader();
    const formData = new FormData();
    formData.append("title", task.title);
    formData.append("description", task.description);
    let successMsg;

    if (task.status === 1) {
      formData.append("status", 2);
      successMsg = "Task status set to Completed !";
    } else {
      formData.append("status", 1);
      successMsg = "Task status set to Incomplete !";
    }

    const todoUrl = `/todo/${task.id}`;

    await axios
      .post(ROOT_URL.concat(todoUrl), formData, { headers })
      .then((resp) => {
        const status = resp.status;
        if (status === 200) {
          customToast(successMsg, "success");
          const updatedTask = {
            ...resp?.data?.todo_list,
            status: parseInt(resp?.data?.todo_list?.status),
          };
          const updatedTasks = [...newTasks];
          const taskIndex = newTasks.findIndex(
            (newTask) => newTask.id === updatedTask.id
          );
          updatedTasks[taskIndex] = {
            ...updatedTasks[taskIndex],
            ...updatedTask,
          };

          setNewTasks(updatedTasks);
          setEditId("");
        } else {
          customToast("An error has occured when changing task status");
        }
      })
      .catch((err) => {
        customToast(err.response.data.message);
      });
    setSpinner("");
  };

  const handleEdit = (id) => {
    handleOpenModal(id);
  };

  const handleDeleteClick = async (task) => {
    const deleteTask = window.confirm("Are you sure you want to delete?");

    if (!deleteTask) {
      return;
    }

    setSpinner("deleteTask");
    setEditId(task.id);
    const headers = getHeader();
    const formData = new FormData();
    formData.append("title", task.title);
    formData.append("description", task.description);
    formData.append("status", 0);

    try {
      await axios.post(ROOT_URL + "/todo/" + task.id, formData, { headers });
      customToast("Successfully deleted task !", "success");
      fetchTasks();
    } catch (err) {
      customToast(err.response.data.message);
    }

    setEditId("");
    setSpinner("");
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHeader
            role={role}
            headers={[
              { id: "status", label: "Status" },
              { id: "title", label: "Title" },
              { id: "description", label: "Description" },
              { id: "title", label: "Client Email" },
              { id: "orderId", label: "Order" },
              { id: "company", label: "Company" },
              { id: "phone", label: "Contact #" },
              { id: "taskDate", label: "Date" },
              { id: "remarks", label: "Remarks" },
              { id: "notes", label: "Personal Notes" },
            ]}
          />
          <TableBody>
            {newTasks.map((task, index) => {
              if (task.status !== 1 && !showCompleted) {
                return "";
              }

              const client_email =
                task?.todo?.client?.email ||
                task?.todo?.order?.client?.email ||
                "";

              const client_phone =
                task?.todo?.client?.phone ||
                task?.todo?.order?.client?.phone ||
                "";

              const isItemSelected = task.status === 2;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                >
                  <StyledTableCell padding="checkbox">
                    {editId === task.id && spinner === "completeTask" ? (
                      <FidgetSpinner
                        fidgetStyle={{ height: "1rem", width: "1rem" }}
                      />
                    ) : (
                      <Checkbox
                        checked={isItemSelected}
                        onChange={() => handleCheck(task)}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {task.title}
                  </StyledTableCell>
                  <StyledTableCell>{task.description}</StyledTableCell>
                  <StyledTableCell>{client_email}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {task.todo?.order?.id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {task.todo?.order?.company?.name}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    <a href={`tel:${client_phone}`}>{client_phone}</a>
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {task?.task_date}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {task?.remarks}
                  </StyledTableCell>
                  {role === 2 || role === 3 ? (
                    <StyledTableCell component="th" scope="row">
                      {task?.todo?.todo_notes?.notes}
                    </StyledTableCell>
                  ) : null}
                  <StyledTableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(task?.id)}
                      >
                        <EditIcon sx={{ color: "#0094FF" }} />
                      </IconButton>

                      {role === 2 ? null : spinner === "deleteTask" &&
                        editId === task.id ? (
                        <FidgetSpinner
                          fidgetStyle={{ height: "1rem", width: "1rem" }}
                        />
                      ) : (
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteClick(task)}
                        >
                          <DeleteIcon sx={{ color: "#FF0000" }} />
                        </IconButton>
                      )}
                    </Box>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          borderTop: "solid 1px rgba(224, 224, 224, 1)",
          borderBottom: "solid 1px rgba(224, 224, 224, 1)",
        }}
        rowsPerPageOptions={[]}
        component="div"
        rowsPerPage={rowsPerPage}
        showFirstButton
        showLastButton
        count={tasksPagination?.total || 0}
        page={tasksPagination?.current_page - 1 || 0}
        onPageChange={(e, newPage) => {
          fetchTasks(newPage + 1);
        }}
      />
    </>
  );
};

export default TaskTable;
