import { Button } from "@mui/material";
import { useGenerateInvoicePDF } from "../../../useHooks/useOrders";
import "./ManagementInvoice.scss";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";

const ManagementInvoice = ({
  handleInvoiceImage,
  fileName,
  fileDownload,
  orderId,
}) => {
  const { refetch: downloadInvoicePDF, isFetching } =
    useGenerateInvoicePDF(orderId);

  const uploadHandler = () => {
    const inp = document.querySelector("#invoice");

    inp.click();
  };

  return (
    <div className="invoice-con">
      <input
        id="invoice"
        type="file"
        onChange={(e) => handleInvoiceImage(e.target.files[0])}
      />
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <p className="file-upload-link">
          <Button
            variant="text"
            sx={{
              p: "0 !important",
              color: fileName ? "#e34040" : "#30a8ff",
              background: "none !important",
              "&:hover": {
                borderBottom: fileName ? "none" : "1px solid",
                borderRadius: 0,
              },
              // borderRadius: "40px",
            }}
            onClick={uploadHandler}
          >
            {fileName || "Upload Invoice"}
          </Button>
          {fileName && <span onClick={() => handleInvoiceImage("")}>✖</span>}
        </p>
        {fileDownload && (
          <a
            href={fileDownload}
            download={fileName.split(".")[0]}
            target={"_blank"}
            rel="noreferrer"
          >
            DOWNLOAD FILE
          </a>
        )}
      </div>
      {orderId && (
        <div>
          <Button
            variant="text"
            className="generate-pdf-link"
            disabled={isFetching}
            onClick={downloadInvoicePDF}
            sx={{
              background: "none !important",
              display: "flex",
              gap: "0.5rem",
              "&:hover": {
                borderBottom: "1px solid",
                borderRadius: 0,
              },
              "&:disabled": {
                background: "none !important",
                color: "#30a8ff !important",
              },
            }}
          >
            {isFetching && (
              <>
                <FidgetSpinner
                  fidgetStyle={{ height: "1.5rem", width: "1.5rem" }}
                />
                Generating invoice...
              </>
            )}
            {!isFetching && "Generate Invoice PDF"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ManagementInvoice;
