/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Dialog, DialogContent } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHeader } from "../../../shared/Authorization";
import { ErrorHandler } from "../../../shared/CustomFunc";
import SectionWrapper from "../../../wrapper/SectionWrapper"
import { ToastDataContext } from "../../UnderRoot";
import './UserManagement.scss';
import UserManagementModal from "./UserManagementModal";
import UserManagementModalTransfer from "./UserManagementModalTransfer";
import UserTable from "./UserTable";

const ROOT_URL = process.env.REACT_APP_API_URL

const UserManagement = () => {
    const navigate = useNavigate();

    const { customToast } = useContext( ToastDataContext )

    const LoggedUser = useSelector( state => state.user.value );
    const [ users, setUsers ] = useState( [] )

    const handleUpdate = async ( data, user_id,  ) => {
        const dataModified = {}

        if ( "role" in data ) {
            if ( data.role === 1 ) dataModified.role = "admin"
            if ( data.role === 2 ) dataModified.role = "accountant"
            if ( data.role === 3 ) dataModified.role = "seller"
        }

        if ( "status" in data ) {
            if ( data.status === 1 ) dataModified.status = "active"
            if ( data.status === 0 ) dataModified.status = "delete"
        }

        if ( "name" in data ) dataModified.name = data.name
        if ( "email" in data ) dataModified.email = data.email
        if ( "contact_url" in data ) dataModified.contact_url = data.contact_url
        if ( "phone" in data ) dataModified.phone = data.phone 

        const headers = getHeader()

        return await axios.put( ROOT_URL + '/user/update/' + user_id, dataModified ,{ headers } )
        .then( () => {
            customToast("User update succesfully.", "success")
            fetchTableData()
            return { ok: true }
        } )
        .catch( err => {
            // console.log( err )
            customToast("Error updating user. Try again later...")
            return { ok: false }
        } )
    }

    const handleCreate = formData => {

        const headers = getHeader()

        axios.post( ROOT_URL + '/user/create', formData, { headers } )
        .then( () => {
            customToast("User created successfully.", "success")
            fetchTableData()
            return { ok: true }
        } )
        .catch( err => {
            ErrorHandler( err, customToast, "Error creating User." )

            return { ok: false }
        } )
    }

    const handleTransferorder = (emailsArray, newSellerEmail) => {
        if (emailsArray.length <= 0 || !newSellerEmail) return

        const headers = getHeader()
        const payload = { emails: JSON.stringify(emailsArray), to_seller_email: newSellerEmail }

        axios.post( ROOT_URL + '/seller/transfer', payload, { headers } )
        .then( resp => {
            customToast('Successfully transferred orders !', 'success')
        } )
        .catch( err => ErrorHandler( err, customToast, err.response.data.message ))
    }

    const dateOrderFunc = ( a, b ) => {
        return new Date( b.created_at ) - new Date( a.created_at )
    }

    const fetchTableData = () => {
        const headers = getHeader()

        axios.get( ROOT_URL + '/user/all', { headers } )
        .then( resp => {
            setUsers( resp.data.user.sort( dateOrderFunc ) )
        } )
        .catch(  err => ErrorHandler( err, customToast, "Error fetching Users." ) )
    }

    useEffect( () => {
        if ( LoggedUser?.id ) {
            if ( !users.length ) {
                if ( LoggedUser?.role === 1 ) fetchTableData()
                else navigate( "/dashboard" )
            }
        }
    }, [ LoggedUser ] )

    const [ selectedUser, setSelectedUser ] = useState( {} );
    const [ updateUserOpen, setUpdateUserOpen ] = useState( false );
    const [ transferOrderOpen, setTransferOrderOpen ] = useState( false );
    const [ modalType, setModalType ] = useState( 'view' );

    const handleUser = _user => setSelectedUser( _user )

    useEffect( () => {
        if ( selectedUser?.id ) {
            if ( modalType === 'view') {
                setUpdateUserOpen( true )
            } else {
                setTransferOrderOpen( true )
            }
        } else {
            setUpdateUserOpen( false )
            setTransferOrderOpen( false )
        }
    }, [ selectedUser ] )


    return (
        <SectionWrapper sectionClass={ "user-management" }>
            <Dialog 
                // fullWidth
                maxWidth={'md'}
                open={ modalType === 'view' ? updateUserOpen : transferOrderOpen }
                onClose={ () => setSelectedUser( {} ) } >
                    <DialogContent sx={{ padding: '2rem' }}>
                        { modalType === 'view'
                            ? <UserManagementModal
                                handleClose={ () => setSelectedUser( {} ) }
                                {
                                    ...{
                                        handleCreate,
                                        handleUpdate,
                                        selectedUser
                                    }
                                } />
                            : <UserManagementModalTransfer
                                handleTransferOrder={ handleTransferorder }
                                handleClose={ () => setSelectedUser( {} ) } />
                        }
                    </DialogContent>
            </Dialog>
            <UserTable users={ users } fetchTableData={ fetchTableData } setModalType={ setModalType } handleUserUpdate={ handleUpdate } handleUser={ handleUser } />
            <Box sx={{ margin: '2rem 0', display: 'flex', gap: '1rem' }}>
                <Button
                    variant='contained'
                    onClick={ () => { 
                        setSelectedUser( { id: "none" } )
                        setModalType( 'view' )
                    }}>
                        Create Account
                </Button>
                <Button
                    variant='contained'
                    className="-white"
                    onClick={ () => { 
                        setSelectedUser( { id: "none" } )
                        setModalType( 'transfer' )
                    }}>
                        Transfer Orders
                </Button>
            </Box>
        </SectionWrapper>
    )
}

export default UserManagement;