import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ data, height='20rem' }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    // layout: {
    //   padding: {
    //     left: 220
    //   }
    // },
    scales: {
      x: {
        beginAtZero: true,
        // max: 5,
        ticks: {
          autoSkip: true,
          maxTickLimit: 5,
        },
      },
      y: {
        grid: {
          display: false
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  return(
    <div style={{ height }}>
      <Bar options={options} data={data} />
    </div>
  );
}

export default BarChart