import React, { useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { StyledTableCell } from "../../../shared/CustomFunc";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import { useDeleteBrand } from "../../../useHooks/useBrands";

const BrandTable = ({ brands = [], handleEdit }) => {
  const [identifier, setIdentifier] = useState("");

  const { mutateAsync: deleteBrand, isLoading: isDeleting } = useDeleteBrand();

  const handleEditClick = (id) => {
    handleEdit(id);
  };

  const handleDeleteClick = (id) => {
    if (!window.confirm("Do you want to delete this brand?")) return;
    setIdentifier(id);
    deleteBrand({ id });
  };

  return (
    <TableContainer>
      <Table>
        <TableHeader
          headers={[
            { id: "id", label: "ID" },
            { id: "name", label: "Brand Name" },
            { id: "code", label: "Brand Code" },
            { id: "address", label: "Address" },
            { id: "currency", label: "Currency" },
          ]}
        />
        <TableBody>
          {brands.map((brand, index) => {
            // Remove last index logic and styling once pagination is done
            const lastIndex = brands.length === index + 1 ? true : false;

            return (
              <TableRow
                key={index}
                sx={
                  lastIndex
                    ? { borderBottom: "solid 1px rgba(224, 224, 224, 1)" }
                    : {}
                }
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {brand.id}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "18.75rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {brand.name}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {brand.code}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "18.75rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {brand.address}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {brand.currency}
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditClick(brand.id)}
                    >
                      <EditIcon sx={{ color: "#0094FF" }} />
                    </IconButton>

                    {isDeleting && identifier === brand.id ? (
                      <FidgetSpinner
                        fidgetStyle={{ height: "1rem", width: "1rem" }}
                      />
                    ) : (
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteClick(brand.id)}
                      >
                        <DeleteIcon sx={{ color: "#FF0000" }} />
                      </IconButton>
                    )}
                  </Box>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BrandTable;

const TableHeader = ({ headers }) => {
  return (
    <TableHead>
      <TableRow sx={{ borderTop: "solid 1px rgba(224, 224, 224, 1)" }}>
        {headers?.map((header) => {
          return (
            <StyledTableCell key={header.id} sx={{ whiteSpace: "nowrap" }}>
              {header.label}
            </StyledTableCell>
          );
        })}
        <StyledTableCell>
          <span style={{ marginLeft: "0.5rem" }}>Actions</span>
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};
