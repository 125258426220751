/* eslint-disable react-hooks/exhaustive-deps */
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material"
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getHeader } from "../../../shared/Authorization";
import { ErrorHandler } from "../../../shared/CustomFunc";
import TextArray from "../../../sharedComponent/TextArray"
import SectionWrapper from "../../../wrapper/SectionWrapper"
import { ToastDataContext } from "../../UnderRoot";
import './Profile.scss';

const ROOT_URL = process.env.REACT_APP_API_URL

const Profile = () => {
    const { customToast } = useContext( ToastDataContext )

    const user = useSelector( state => state.user.value )

    const [ origName, setOrigName ] = useState("")
    const [ origEmail, setOrigEmail ] = useState("")
    const [ origAlternate, setOrigAlternate ] = useState([])
    const [ origPhone, setOrigPhone ] = useState("")
    const [ origContactURL, setOrigContactURL ] = useState("")

    const [ name, setName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ alternate_emails, setAlternateEmails ] = useState( [] )

    const [ phone, setPhone ] = useState("")
    const [ contact_url, setContactURL ] = useState("")

    const addAlternate = email => {
        setAlternateEmails( [ ...alternate_emails, email ] )
    }

    const deleteAlternate = email => {
        const filteredEmails = alternate_emails.filter( _email => _email !== email )

        setAlternateEmails( filteredEmails )
    }

    const alternateSupplier = arrayObj => {
        return arrayObj.map( item => item.email )
    }

    const [ showPasswordNew, setShowNew ] = useState( false )
    const [ showPasswordOld, setShowOld ] = useState( false )

    const [ old_password, setOldPass ] = useState("")
    const [ new_password, setNewPass ] = useState("")

    const fetchUser = () => {
        const headers = getHeader()

        axios.get( ROOT_URL + '/user', { headers } )
        .then( resp => {
            const _user = resp.data.user
            // const { contact:_contact, contact_url: _contact_url } = _user?.seller || {}

            setName( _user.name )
            setOrigName( _user.name )

            setEmail( _user.email )
            setOrigEmail( _user.email )

            setAlternateEmails( alternateSupplier( _user.alternate_emails ) )
            setOrigAlternate( alternateSupplier( _user.alternate_emails ) )

            // setOrigContact( _user?.contact || "" )
            // setContact( _user?.contact || "" )

            // setOrigContactURL( _user?.contact_url || "" )
            // setContactURL( _user?.contact_url || "" )            
            
            setOrigPhone( _user.phone || "" )
            setPhone( _user.phone || "" )

            setOrigContactURL( _user.contact_url || "" )
            setContactURL( _user.contact_url || "" )
        } )
        .catch( err => {
            // console.log( err )
            customToast( "Error fetching user." )
        } )
    }

    const [ isEditingAlternate, setIsEditingAlternate ] = useState( false );

    const handleEditingAlternate = val => setIsEditingAlternate( val )

    const submitHandler = e => {
        e.preventDefault()

        if ( isEditingAlternate ) {
            return
        }

        const data = {};

        if ( !email ) {
            customToast("Email is required.")
        }

        if ( !name ) {
            customToast("Name is required.")
        }

        if ( email !== origEmail ) data.email = email
        if ( name !== origName ) data.name = name

        if ( old_password || new_password ) {
            if ( !old_password ) {
                customToast("Old password is required for changing password.")
                return
            } else {
                setOldPass("")
                setNewPass("")
            }

            if ( old_password === new_password && old_password.length ) {
                customToast("Password must not match.")
                return
            }

            data.old_password = old_password
            data.new_password = new_password
        }

        const foundAlt = alternate_emails.find( _email => _email === email )

        if ( foundAlt ) {
            customToast("Main email cannot be passed to your alternate email.")
            return
        }

        let alternate_changes = false;

        if ( alternate_emails.length !== origAlternate.length ) alternate_changes = true
        
        if ( !alternate_changes ) {
            alternate_emails.forEach( _email => {
                let matched = false;

                origAlternate.forEach( _alt_sel_emails => {
                    if ( _alt_sel_emails === _email ) matched = true
                } )

                if ( !matched ) alternate_changes = true
            } )
        }

        if ( alternate_changes ) {
            if ( alternate_emails.length ) data.alternate_email = JSON.stringify( alternate_emails )
            else data.alternate_email = ""
        }

        if ( phone !== origPhone ) {
            data.phone = phone
        }

        if ( contact_url !== origContactURL ) {
            data.contact_url = contact_url
        }

        if ( !Object.keys( data ).length ) {
            customToast("No changes detected")
            return
        }

        const headers = getHeader()

        axios.put( ROOT_URL + '/seller/' + user.id, data, { headers } )
        .then( () => {
            customToast("Data updated successfully", "success")
            fetchUser()
        } )
        .catch( err => {
            ErrorHandler( err, customToast, err.response.data.message )
            fetchUser()
        } )
    }

    useEffect( () => {
        fetchUser()
    }, [] )

    return (
        <SectionWrapper sectionClass={ "profile" }>
            <h1>My Profile</h1>
            <form onSubmit={ submitHandler }>
                <ul>
                    <li>
                        <label htmlFor="name"> Name: </label>
                        <TextField 
                            id="name" 
                            type={ "text" } 
                            variant="standard"
                            sx={ { width: "25rem" } }
                            value={ name }
                            onChange={ e => setName( e.target.value ) } />
                    </li>
                    <li>
                        <label htmlFor="email"> My Email: </label>
                        <TextField 
                            id="email" 
                            type={ "email" } 
                            variant="standard"
                            sx={ { width: "25rem" } }
                            value={ email }
                            onChange={ e => setEmail( e.target.value ) } />
                    </li>
                    {   
                        user?.role === 3 &&
                        <>
                            <li>
                                <label htmlFor="alternate_emails"> Alternate Emails: </label>
                                <TextArray 
                                    id="alternate_emails" 
                                    boxStyle="standard"
                                    arrayValue={ alternate_emails }
                                    handleAdd={ addAlternate }
                                    handleDelete={ deleteAlternate }
                                    handleError={ customToast }
                                    isEditing={ isEditingAlternate }
                                    handleIsEditing={ handleEditingAlternate }
                                    sx={ { width: "25rem" } } />
                            </li>
                            <li>
                                <label htmlFor="phone"> Mobile Number: </label>
                                <TextField 
                                    id="phone" 
                                    type={ "text" } 
                                    variant="standard"
                                    sx={ { width: "25rem" } }
                                    value={ phone }
                                    onChange={ e => setPhone( e.target.value ) } />
                            </li>
                            <li>
                                <label htmlFor="contact_url"> Contact URL: </label>
                                <TextField 
                                    id="contact_url" 
                                    type={ "text" } 
                                    variant="standard"
                                    sx={ { width: "25rem" } }
                                    value={ contact_url }
                                    onChange={ e => setContactURL( e.target.value ) } />
                            </li>
                        </>
                    }
                    <li>
                        <label htmlFor="old_password"> Old Password: </label>
                        <TextField 
                            id="old_password" 
                            type={ showPasswordOld ? "text" : "password" } 
                            variant="standard"
                            sx={ { width: "25rem" } }
                            value={ old_password }
                            onChange={ e => setOldPass( e.target.value ) }
                            InputProps={ {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={ () => setShowOld( !showPasswordOld ) }>
                                            { showPasswordOld ? <Visibility /> : <VisibilityOff /> }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            } } />
                    </li>
                    <li>
                        <label htmlFor="new_password"> New Password: </label>
                        <TextField 
                            id="new_password" 
                            type={ showPasswordNew ? "text" : "password" } 
                            variant="standard"
                            sx={ { width: "25rem" } }
                            value={ new_password }
                            onChange={ e => setNewPass( e.target.value ) } 
                            InputProps={ {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={ () => setShowNew( !showPasswordNew ) }>
                                            { showPasswordNew ? <Visibility /> : <VisibilityOff /> }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            } } />
                    </li>
                </ul>
                <Button variant="contained" type="submit" sx={ { width: "10rem" } }>
                    Save
                </Button>
            </form>
        </SectionWrapper>
    )
}

export default Profile