/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/userSlice";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// import { useMemo } from 'react';

const HeaderManagementSystem = ({ roleClass }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.value);

  // const location = useLocation()

  // const pathname = useMemo( () => {
  //     return location.pathname
  // }, [ location ] )

  const [navOpen, setNavOpen] = useState(false);

  const viewWidth = window.innerWidth;

  useEffect(() => {
    if (viewWidth > 1024 && navOpen) {
      setNavOpen(false);
    }
  }, [viewWidth, navOpen]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (navOpen) {
      if (viewWidth < 1025) body.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "visible";
    }
  }, [navOpen]);

  const closeNav = () => {
    if (navOpen) setNavOpen(false);
  };

  return (
    <>
      <div className="icon fd">
        <img src="favicon.png" alt="site icon" className="site-icon" />
        <h1>OMS</h1>
        <p>by grocket</p>
      </div>
      <nav className={`fd ${roleClass} ${navOpen ? "-nav-open" : ""}`}>
        <Link onClick={closeNav} to={"/dashboard"} className="-nav-btns">
          {" "}
          <FontAwesomeIcon icon={solid("chart-simple")} />{" "}
          {user?.role === 3 ? "My Dashboard" : "Dashboard"}{" "}
        </Link>
        {user?.role === 1 && (
          <Link onClick={closeNav} to={"/reports"} className="-nav-btns">
            {" "}
            <FontAwesomeIcon icon={solid("file")} /> Reports{" "}
          </Link>
        )}
        <Link onClick={closeNav} to={"/archive"} className="-nav-btns">
          {" "}
          <FontAwesomeIcon icon={solid("trash")} /> Deleted Orders{" "}
        </Link>
        {(user?.role === 3 || user?.role === 2) && (
          <Link
            onClick={closeNav}
            to={"/task"}
            target="_blank"
            rel="noopener noreferrer"
            className="-nav-btns"
          >
            {" "}
            <FontAwesomeIcon icon={solid("clipboard")} /> My Tasks{" "}
          </Link>
        )}
        <Link onClick={closeNav} to={"/clients"} className="-nav-btns">
          {" "}
          <FontAwesomeIcon icon={solid("user-tag")} />
          Clients{" "}
        </Link>
        {user?.role !== 2 && (
          <Link onClick={closeNav} to={"/prospects"} className="-nav-btns">
            {" "}
            <FontAwesomeIcon icon={solid("magnifying-glass-location")} />
            My Prospects{" "}
          </Link>
        )}
        {user?.role === 1 && (
          <Link onClick={closeNav} to={"/users"} className="-nav-btns">
            {" "}
            <FontAwesomeIcon icon={solid("users-gear")} /> Users{" "}
          </Link>
        )}
        {user?.role === 1 && (
          <Link onClick={closeNav} to={"/brands"} className="-nav-btns">
            {" "}
            <FontAwesomeIcon icon={solid("building")} /> Brands{" "}
          </Link>
        )}
        <Link onClick={closeNav} to={"/profile"} className="-nav-btns">
          {" "}
          <FontAwesomeIcon icon={solid("user")} /> My Profile{" "}
        </Link>
        <button
          className="logout-btn -nav-btns"
          onClick={() => {
            closeNav();
            dispatch(logout());
          }}
        >
          <FontAwesomeIcon icon={solid("sign-out")} />
          Logout
        </button>
      </nav>
      <div
        className={`burger ${roleClass} ${navOpen ? "-nav-open" : ""}`}
        onClick={() => setNavOpen(!navOpen)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
};

export default HeaderManagementSystem;
