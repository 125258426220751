/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMessages } from "../../store/messagesSlice";
import HeaderLogin from './HeaderLogin';
import HeaderManagementSystem from './HeaderManagementSystem';
import './Header.scss';
import HeaderResetStep1 from './HeaderResetStep1';
import HeaderResetStep2 from './HeaderResetStep2';
import MessageNavigate from './MessageNavigate';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const user = useSelector( state => state.user.value )

    const [ currentPath, setCurrentPath ] = useState( "" )

    useEffect( () => {
        if ( location.pathname === "/" || location.pathname.includes("reset_password") ) { 
            // I can do the checking here
            if ( user?.id ) navigate( '/dashboard' )

            if ( currentPath !== "landpage" ) setCurrentPath( "landpage" )
        } else {
            if ( !user?.id ) navigate( '/' )

            if ( currentPath !== "default" ) setCurrentPath( "default" )
        }
    }, [ location, user ] )

    const roleClass = useMemo( () => {
        if ( user?.role === 3 ) return "-seller"
        if ( user?.role === 2 ) return "-accountant"
        return ""
    }, [ user ] )

    const receivedMessages = useSelector( state => state.messages.value.received );

    useEffect( () => {
        if ( user.id ) {
            if ( user.role === 3 ) {
                dispatch( fetchMessages() )
            }
        }
    }, [ user ] )

    const messageNavigateLogic = useMemo( () => {
        if ( user?.role !== 3 ) return false

        if ( location?.pathname.includes("/message") ) return false

        return true
    }, [ user, location ] )

    return (
        <header className={`fd ${ currentPath } ${ roleClass }`}>
            {
                <Routes>
                    <Route path="/" element={ <HeaderLogin /> }></Route>
                    <Route path="/reset_password/step1" element={ <HeaderResetStep1 /> }></Route>
                    <Route path="/reset_password/step2" element={ <HeaderResetStep2 /> }></Route>
                    <Route path="*" element={ <HeaderManagementSystem roleClass={ roleClass } /> }></Route>
                </Routes>
            }
            { messageNavigateLogic && <MessageNavigate messages={ receivedMessages } /> }
        </header>
    )
}

export default Header