import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppsIcon from "@mui/icons-material/Apps";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  useDeleteProspect,
  useGetSelectedProspect,
  useUpdateProspect,
} from "../../../useHooks/useProspects";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import useInputSearch from "../../../useHooks/useInputSearch";
import useClients from "../../../useHooks/useClients";

const ProspectModalDetails = ({ prospectId = "", handleClose, role }) => {
  const {
    data: selectedProspect,
    isFetching: isFetchingProspect,
    isError,
  } = useGetSelectedProspect(prospectId);

  const { mutateAsync: updateProspect, isLoading: isSaving } =
    useUpdateProspect();

  const { mutateAsync: deleteProspect, isLoading: isDeleting } =
    useDeleteProspect();

  const { industries } = useClients();

  const { items: sellers, searchFunc: fetchSellers } =
    useInputSearch("/seller/search/");

  const [prospect, setProspect] = useState({
    id: "",
    business_name: "",
    url: "",
    website: "",
    phone: "",
    user_email: "",
    notes: "",
    user_id: undefined,
    client_industry_id: "",
    email: "",
  });

  useEffect(() => {
    if (selectedProspect) {
      setProspect({
        ...selectedProspect,
        client_industry_id: selectedProspect?.client_industry_id ?? "",
      });
    }
  }, [selectedProspect]);

  const disableSelectSeller = useMemo(() => {
    if (role === 3) return true;
    return false;
  }, [role]);

  useEffect(() => {
    if (prospect.user_email) fetchSellers(prospect.user_email);
  }, [prospect.user_email, fetchSellers]);

  useEffect(() => {
    if (prospect.user_email && sellers.length) {
      const foundSeller = sellers.find(
        (_seller) =>
          _seller?.email.toLowerCase() === prospect.user_email.toLowerCase()
      );
      if (foundSeller) {
        setProspect((prev) => ({
          ...prev,
          user_email: foundSeller.email,
        }));
      }
    }
  }, [prospect.user_email, sellers]);

  const handleChange = (field, value) => {
    setProspect((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleIndustrySelect = (id) => {
    const foundIndustry = industries?.find((_industry) => _industry.id === id);
    if (!foundIndustry) return;

    setProspect((prev) => ({
      ...prev,
      client_industry_id: foundIndustry.id,
    }));
  };

  const handleDelete = async () => {
    if (!window.confirm("Do you want to delete this prospect?")) return;
    const result = await deleteProspect(prospect.id);
    if (result.status === 200) handleClose();
  };

  const handleSave = async () => {
    const payload = {};

    for (const key in selectedProspect) {
      if (
        selectedProspect.hasOwnProperty(key) &&
        prospect.hasOwnProperty(key)
      ) {
        if (selectedProspect[key] !== prospect[key]) {
          payload[key] = prospect[key];
        }
      }
    }

    if (prospect.client_industry_id) {
      payload.client_industry_id = prospect.client_industry_id;
    }

    const result = await updateProspect({
      ...payload,
      id: prospect.id,
    });

    if (result.status === 200) handleClose();
  };

  // Close modal if fetch errors
  if (isError) handleClose();

  // Spinner when fetching
  if (isFetchingProspect) {
    return (
      <Box sx={{ padding: "2rem" }}>
        <FidgetSpinner fidgetStyle={{ width: "3rem", height: "3rem" }} />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
        <Box sx={{ display: "flex", gap: "2.25rem", alignItems: "baseline" }}>
          <FontAwesomeIcon
            icon={solid("location-dot")}
            color="#0094FF"
            size="2xl"
          />
          <TextField
            fullWidth
            variant="standard"
            label="Google Map URL"
            value={prospect.url}
            onChange={(e) => handleChange("url", e.target.value)}
          />
        </Box>

        <Box sx={{ display: "flex", gap: "2.25rem", alignItems: "baseline" }}>
          <FontAwesomeIcon
            icon={solid("building")}
            color="#0094FF"
            size="2xl"
          />
          <TextField
            fullWidth
            variant="standard"
            label="Business Name"
            value={prospect.business_name}
            onChange={(e) => handleChange("business_name", e.target.value)}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "1.5rem",
            alignItems: "center",
          }}
        >
          <AppsIcon
            sx={{
              ml: "-0.5rem",
              color: "#0094ff",
              width: "2.75rem",
              height: "2.75rem",
            }}
          />
          <FormControl variant="standard" fullWidth>
            <InputLabel id="client_industry" sx={{ p: 0 }}>
              Industry
            </InputLabel>
            <Select
              labelId="client_industry"
              variant="standard"
              value={prospect.client_industry_id}
              onChange={(e) => handleIndustrySelect(e.target.value)}
            >
              {industries.map((industry, i) => (
                <MenuItem key={i} value={industry.id}>
                  {" "}
                  {industry.name.toUpperCase()}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "2.25rem",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              gap: "2.25rem",
              alignItems: "baseline",
            }}
          >
            <FontAwesomeIcon
              icon={solid("up-right-from-square")}
              color="#0094FF"
              size="2xl"
            />
            <TextField
              fullWidth
              variant="standard"
              label="Website"
              value={prospect.website}
              onChange={(e) => handleChange("website", e.target.value)}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              gap: "2.25rem",
              alignItems: "baseline",
            }}
          >
            <FontAwesomeIcon icon={solid("phone")} color="#0094FF" size="2xl" />
            <TextField
              fullWidth
              variant="standard"
              label="Phone"
              value={prospect.phone ?? ""}
              onChange={(e) => handleChange("phone", e.target.value)}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "2.25rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "2rem",
              alignItems: "baseline",
            }}
          >
            <FontAwesomeIcon
              icon={solid("envelope")}
              color="#0094FF"
              size="2xl"
            />
            <TextField
              fullWidth
              multiline
              maxRows={4}
              variant="standard"
              label="Business Email"
              value={prospect?.email ?? ""}
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "1.5rem",
              alignItems: "baseline",
            }}
          >
            <FontAwesomeIcon
              icon={solid("user-tag")}
              color="#0094FF"
              size="2xl"
            />
            <Autocomplete
              fullWidth
              id="seller-email"
              options={sellers}
              getOptionLabel={(option) => option?.email || ""}
              freeSolo
              disabled={disableSelectSeller}
              disableClearable
              sx={{ alignSelf: "flex-start" }}
              value={prospect.user_email}
              onChange={(_, newValue) =>
                handleChange("user_email", newValue.email)
              }
              inputValue={prospect.user_email || ""}
              onInputChange={(_, newValue, reason) => {
                if (reason === "reset" && !newValue) return;
                handleChange("user_email", newValue);
              }}
              renderOption={(params, option) => (
                <li {...params} key={option.id}>
                  {option.email}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assigned Seller"
                  variant="standard"
                />
              )}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "2rem", alignItems: "baseline" }}>
          <FontAwesomeIcon icon={solid("pencil")} color="#0094FF" size="2xl" />
          <TextField
            fullWidth
            multiline
            maxRows={4}
            variant="standard"
            label="Remarks / Notes"
            value={prospect.notes ?? ""}
            onChange={(e) => handleChange("notes", e.target.value)}
          />
        </Box>
      </Box>

      <Box
        sx={{
          mt: "4rem",
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Button
          variant="contained"
          type="button"
          className="-gray"
          onClick={handleClose}
          sx={{
            width: { xs: "100%", md: "unset" },
            minWidth: "8rem",
            p: "0.5rem 1.5rem",
          }}
        >
          Cancel
        </Button>

        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            variant="contained"
            type="button"
            className="-red"
            onClick={handleDelete}
            disabled={isDeleting}
            sx={{
              width: { xs: "100%", md: "unset" },
              minWidth: "8rem",
              p: "0.5rem 1.5rem",
            }}
          >
            {isDeleting ? (
              <FidgetSpinner fidgetStyle={{ width: "1rem", height: "1rem" }} />
            ) : (
              "Delete"
            )}
          </Button>
          <Button
            variant="contained"
            type="button"
            disabled={isSaving}
            onClick={handleSave}
            sx={{
              width: { xs: "100%", md: "unset" },
              minWidth: "8rem",
              p: "0.5rem 1.5rem",
            }}
          >
            {isSaving ? (
              <FidgetSpinner fidgetStyle={{ width: "1rem", height: "1rem" }} />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ProspectModalDetails;
