/* eslint-disable react-hooks/exhaustive-deps */
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableRow,
  TablePagination,
  Button,
  Input,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import "./CompanyLinksTable.scss";

const TABLE_HEAD = ["ID", "LINK NAME", "URL", "Valid URL", "Action"];

const KEYS = ["id", "name", "url", "valid_url"];

const CompanyLinksTable = ({
  existing = false,
  company_links = [],
  updateLinksSubmit = () => "",
  removeLinksSubmit = () => "",
  tableSpinner = false,
}) => {
  const [current_page, setCurrentPage] = useState(0);

  const [dialogDelete, setDialogDelete] = useState({
    open: false,
    id: null,
  });

  const [deleteSpinner, setDeleteSpinner] = useState(false);

  const removeLink = async () => {
    setDeleteSpinner(true);

    if (dialogDelete.id) await removeLinksSubmit(dialogDelete.id);

    setDeleteSpinner(false);

    setDialogDelete({
      open: false,
      id: null,
    });
  };

  const [clientsInput, setClientsInput] = useState([]);

  useEffect(() => {
    if (company_links.length < 1 && clientsInput.length < 1) return;
    const _clientsInput = company_links.map(
      ({ id, name, url, valid_url, check_url }) => {
        return {
          id,
          name,
          url,
          valid_url,
          check_url,
          hasUpdate: false,
        };
      }
    );
    setClientsInput(_clientsInput);
  }, [company_links]);

  const tableInput = useCallback(
    (id, val, key) => {
      const updatedClientInput = clientsInput.map((_clientInput) => {
        if (id === _clientInput.id) {
          return {
            ..._clientInput,
            [key]: val,
            hasUpdate: true,
          };
        }
        return _clientInput;
      });

      setClientsInput(updatedClientInput);
    },
    [clientsInput, setClientsInput]
  );

  const linkCleaner = useCallback((link) => {
    if (!link.includes("https://")) return "https://" + link;

    return link;
  }, []);

  const cellSupplier = useCallback(
    (link, linkI, key, keyI) => {
      if ((key === "id" || key === "valid_url") && !existing) return "";

      if (key === "name" || key === "url")
        return (
          <TableCell key={keyI}>
            <Input
              value={clientsInput?.[linkI]?.[key] || ""}
              onChange={(e) => tableInput(link?.id, e.target.value, key)}
              variant="standard"
            />
          </TableCell>
        );

      if (key === "valid_url")
        return (
          <TableCell key={keyI}>
            {" "}
            {link?.["check_url"] === 0
              ? "Pending"
              : link?.[key] === 1
              ? "Valid"
              : "Invalid"}{" "}
          </TableCell>
        );

      return <TableCell key={keyI}> {link[key]} </TableCell>;
    },
    [existing, clientsInput]
  );

  const [updateSpinner, setUpdateSpinner] = useState(false);

  const updateHandler = async (link) => {
    const _old_link = company_links?.find(
      (_company_link) => _company_link.id === link.id
    );

    if (!_old_link) return;

    const data = {};
    const _fallbackKey = [];

    if (_old_link.name !== link.name) {
      data.name = link.name;
      _fallbackKey.push({ key: "name", val: _old_link.name });
    }
    if (_old_link.url !== link.url) {
      data.url = link.url;
      _fallbackKey.push({ key: "url", val: _old_link.url });
    }

    setUpdateSpinner(true);

    const status = await updateLinksSubmit(link.id, data);

    setUpdateSpinner(false);

    // reset the data if error
    if (!status.ok)
      _fallbackKey.forEach(({ key, val }) => tableInput(link.id, val, key));

    // remove check if updated or error
    tableInput(link.id, false, "hasUpdate");
  };

  const resetHandler = (link) => {
    const _old_link = company_links?.find(
      (_company_link) => _company_link.id === link.id
    );

    if (!_old_link) return;

    const updatedClientInput = clientsInput.map((_clientInput) => {
      if (_old_link.id === _clientInput.id) {
        return {
          ..._clientInput,
          name: _old_link.name,
          url: _old_link.url,
          hasUpdate: false,
        };
      }
      return _clientInput;
    });

    setClientsInput(updatedClientInput);
  };

  return (
    <>
      <Box sx={{ overflow: "auto" }}>
        <h4>CUSTOMER LINKS</h4>
        <TableContainer
          component={Paper}
          sx={{ display: "table", marginTop: "1rem" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((label, i) =>
                  !existing && (i === 0 || i === 3) ? (
                    ""
                  ) : (
                    <TableCell key={i} sx={{ whiteSpace: "nowrap" }}>
                      {label}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {clientsInput?.length > 0 &&
                clientsInput
                  .slice(current_page * 5, current_page * 5 + 5)
                  .map((link, linkI) => (
                    <TableRow key={linkI}>
                      {
                        // this just checks if we are displaying the id or not
                        // KEYS.map( ( key, keyI ) => ( !existing && keyI === 0 ? "" :
                        // <TableCell key={ keyI } >
                        //     { link?.[key] || "" }
                        // </TableCell> ) )
                        KEYS.map((key, keyI) =>
                          cellSupplier(link, linkI, key, keyI)
                        )
                      }
                      <TableCell>
                        <span className="row-action-btns">
                          <a
                            href={linkCleaner(link?.url)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon icon={solid("link")} />
                          </a>
                          <span
                            className="delete-btn"
                            onClick={() =>
                              setDialogDelete({
                                open: true,
                                id: link?.id,
                              })
                            }
                          >
                            <FontAwesomeIcon icon={solid("trash")} />
                          </span>
                          {link?.hasUpdate && (
                            <>
                              <span
                                className="update-btn"
                                onClick={() => updateHandler(link)}
                              >
                                {updateSpinner ? (
                                  <FidgetSpinner
                                    fidgetStyle={{
                                      height: "1rem",
                                      width: "1rem",
                                    }}
                                  />
                                ) : (
                                  <FontAwesomeIcon icon={solid("check")} />
                                )}
                              </span>
                              <span
                                className="delete-btn"
                                onClick={() => resetHandler(link)}
                              >
                                <FontAwesomeIcon icon={solid("x")} />
                              </span>
                            </>
                          )}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              {tableSpinner && (
                <TableRow>
                  <TableCell colSpan={"5"}>
                    <FidgetSpinner />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            rowsPerPage={5}
            count={company_links?.length || 0}
            page={current_page}
            onPageChange={(e, newPage) => setCurrentPage(newPage)}
          />
        </TableContainer>
      </Box>
      <Dialog open={dialogDelete?.open}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this link?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ marginRight: "1rem" }}
            className="-gray"
            disabled={deleteSpinner}
            onClick={() => setDialogDelete({ open: false, id: null })}
          >
            Cancel
          </Button>
          {deleteSpinner ? (
            <FidgetSpinner fidgetClass="-btn" style={{ width: "6.5rem" }} />
          ) : (
            <Button variant="contained" onClick={removeLink}>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompanyLinksTable;
