import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState, useMemo, useContext, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

// import { emailCheck } from '../../../shared/CustomFunc';
import { ToastDataContext } from "../../UnderRoot";
import useInputSearch from "../../../useHooks/useInputSearch";
import "./TaskModal.scss";
import axios from "axios";
import { getHeader } from "../../../shared/Authorization";

dayjs.extend(utc);

const ROOT_URL = process.env.REACT_APP_API_URL;

const isoDate = (date) => {
  const stringDate = dayjs.utc(date).format("YYYY-MM-DD");
  return `${stringDate} 00:00:00`;
};

const TaskModal = ({
  _order_id = "",
  _clientEmail = "",
  task = {},
  fetchTasks,
  open,
  handleClose,
  role,
}) => {
  const { customToast } = useContext(ToastDataContext);

  const [taskName, setTaskName] = useState("");
  const [description, setDescription] = useState("");
  const [remarks, setRemarks] = useState("");
  const [notes, setNotes] = useState("");

  const [order_id, setOrderId] = useState(_order_id);
  const [email, setEmail] = useState(_clientEmail);

  const today = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const [taskDate, setTaskDate] = useState(dayjs.utc(today));

  // Set default values when editing task
  useEffect(() => {
    if (!task?.id) {
      setTaskName("");
      setDescription("");
      setOrderId("");
      setEmail("");
      setRemarks("");
      setNotes("");
      setTaskDate(dayjs.utc(today));

      if (_order_id) {
        setOrderId(_order_id);
      }

      if (_clientEmail) {
        setEmail(_clientEmail);
      }

      return;
    }

    setTaskName(task?.title);
    setDescription(task?.description);
    setRemarks(task?.remarks);
    setNotes(
      task?.todo?.todo_notes?.notes ? task?.todo?.todo_notes?.notes : ""
    );

    setOrderId(task?.todo?.order_id ? task?.todo?.order_id?.toString() : "");

    const email =
      task?.todo?.client?.email || task?.todo?.order?.client?.email || "";

    setEmail(email);

    // Old tasks dont have this value, hence the if statement
    if (task?.task_date) {
      setTaskDate(dayjs.utc(task.task_date));
    }

    // eslint-disable-next-line
  }, [task, _order_id]);

  const clearForm = () => {
    setTaskName("");
    setDescription("");
    setOrderId("");
    setEmail("");
    setRemarks("");
    setNotes("");
    setTaskDate(dayjs.utc(today));
  };

  // Fetch orders
  const { items: orders, searchFunc: fetchOrders } = useInputSearch(
    `/order?filter=order_id&keyword=${order_id}`,
    "orders",
    "data"
  );

  useEffect(() => {
    if (order_id) fetchOrders(order_id);
    //eslint-disable-next-line
  }, [order_id]);

  // Fetch clients
  const { items: clients, searchFunc: fetchClients } =
    useInputSearch("/client/search/");

  useEffect(() => {
    if (email) fetchClients(email);
    //eslint-disable-next-line
  }, [email]);

  // Disable email / order input

  const disabledProps = useMemo(() => {
    if (role === 2) return { email: true, order: true };
    if (order_id) return { email: true };
    if (email && !order_id) return { order: true };

    return { email: false, order: false };
  }, [order_id, email, role]);

  // Submit

  const submitHandler = async (e) => {
    e.preventDefault();

    // Initialize payload
    let payload;

    if (role === 2) {
      payload = { notes };
    } else {
      payload = {
        title: taskName,
        description,
        remarks,
        task_date: isoDate(taskDate),
        notes,
      };

      if (email) {
        payload.client_email = email;
      }

      if (order_id) {
        payload.order_id = parseInt(order_id);
      }
    }

    // Post/put API calls
    const headers = getHeader();

    if (task?.id) {
      try {
        await axios.post(ROOT_URL + "/todo/" + task.id, payload, { headers });
        fetchTasks();
        handleClose();
      } catch (err) {
        customToast(err.response.data.message);
      }
    } else {
      try {
        await axios.post(ROOT_URL + "/todo", payload, { headers });
        fetchTasks();
        handleClose();
      } catch (err) {
        customToast(err.response.data.message);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        clearForm();
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <Box sx={{ p: "2rem" }}>
        <DialogTitle>Task Details</DialogTitle>
        <DialogContent>
          <form onSubmit={submitHandler}>
            <TextField
              fullWidth
              name="task_name"
              label="Task Name"
              margin="dense"
              variant="standard"
              type="string"
              value={taskName}
              disabled={role === 2}
              onChange={(e) => setTaskName(e.target.value)}
            />

            <TextField
              fullWidth
              name="description"
              label="Description"
              margin="dense"
              variant="standard"
              value={description}
              disabled={role === 2}
              onChange={(e) => setDescription(e.target.value)}
            />

            <Box sx={{ mt: "0.5rem", display: "flex", gap: "2rem" }}>
              <Autocomplete
                fullWidth
                id="order_id"
                options={orders}
                getOptionLabel={(option) => option?.id?.toString() || ""}
                freeSolo
                disabled={disabledProps?.order}
                disableClearable
                value={order_id.toString()}
                onChange={(e, newValue) => {
                  setOrderId(newValue.id.toString());
                  setEmail(newValue?.client?.email);
                }}
                inputValue={order_id.toString() || ""}
                onInputChange={(e, newValue, reason) => {
                  if (reason === "reset" && !newValue) return;
                  setOrderId(newValue);
                }}
                renderOption={(params, option) => (
                  <li {...params} key={option.id}>
                    {option.id}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Order ID" variant="standard" />
                )}
              />

              <Autocomplete
                fullWidth
                id="client-email"
                options={clients}
                getOptionLabel={(option) => option?.email || ""}
                freeSolo
                disabled={disabledProps?.email}
                disableClearable
                value={email}
                onChange={(e, newValue) => setEmail(newValue.email)}
                inputValue={email || ""}
                onInputChange={(e, newValue, reason) => {
                  if (reason === "reset" && !newValue) return;
                  setEmail(newValue);
                  setOrderId("");
                }}
                renderOption={(params, option) => (
                  <li {...params} key={option.id}>
                    {option.email}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client Email"
                    variant="standard"
                  />
                )}
              />
            </Box>

            <TextField
              fullWidth
              name="remarks"
              label="Remarks"
              margin="dense"
              variant="standard"
              type="string"
              value={remarks}
              disabled={role === 2}
              onChange={(e) => setRemarks(e.target.value)}
            />

            <DatePicker
              sx={{ mt: "1.5rem", maxWidth: "228px" }}
              className="datepicker"
              label="Task Date"
              value={taskDate}
              disabled={role === 2}
              onChange={(newValue) => setTaskDate(dayjs(newValue))}
              format="YYYY/MM/DD"
            />

            <TextField
              fullWidth
              name="personal_notes"
              label="Personal Note"
              margin="dense"
              variant="standard"
              type="string"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />

            <Box
              sx={{
                mt: "2rem",
                display: "flex",
                flexDirection: { xs: "column-reverse", sm: "row" },
                gap: "1rem",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                type="button"
                className="-gray"
                sx={{ p: "0.5rem 1.5rem" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ p: "0.5rem 1.5rem" }}
              >
                Submit Task
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default TaskModal;
