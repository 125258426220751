/* eslint-disable react-hooks/exhaustive-deps */

import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import { useEffect, useMemo } from "react";
import './TextEditor.scss';

// semi custom hook
// we'll set the editor on top level
// to make things easier

export const useNewEditor = ( content ="<p></p>", placeholder="" ) => {
    return useEditor( {
        extensions: [
            StarterKit,
            Placeholder.configure({
                placeholder
            })
        ],
        content
    } )
}
const TextEditor = ( { 
    editor=null,
    content=null,
    editable=true, 
    editorHeight=1, 
    hasMaxHeight=false,
    className=""
 } ) => {

    const localEditor = useEditor( {
      extensions: [
        StarterKit,
      ],
      content,
    } )

    useEffect( () => {
        if ( !localEditor ) return
        localEditor.setEditable( editable )
    }, [ localEditor, editable ] )

    useEffect( () => {
        if ( !localEditor ) return
        if ( content !== localEditor.getHTML() ) {
            localEditor.commands.setContent( content )
        }
    }, [ localEditor, content ] )

    const editorClassName = useMemo( () => {
        let _editorClassName = "text-editor";
        if ( editable ) _editorClassName+= " -editable";
        if ( editorHeight > 1 ) _editorClassName+= ` -rows-${ editorHeight }`
        if ( hasMaxHeight ) _editorClassName+= " -has-max"
        if ( className ) _editorClassName+= " " + className

        return _editorClassName
    }, [ editable, editorHeight, hasMaxHeight, className ] )

    return (
        <EditorContent 
            editor={ editor || localEditor }
            className={ editorClassName } />
    )
}

export default TextEditor;