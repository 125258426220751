import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import { useSendTemplateEmail } from "../../../useHooks/useClients";

const REVIEW_STATUS_DROPDOWN = [
  {
    value: 5,
    label: "Neu",
  },
  {
    value: 0,
    label: "Beauftragt",
  },
  {
    value: 1,
    label: "Weiterleitung",
  },
  {
    value: 2,
    label: "Widerspruch",
  },
  {
    value: 3,
    label: "Gescheitert",
  },
  {
    value: 4,
    label: "Gelöscht",
  },
];

const REVIEW_EMAIL_TEMPLATE = [
  {
    value: "beauftragt",
    label: "Beauftragt",
  },
  {
    value: "weiterleitung",
    label: "Weiterleitung",
  },
  {
    value: "gescheitert",
    label: "Gescheitert",
  },
];

const ModalReviewsTable = ({ reviews, order_id, handleUpdateReview }) => {
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [reviewTemplate, setReviewTemplate] = useState("beauftragt");

  const { mutateAsync: sendEmail, isLoading: isSendingEmail } =
    useSendTemplateEmail();

  const isOneReviewChecked = useMemo(() => {
    return checkBoxes.some((checkbox) => checkbox.checked);
  }, [checkBoxes]);

  const areAllReviewsChecked = useMemo(() => {
    return checkBoxes.every((checkbox) => checkbox.checked);
  }, [checkBoxes]);

  const handleCheckAllReviews = () => {
    if (!areAllReviewsChecked) {
      const newArray = checkBoxes.map((checkbox) => {
        return { ...checkbox, checked: true };
      });
      setCheckBoxes(newArray);
    } else {
      const newArray = checkBoxes.map((checkbox) => {
        return { ...checkbox, checked: false };
      });
      setCheckBoxes(newArray);
    }
  };

  const handleCheckReview = (id) => {
    setCheckBoxes((prevState) =>
      prevState.map((checkbox) =>
        checkbox.id === id
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      )
    );
  };

  const handleSendEmail = async () => {
    const formData = new FormData();
    const reviewIDs = checkBoxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.id);

    formData.append("reviews", JSON.stringify(reviewIDs));
    formData.append("template", reviewTemplate);

    sendEmail({ formData, order_id });
  };

  useEffect(() => {
    if (reviews && reviews.length > 0) {
      const initialBooleans = reviews
        .slice(currentPage * 5, currentPage * 5 + 5)
        .map((review) => {
          return { id: review.id, checked: false };
        });
      setCheckBoxes(initialBooleans);
    }
  }, [reviews, currentPage]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {order_id && (
              <>
                <TableCell>
                  <Checkbox
                    checked={areAllReviewsChecked}
                    onChange={handleCheckAllReviews}
                  />
                </TableCell>
                <TableCell sx={{ width: "5rem" }}>ID</TableCell>
              </>
            )}
            <TableCell sx={{ minWidth: "8rem" }}>NAME</TableCell>
            <TableCell sx={{ minWidth: "8rem" }}>REVIEW STATUS</TableCell>
            <TableCell sx={{ minWidth: "8rem" }}>ORIGIN</TableCell>
            <TableCell sx={{ width: "5rem" }}>ACTION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reviews.length === 0 && (
            <TableRow>
              <TableCell
                sx={{ textAlign: "center" }}
                colSpan={order_id ? 4 : 3}
              >
                No data found
                <br />
                Add a review to continue
              </TableCell>
            </TableRow>
          )}
          {reviews
            .slice(currentPage * 5, currentPage * 5 + 5)
            .map((review, i) => {
              return (
                <TableRow key={i}>
                  {order_id && (
                    <>
                      <TableCell>
                        <Checkbox
                          checked={checkBoxes[i]?.checked || false}
                          onChange={() => handleCheckReview(review?.id)}
                        />
                      </TableCell>
                      <TableCell>{review?.id}</TableCell>
                    </>
                  )}
                  <TableCell>{review?.name}</TableCell>
                  <TableCell>
                    <Select
                      id={`review_status_${i}`}
                      label="REVIEW STATUS"
                      sx={{ width: "max-content" }}
                      variant="standard"
                      value={review?.status}
                      onChange={(e) =>
                        handleUpdateReview(
                          { status: e.target.value },
                          review?.id
                        )
                      }
                    >
                      {REVIEW_STATUS_DROPDOWN.map((reviewStatus, reviewI) => (
                        <MenuItem key={reviewI} value={reviewStatus.value}>
                          {reviewStatus.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    {review?.google_review_id ? "SELECTED" : "MANUAL"}
                  </TableCell>
                  <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                    <FontAwesomeIcon
                      icon={solid("x")}
                      style={{ cursor: "pointer", color: "red" }}
                      onClick={() =>
                        handleUpdateReview({ delete: true }, review?.id)
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[]}
        rowsPerPage={5}
        count={reviews.length}
        page={currentPage}
        onPageChange={(e, newPage) => setCurrentPage(newPage)}
      />
      {isOneReviewChecked && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "2rem",
          }}
        >
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="review-template">
              Template
            </InputLabel>
            <Select
              label="Template"
              variant="standard"
              value={reviewTemplate}
              onChange={(e) => setReviewTemplate(e.target.value)}
              inputProps={{
                name: "template",
                id: "review-template",
              }}
            >
              {REVIEW_EMAIL_TEMPLATE.map((template, index) => (
                <MenuItem key={index} value={template.value}>
                  {template.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="button"
            variant="contained"
            sx={{ width: "10rem", whiteSpace: "nowrap" }}
            disabled={isSendingEmail}
            onClick={handleSendEmail}
          >
            {isSendingEmail ? (
              <FidgetSpinner fidgetStyle={{ width: "1rem", height: "1rem" }} />
            ) : (
              "Send Email"
            )}
          </Button>
        </div>
      )}
    </TableContainer>
  );
};

export default ModalReviewsTable;
