import { useContext } from "react";
import axios from "axios";
// import qs from 'qs';
import { getHeader } from "../shared/Authorization";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ErrorHandler } from "../shared/CustomFunc";
import { ToastDataContext } from "../component/UnderRoot";

const INIT_ORDERS = { data: [] };

const ROOT_URL = process.env.REACT_APP_API_URL;

const useOrders = (params) => {
  const { customToast } = useContext(ToastDataContext);

  const headers = getHeader();

  const { data, isFetching: ordersLoading } = useQuery({
    queryKey: ["orders", params],
    queryFn: async () => {
      const result = await axios.get(ROOT_URL + "/order", { headers, params });
      return result.data;
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
    initialData: { orders: INIT_ORDERS, statistics: {} },
    refetchOnReconnect: true,
    refetchInterval: (_, query) => (query.state === "error" ? 5000 : 0),
  });

  const addOrder = async (_data) => {
    return await axios
      .post(`${ROOT_URL}/order`, _data, { headers })
      .then((resp) => {
        return { ok: true, order: resp.data.order };
      })
      .catch((err) => {
        return { ok: false, err };
      });
  };

  const updateOrder = async (order_id, _data) => {
    return await axios
      .post(`${ROOT_URL}/order/${order_id}?_method=put`, _data, { headers })
      .then((resp) => {
        return { ok: true, order: resp.data.order };
      })
      .catch((err) => {
        return { ok: false, err };
      });
  };

  const deleteOrder = async (order_id) => {
    return await axios
      .delete(`${ROOT_URL}/order/${order_id}`, { headers })
      .then((resp) => {
        return { ok: true };
      })
      .catch((err) => {
        return { ok: false, err };
      });
  };

  const addReviews = async (order_id, _reviews, sendConfirmation) => {
    return await axios
      .post(
        ROOT_URL + "/reviews",
        {
          order_id,
          order_reviews: JSON.stringify(_reviews),
          send_confirmation: sendConfirmation ? 1 : 0,
        },
        { headers }
      )
      .then(() => {
        return { ok: true };
      })
      .catch((err) => {
        return { ok: false, err };
      });
  };

  return {
    ordersLoading,
    orders: data.orders,
    statistics: data.total?.[0],
    // fetchOrders,
    addOrder,
    deleteOrder,
    updateOrder,
    addReviews,
  };
};
export default useOrders;

export const useUpdatePaymentStatus = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    mutationFn: (arg) => {
      const url = ROOT_URL + `/order/payment_status/${arg.payload.id}`;
      return axios.put(
        url,
        { payment_status: arg.payload.payment_status },
        { headers: getHeader() }
      );
    },
    onMutate: async (newOrder) => {
      await queryClient.cancelQueries({
        queryKey: ["orders", newOrder.params],
      });

      const previousOrders = queryClient.getQueryData([
        "orders",
        newOrder.params,
      ]);

      queryClient.setQueryData(["orders", newOrder.params], (old) => {
        const newOrders = old.orders.data.map((oldOrder) => {
          return oldOrder.id === newOrder.payload.id
            ? { ...oldOrder, ...newOrder.payload }
            : oldOrder;
        });
        return { ...old, orders: { ...old.orders, data: newOrders } };
      });

      return { previousOrders };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(["orders"], context.previousOrders);
      customToast("Error updating order");
    },
    onSuccess: () => {
      customToast("Order updated", "success");
    },
  });
};

export const useGenerateInvoicePDF = (order_id) => {
  return useQuery({
    enabled: false,
    queryKey: ["invoicePDF"],
    queryFn: async () => {
      const response = await axios.get(
        ROOT_URL + `/order/generate-pdf/${order_id}`,
        {
          responseType: "blob",
          headers: getHeader(),
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadLink = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadLink;
      a.download = `order#_${order_id}_invoice.pdf`; // Set the desired file name
      document.body.appendChild(a);
      a.click();

      return true;
    },
  });
};
