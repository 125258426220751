import axios from "axios";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getHeader } from "../shared/Authorization";
import { ToastDataContext } from "../component/UnderRoot";
import { ErrorHandler } from "../shared/CustomFunc";

const ROOT_API_URL = process.env.REACT_APP_API_URL;
const PROSPECT_url = ROOT_API_URL + "/prospect";
const PROSPECT_EMAILS_URL = ROOT_API_URL + "/prospect-emails";

export const useGetProspects = () => {
  return useQuery({
    queryKey: ["prospects"],
    queryFn: async () => {
      const result = await axios.get(PROSPECT_url, {
        headers: getHeader(),
      });
      return result.data.prospects;
    },
    initialData: [],
    refetchOnReconnect: true,
  });
};

export const useGetSelectedProspect = (id) => {
  return useQuery({
    enabled: id ? true : false,
    queryKey: ["prospect", { id }],
    queryFn: async ({ signal }) => {
      const result = await axios.get(PROSPECT_url + `/${id}`, {
        headers: getHeader(),
        signal,
      });
      return result.data.prospect;
    },
    refetchOnReconnect: true,
    staleTime: Infinity,
  });
};

export const useAddProspect = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    // Payload = {
    //   url: string,
    //   seller_email?: string
    // }
    mutationFn: (payload) => {
      return axios.post(PROSPECT_url, payload, { headers: getHeader() });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["prospects"]);
      customToast("Successfully added prospect", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useUpdateProspect = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    // Payload = {
    //   id?: number,
    //   seller_email?: string,
    //   notes: string?,
    //   business_name?: string,
    //   website?: string,
    //   phone?: string
    // };
    mutationFn: (arg) => {
      const payload = { ...arg };
      delete payload.id;

      return axios.patch(PROSPECT_url + `/${arg.id}`, payload, {
        headers: getHeader(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["prospects"]);
      queryClient.invalidateQueries(["prospect"]);
      customToast("Successfully updated prospect", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useDeleteProspect = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    mutationFn: (id) => {
      return axios.delete(PROSPECT_url + `/${id}`, { headers: getHeader() });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["prospects"]);
      customToast("Successfully deleted prospect", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useGetEmailTemplates = () => {
  return useQuery({
    queryKey: ["email_templates"],
    queryFn: async () => {
      const result = await axios.get(PROSPECT_EMAILS_URL, {
        headers: getHeader(),
      });
      return result.data.email_templates;
    },
    refetchOnReconnect: true,
  });
};

export const useGetEmailTemplateById = (id) => {
  return useQuery({
    enabled: id ? true : false,
    queryKey: ["email_templates", id],
    queryFn: async ({ signal }) => {
      const result = await axios.get(PROSPECT_EMAILS_URL + `/${id}`, {
        headers: getHeader(),
        signal,
      });
      return result.data.email_template;
    },
  });
};

export const useAddEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    // FormData = {
    //   name: string,
    //   subject: string,
    //   content: string,
    //   header_image: string,
    // }
    mutationFn: (payload) => {
      return axios.post(PROSPECT_EMAILS_URL, payload, {
        headers: getHeader(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["email_templates"]);
      customToast("Successfully added email template", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useUpdateEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    // payload = { id: string, formData }
    // FormData = {
    //   name: string,
    //   subject: string,
    //   content: string,
    // }
    mutationFn: (payload) => {
      return axios.patch(
        PROSPECT_EMAILS_URL + `/${payload.id}`,
        payload.payload,
        {
          headers: getHeader(),
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["email_templates"]);
      customToast("Successfully updated email template", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useDeleteEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    mutationFn: (id) => {
      return axios.delete(PROSPECT_EMAILS_URL + `/${id}`, {
        headers: getHeader(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["email_templates"]);
      customToast("Successfully deleted email template", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useUpdateEmailImage = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    // payload = { id: string, formData }
    // FormData = {
    //   name: string,
    //   subject: string,
    //   content: string,
    // }
    mutationFn: (payload) => {
      return axios.post(
        PROSPECT_EMAILS_URL + `/upload/${payload.id}`,
        payload.payload,
        {
          headers: getHeader(),
        }
      );
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["email_templates"]);
      queryClient.invalidateQueries([
        "email_templates",
        response?.data?.email_template?.id,
      ]);
      customToast("Image updated", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useGetProspectEmailLogs = (prospectId) => {
  return useQuery({
    enabled: prospectId ? true : false,
    queryKey: ["prospect_email_logs", prospectId],
    queryFn: async ({ signal }) => {
      const result = await axios.get(PROSPECT_url + `/logs/${prospectId}`, {
        headers: getHeader(),
        signal,
      });
      return result.data.logs;
    },
  });
};

export const useSendProspectEmail = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    mutationFn: (payload) => {
      return axios.post(
        PROSPECT_url + `/send-email/${payload.prospectId}`,
        payload.payload,
        {
          headers: getHeader(),
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["prospect_email_logs"]);
      customToast("Successfully sent email to prospect", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};

export const useImportCSV = () => {
  const queryClient = useQueryClient();
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    mutationFn: (payload) => {
      return axios.post(PROSPECT_url + `/csv`, payload, {
        headers: getHeader(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["prospects"]);
      customToast("Successfully imported prospect data", "success");
    },
    onError: (err) => {
      ErrorHandler(
        err,
        customToast,
        `Something went wrong: ${err.response.data.message}`
      );
    },
  });
};
