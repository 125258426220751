import { TableHead, TableRow } from "@mui/material";
import { StyledTableCell } from "./CustomFunc";

export const TableHeader = ({ columnHead, addActions = false }) => {
  return (
    <TableHead>
      <TableRow sx={{ borderTop: "solid 1px rgba(224, 224, 224, 1)" }}>
        {columnHead?.map((column, index) => {
          return (
            <StyledTableCell
              key={column?.id ?? index}
              sx={{ whiteSpace: "nowrap" }}
            >
              {column.label}
            </StyledTableCell>
          );
        })}
        {addActions && (
          <StyledTableCell>
            <span style={{ marginLeft: "0.5rem" }}>Actions</span>
          </StyledTableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
