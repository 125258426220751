/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState, useMemo, useContext } from "react";
import DataCard from "../../../sharedComponent/DataCard";
import SectionWrapper from "../../../wrapper/SectionWrapper";
import { FunctionContext } from "../Main";
import { useSelector } from "react-redux";
import ManagementHeader from "./ManagementHeader";
import ManagementTable from "./ManagementTable";
import "./ManagementSystem.scss";
import ManagementApplicationSystem from "./ManagementApplicationSystem";
import useOrders from "../../../useHooks/useOrders";

// const ROOT_URL = process.env.REACT_APP_API_URL

const DataContext = createContext(null);

const ManagementSystem = () => {
  const [params, setParams] = useState({
    page: 1,
    rows_per_page: 10,
  });

  const user = useSelector((state) => state.user.value);
  // function context
  const { dateStringify, numberWithCommas } = useContext(FunctionContext);

  const { orders, ordersLoading, statistics } = useOrders(params);

  const [filterBy, setFilterBy] = useState("all");
  const [keyword, setKeyWord] = useState("");

  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");

  // const [ aggrevatedDatas, setAggrevatedDatas ] = useState( [] );

  const fetchTableData = async (init = false, page = 1, reset = false) => {
    if (init && orders?.length) return;

    const _params = { page, rows_per_page: 10 };

    if (!reset) {
      if (filterBy && (keyword || keyword === 0)) {
        _params.filter = filterBy;
        _params.keyword = keyword;
      } else {
        handleFilterBy("all");
        handleKeyWord("");
      }

      const convertedDates = dateSupplier();

      if (convertedDates.start_date)
        _params.date_from = convertedDates.start_date;
      if (convertedDates.end_date) _params.date_to = convertedDates.end_date;
    }

    setParams(_params);

    // const status = await fetchOrders( _params )

    // if ( status.ok ) {
    //     if ( user?.role === 3 ) aggrevatedDatasSupplier( status.resp_data?.payment_status?.[0] || {} )
    // } else {
    //     ErrorHandler( status.err, customToast, "Error fetching orders" )
    // }
  };

  const handleFilterReset = () => {
    handleFilterBy("all");
    handleKeyWord("");
    handleStartDate("");
    handleEndDate("");
    fetchTableData(false, 1, true);
  };

  const aggrevatedDatas = useMemo(() => {
    if (!statistics?.total || user.role !== 3) {
      return [];
    }

    const _aggrevatedDatas = [];

    _aggrevatedDatas.push({
      label: "TOTAL",
      value: `${Number(statistics?.total || 0).toFixed(2)}`,
      color: "#30A8FF",
      src: "/dataIcons/cart-plus-icon.png",
    });

    _aggrevatedDatas.push({
      label: "UNPAID INVOICES",
      value: `${Number(statistics?.unpaid_invoices || 0).toFixed(2)}`,
      color: "#E34040",
      src: "/dataIcons/invoice-icon.png",
    });

    _aggrevatedDatas.push({
      label: "PAID COMMISSIONS",
      value: `${Number(statistics?.paid_commission || 0).toFixed(2)}`,
      color: "#48D16F",
      src: "/dataIcons/checked-icon.png",
    });

    _aggrevatedDatas.push({
      label: "CURRENT COMMISSIONS",
      value: `${Number(statistics?.current_commission || 0).toFixed(2)}`,
      color: "#F46D0B",
      src: "/dataIcons/clipboard-icon.png",
    });

    return _aggrevatedDatas;
  }, [user.role, statistics]);

  // const aggrevatedDatas = useMemo(() => {
  //     if (user.role !== 3) {
  //         return [];
  //     }

  //     const _aggrevatedDatas = [];

  //     _aggrevatedDatas.push(
  //         {
  //             label: "NEW",
  //             value: `${ Number( paymentData?.new || 0 ).toFixed(0)  }`,
  //             color: "#30A8FF",
  //             src: "/dataIcons/cart-plus-icon.png"
  //         }
  //     )

  //     _aggrevatedDatas.push(
  //         {
  //             label: "SENT",
  //             value: `${ Number( paymentData?.sent || 0 ).toFixed(0) }`,
  //             color: "#F46D0B",
  //             src: "/dataIcons/invoice-icon.png"
  //         }
  //     )

  //     _aggrevatedDatas.push(
  //         {
  //             label: "PAYMENT REMINDER",
  //             value: `${ Number( paymentData?.payment_reminder_1 || 0 ) + Number( paymentData?.payment_reminder_2 || 0 ) }`,
  //             color: "#E34040",
  //             src: "/dataIcons/warning-icon.png"
  //         }
  //     )

  //     _aggrevatedDatas.push(
  //         {
  //             label: "PAID",
  //             value: `${ Number( paymentData?.paid || 0 ).toFixed(0) }`,
  //             color: "#48D16F",
  //             src: "/dataIcons/checked-icon.png"
  //         }
  //     )

  //     return _aggrevatedDatas;
  //     // setAggrevatedDatas( _aggrevatedDatas )
  // }, [paymentData, user.role]);

  const handleFilterBy = (value) => {
    setKeyWord("");
    setFilterBy(value);
  };

  const handleKeyWord = (value) => setKeyWord(value);

  const startDateMax = useMemo(() => {
    if (!end_date) return "";

    const dateDT = new Date(end_date);

    dateDT.setDate(dateDT.getDate() - 1);

    return dateStringify(dateDT);
  }, [end_date]);

  const handleStartDate = (value) => {
    if (startDateMax) {
      const startDateMaxDT = new Date(startDateMax);
      const newValueDT = new Date(value);

      if (newValueDT.getTime() > startDateMaxDT.getTime()) return;
    }

    console.log("triggered");

    setStartDate(value);
  };

  const handleEndDate = (value) => {
    // const newValueDT = new Date(value);

    // if (start_date) {
    //   const startDateDT = new Date();

    //   if (startDateDT.getTime() >= newValueDT.getTime()) {
    //     startDateDT.setDate(newValueDT.getDate() - 1);

    //     setStartDate(dateStringify(startDateDT));
    //   }
    // }

    setEndDate(value);
  };

  const dateSupplier = () => {
    const returnDates = {};

    if (start_date) {
      const startDateDT = new Date(start_date);

      startDateDT.setHours(0, 0, 0, 0);

      returnDates.start_date = startDateDT.toISOString();
    } else returnDates.start_date = "";

    if (end_date) {
      const endDateDT = new Date(end_date);

      endDateDT.setHours(23, 59, 59, 999);

      returnDates.end_date = endDateDT.toISOString();
    } else returnDates.end_date = "";

    return returnDates;
  };

  useEffect(() => {
    fetchTableData(true);
  }, [user]);

  return (
    <SectionWrapper sectionClass="management-system">
      <DataContext.Provider
        value={{
          orders,
          handleFilterReset,
          fetchTableData,
          filterBy,
          handleFilterBy,
          keyword,
          handleKeyWord,
          start_date,
          startDateMax,
          handleStartDate,
          end_date,
          handleEndDate,
        }}
      >
        <ManagementHeader />
        {aggrevatedDatas.length > 0 && (
          <div className="data-card-container -management-system margin--top-standard">
            <ul className="fd">
              {aggrevatedDatas.map((data, i) => (
                <li key={i}>
                  <DataCard
                    label={data.label}
                    color={data.color}
                    value={data.value}
                    src={data.src}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        <ManagementTable ordersLoading={ordersLoading} params={params} />
        {
          <div className={`base-control-management-system fd`}>
            {statistics?.total && (
              <p className="total fd">
                <span>TOTAL:</span>
                <span className="value">
                  ${numberWithCommas(statistics?.total || 0)}
                </span>
              </p>
            )}
            {statistics?.current_commission && (
              <p className="total fd">
                <span>COMMISSIONS:</span>
                <span className="value">
                  ${numberWithCommas(statistics?.current_commission || 0)}
                </span>
              </p>
            )}
            {statistics?.paid_commission && (
              <p className="total fd">
                <span>PAID COMMISSIONS:</span>
                <span className="value">
                  ${numberWithCommas(statistics?.paid_commission || 0)}
                </span>
              </p>
            )}
          </div>
        }
        <ManagementApplicationSystem />
      </DataContext.Provider>
    </SectionWrapper>
  );
};

export default ManagementSystem;
export { DataContext };
