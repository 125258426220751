/* eslint-disable react-hooks/exhaustive-deps */
import { 
    Button, 
    TextField, 
    FormControl,
    InputLabel,
    Select,
    MenuItem, 
    Modal, 
    Box 
} from "@mui/material"
import { useContext, useEffect, useState } from "react";
import ManagementOrderModal from "./ManagementOrderModal";
import { DataContext } from "./ManagementSystem";
import './ManagementHeader.scss';
import { FunctionContext } from "../Main";

// const reviewStatusDropdown = [
//     { 
//         value: 0, 
//         label: "Beauftragt" 
//     }, { 
//         value: 1, 
//         label: "Weiterleitung" 
//     }, { 
//         value: 2, 
//         label: "Widerspruch" 
//     }, { 
//         value: 3, 
//         label: "Gescheitert" 
//     }, {
//         value: 4,
//         label: "Gelöscht"
//     }
// ]

const REVIEW_STATUS_DROPDOWN = [
    {
        value: 5,
        label: "Neu"
    }, { 
        value: 0, 
        label: "Beauftragt" 
    }, { 
        value: 1, 
        label: "Weiterleitung" 
    }, { 
        value: 2, 
        label: "Widerspruch" 
    }, { 
        value: 3, 
        label: "Gescheitert" 
    }, {
        value: 4,
        label: "Gelöscht"
    }
]

const PAYMENT_STATUS_DROPDOWN = [
    { 
        value: 0,
        label: "New"
    }, { 
        value: 1,
        label: "Sent Invoice"
    }, { 
        value: 2,
        label: "Paid"
    }, { 
        value: 3,
        label: "Unpaid"
    }, {
        value: 11,
        label: "Payment Reminder 1"
    }, { 
        value: 12,
        label: "Payment Reminder 2"
    }
]

const FILTER_BY_OPTION = [
    {
        value: "all",
        label: "SEARCH",  
    },
    {   
        value: "order_id",
        label: "ORDER ID"
    },
    {   
        value: "company",
        label: "COMPANY"
    },
    {   
        value: "payment_status",
        label: "PAYMENT STATUS"
    },
    {
        value: "has_review_status",
        label: "CONTAINS REVIEW STATUS"
    },
    {
        value: "reviewer_name",
        label: "CONTAINS REVIEWER NAME"
    },
    {
        value: "client",
        label: "CLIENT"
    },
    {
        value: "seller",
        label: "SELLER"
    },{
        value: "remarks",
        label: "REMARKS"
    },
]

const INPUT_LABEL_PROPS = {
  shrink: true,
}

const ManagementHeader = ( ) => {
    const {
        handleFilterReset,
        filterBy, 
        handleFilterBy, 
        keyword, 
        handleKeyWord,
        start_date,
        startDateMax,
        handleStartDate,
        end_date,
        handleEndDate,
        fetchTableData
    } = useContext( DataContext );

    const { modalOpen } = useContext( FunctionContext );

    const [ addOrderOpen, setAddOrderOpen ] = useState( false )

    const handleAddOrderOpen = () => setAddOrderOpen( true )
    const handleAddOrderClose = () => setAddOrderOpen( false )

    const formSubmit = e => {
        e.preventDefault()

        fetchTableData()
    }

    useEffect( () => modalOpen( addOrderOpen ), [ addOrderOpen ] )

    const [ paramsClient, setParamsClient ] = useState( {} );

    useEffect( () => {
        const dashboardParams = new URLSearchParams(window.location.search);

        if (!dashboardParams.has("clientId")) return;

        const client_id = dashboardParams.get('clientId');
        const client_email = dashboardParams.get('clientEmail');
        const encodedClientName = dashboardParams.get('clientName');
        const client_name = decodeURIComponent(encodedClientName);
        const client_phone = dashboardParams.get('phone');
        const third_party_id = dashboardParams.get('3rdPartyId');
        const unit_cost = dashboardParams.get('unitCost');

        setParamsClient( { client_id, client_email, client_name, client_phone, third_party_id, unit_cost } );
        setAddOrderOpen( true );
    }, [])

    return (
        <div className="section-header margin--top-standard fd">
            <Button className="add-btn -big" type="button" variant="contained" onClick={ handleAddOrderOpen }>Add New Order</Button>
            <Modal 
                open={ addOrderOpen }
                onClose={ handleAddOrderClose }
                className="modal-parent -order -empty">
                    <Box className='modal-box'
                        sx={ { width: '950px' } }>
                        <ManagementOrderModal
                            open={ addOrderOpen } 
                            handleClose={ handleAddOrderClose }
                            fetchTableData={ fetchTableData }
                            paramsClient={paramsClient} />
                    </Box>
            </Modal>
            <form className="header-fields fd" onSubmit={ formSubmit }>
                <FormControl variant="standard">
                    <InputLabel id="filter_label">
                        FILTER
                    </InputLabel>
                    <Select
                        labelId='filter_label'
                        id="filter-by"
                        label="FILTER"
                        value={ filterBy }
                        sx={ { minWidth: "10rem" } }
                        onChange={ e => handleFilterBy( e.target.value ) } >
                            {
                                FILTER_BY_OPTION.map( ( filterOption, i ) =>
                                    <MenuItem
                                        key={ i }
                                        value={ filterOption.value } >
                                        { filterOption.label }
                                    </MenuItem>
                                )
                            }
                    </Select>
                </FormControl>
                {
                    filterBy === "has_review_status"  && 
                    <FormControl variant="standard">
                        <InputLabel id="review_status_label">
                            KEYWORD
                        </InputLabel>
                        <Select
                            labelId='review_status_label'
                            id="order_status"
                            label="KEYWORD"
                            value={ keyword }
                            sx={ { minWidth: "12rem" } }
                            onChange={ e => handleKeyWord( e.target.value ) } >
                                {
                                    REVIEW_STATUS_DROPDOWN.map( ( orderOption, i ) =>
                                        <MenuItem
                                            key={ i }
                                            value={ orderOption.value } >
                                            { orderOption.label }
                                        </MenuItem>
                                    )
                                }
                        </Select>
                    </FormControl>
                }
                {
                   filterBy === "payment_status" && 
                   <FormControl variant="standard">
                       <InputLabel id="payment_status_label">
                            KEYWORD
                       </InputLabel>
                       <Select
                           labelId='payment_status_label'
                           id="payment_status"
                           label="KEYWORD"
                           value={ keyword }
                           sx={ { minWidth: "12rem" } }
                           onChange={ e => handleKeyWord( e.target.value ) } >
                               {
                                   PAYMENT_STATUS_DROPDOWN.map( ( paymentOption, i ) =>
                                       <MenuItem
                                           key={ i }
                                           value={ paymentOption.value } >
                                           { paymentOption.label }
                                       </MenuItem>
                                   )
                               }
                       </Select>
                   </FormControl>
                }
                {
                    filterBy !== "has_review_status" && filterBy !== "payment_status" && 
                    <TextField 
                        id="keyword" 
                        label="KEYWORD"
                        value={ keyword }
                        onChange={ e => handleKeyWord( e.target.value ) }
                        variant="standard"  />
                }
                <div className="text-fields fd">
                    <TextField 
                        id="start_date" 
                        type="date" 
                        label="START DATE"
                        value={ start_date }
                        onChange={ e => handleStartDate( e.target.value ) }
                        variant="standard" 
                        InputLabelProps={ INPUT_LABEL_PROPS }
                        inputProps={ { max: startDateMax } } />
                    <TextField 
                        id="end_date" 
                        type="date" 
                        label="END DATE"
                        value={ end_date }
                        onChange={ e => handleEndDate( e.target.value ) }
                        variant="standard"
                        InputLabelProps={ INPUT_LABEL_PROPS }
                        InputProps={ { min: start_date } } />
                </div>
                <Button variant="contained" className="-gray" type="submit" >Update Data</Button>
                <Button variant="contained" className="-gray" onClick={ handleFilterReset }  >RESET</Button>
            </form>
        </div>
    )
}

export default ManagementHeader;