/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { ErrorHandler } from "../../../shared/CustomFunc";
import { getHeader } from "../../../shared/Authorization";
import { ToastDataContext } from "../../UnderRoot";
import ModalReviewsTable from "./ModalReviewsTable";
import ModalNegativeReviewsTable from "./ModalNegativeReviewsTable";
import { useGetLowestReviews } from "../../../useHooks/useClients";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";

const PAYMENT_STATUS_DROPDOWN = [
  {
    value: 0,
    label: "New",
  },
  {
    value: 1,
    label: "Sent Invoice",
  },
  {
    value: 2,
    label: "Paid",
  },
  {
    value: 3,
    label: "Unpaid",
  },
  {
    value: 11,
    label: "Payment Reminder 1",
  },
  {
    value: 12,
    label: "Payment Reminder 2",
  },
];

const ROOT_URL = process.env.REACT_APP_API_URL;

const ManagementOrderModalReviews = ({
  order_id = null,
  disabledProps = true,
  fetchReviews = null,
  reviews = [],
  setReviews = null,
  remarks = "",
  setRemarks = null,
  total_price = 0,
  unit_cost = null,
  setUnitCost = null,
  payment_status = 0,
  setPaymentStatus = null,
  setReviewIsChanged = null,
  sendConfirmation = false,
  setSendConfirmation = null,
  company_url,
}) => {
  const { customToast } = useContext(ToastDataContext);

  const [current_name, setCurrentName] = useState("");
  const [quantity, setQuantity] = useState("10");
  const [tab, setTab] = useState("select_from_reviews"); // select_from_reviews || 'manual'

  const { lowestReviews, getLowestReviews, isFetchingLowestReviews } =
    useGetLowestReviews();

  const handleGetLowestReviews = () => {
    const parsedQuantity = parseInt(quantity);
    if (isNaN(parsedQuantity)) return;

    getLowestReviews({ url: company_url, quantity: parsedQuantity });
  };

  const filteredLowReviews = useMemo(() => {
    const _filteredLowReviews = lowestReviews?.filter(
      (lowRev) =>
        !reviews.some(
          (rev) => lowRev?.google_review_id === rev?.google_review_id
        )
    );
    return _filteredLowReviews;
  }, [lowestReviews, reviews]);

  useEffect(() => {
    handleGetLowestReviews();
  }, []);

  useEffect(() => {
    setCurrentName("");
  }, []);

  const handleAddReview = (e, _name = null, google_review_id = null) => {
    e.preventDefault();

    if (!_name) {
      if (!current_name) {
        customToast("Name cannot be empty");
        return;
      }
    }

    if (!order_id) {
      // if there is no order_id, it means we are adding a new order
      // we are sending as batch of reviews
      const newReview = {
        id: reviews.length,
        name: _name ?? current_name,
        status: 5,
      };

      if (google_review_id) {
        newReview.google_review_id = google_review_id;
      }

      setReviews([...reviews, newReview]);

      setCurrentName("");
      return;
    }

    const headers = getHeader();

    const newReview = {
      name: _name ?? current_name,
      status: 5,
    };

    if (google_review_id) {
      newReview.google_review_id = google_review_id;
    }

    const order_reviews = [{ ...newReview }];

    axios
      .post(
        ROOT_URL + "/reviews",
        {
          order_id,
          order_reviews: JSON.stringify(order_reviews),
          send_confirmation: sendConfirmation ? 1 : 0,
        },
        { headers }
      )
      .then(() => {
        setCurrentName("");
        setReviewIsChanged(true);
        customToast("Successfully added review", "success");
        fetchReviews();
      })
      .catch((err) => {
        ErrorHandler(err, customToast, "Error adding review/s");
      });
  };

  const handleUpdateReview = (value, review_id) => {
    if (!order_id) {
      if ("delete" in value)
        setReviews(reviews.filter((review) => review.id !== review_id));
      else {
        const _reviews = [];

        reviews.forEach((review) => {
          if (review.id === review_id) _reviews.push({ ...review, ...value });
          else _reviews.push(review);
        });

        setReviews(_reviews);
      }
      return;
    }

    const headers = getHeader();

    axios
      .put(ROOT_URL + "/reviews/" + review_id, value, { headers })
      .then(() => {
        setReviewIsChanged(true);
        customToast("Successfully updated review", "success");
        fetchReviews();
      })
      .catch((err) => {
        ErrorHandler(err, customToast, "Error updating review");
      });
  };

  const confirmationEmailHandler = () => {
    setSendConfirmation(!sendConfirmation);
  };

  return (
    <>
      <ModalReviewsTable
        reviews={reviews}
        order_id={order_id}
        handleUpdateReview={(value, review_id) =>
          handleUpdateReview(value, review_id)
        }
      />

      <Box className="tab-box" sx={{ marginBottom: "2rem" }}>
        <Tabs
          className="order-modal-tabs"
          value={tab}
          onChange={(_, newValue) => setTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab
            label="Select From Reviews"
            value="select_from_reviews"
            sx={{ width: "100%", maxWidth: "50%" }}
          />
          <Tab
            label="Manual"
            value="manual"
            sx={{ width: "100%", maxWidth: "50%" }}
          />
        </Tabs>
      </Box>

      {tab === "manual" ? (
        <form
          className="add-review-form fd"
          onSubmit={handleAddReview}
          style={{ paddingBottom: "2rem" }}
        >
          <div className="inputs fd">
            <TextField
              variant="standard"
              label="NAME"
              value={current_name}
              onChange={(e) => setCurrentName(e.target.value)}
              fullWidth
            />
          </div>
          <Button type="submit" variant="contained" sx={{ width: "8rem;" }}>
            Add
          </Button>
        </form>
      ) : (
        <>
          <Box className="fd" sx={{ gap: "1rem" }}>
            <TextField
              fullWidth
              variant="standard"
              label="NO. OF REVIEWS"
              placeholder="Enter the number of reviews you want to load"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              InputProps={{ inputProps: { min: "0" } }}
              InputLabelProps={{ shrink: true }}
            />
            <Button
              variant="contained"
              className="-black"
              onClick={handleGetLowestReviews}
              disabled={isFetchingLowestReviews}
              sx={{ whiteSpace: "nowrap", minWidth: "10rem" }}
            >
              {isFetchingLowestReviews ? (
                <FidgetSpinner
                  fidgetStyle={{ width: "1rem", height: "1rem" }}
                />
              ) : (
                "Get Reviews"
              )}
            </Button>
          </Box>

          <Box className="fd" sx={{ p: "2rem 0" }}>
            <ModalNegativeReviewsTable
              lowestReviews={filteredLowReviews}
              addReview={handleAddReview}
              isFetching={isFetchingLowestReviews}
            />
          </Box>
        </>
      )}

      <div className="extra-inputs-price fd">
        <TextField
          label="TOTAL"
          type="number"
          disabled
          variant="standard"
          value={total_price || 0}
        />
        {unit_cost !== null && (
          <TextField
            label="UNIT PRICE"
            type="number"
            disabled={disabledProps}
            value={unit_cost || 0}
            onChange={(e) => setUnitCost(e.target.value)}
            variant="standard"
            required
          />
        )}
        {order_id && (
          <FormControl variant="standard">
            <InputLabel id="payment_status_label">PAYMENT STATUS</InputLabel>
            <Select
              labelId="payment_status_label"
              id="payment_status"
              disabled={disabledProps}
              label="PAYMENT STATUS"
              value={
                payment_status || payment_status === 0 ? payment_status : ""
              }
              onChange={(e) => setPaymentStatus(e.target.value)}
            >
              {PAYMENT_STATUS_DROPDOWN.map((payment_status_drop) => (
                <MenuItem
                  key={payment_status_drop.value}
                  value={payment_status_drop.value}
                >
                  {payment_status_drop.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <TextField
        id="remarks"
        className="outside-text"
        label="REMARKS"
        value={remarks || ""}
        onChange={(e) => setRemarks(e.target.value)}
        variant="standard"
        sx={{ mb: "1rem" }}
        fullWidth
        multiline
        maxRows={4}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={sendConfirmation}
              onChange={confirmationEmailHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Send Order Confirmation Email"
        />
      </FormGroup>
      <ul>
        <li className="fd"></li>
      </ul>
    </>
  );
};

export default ManagementOrderModalReviews;
