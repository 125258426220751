import { useContext, useState } from "react";
import axios from "axios";
import qs from "qs";
import { getHeader } from "../shared/Authorization";
import { dateCleaner } from "../shared/CustomFunc";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ToastDataContext } from "../component/UnderRoot";

const ROOT_URL = process.env.REACT_APP_API_URL;
const SCRAPER_URL = process.env.REACT_APP_SCRAPER_API_URL;

const useClients = () => {
  const [clientsLoading, setClientsLoading] = useState(false);
  const [clients, setClients] = useState();

  const fetchClients = async (keyword, clientLoggedIn = false) => {
    const headers = getHeader();

    const params = clientLoggedIn
      ? { ...keyword, clientLoggedIn }
      : { ...keyword };

    setClientsLoading(true);

    const status = await axios
      .get(ROOT_URL + "/client", { headers, params })
      .then((resp) => {
        const clients = resp.data?.clients;
        const formattedDateClients = clients?.map((client) => {
          const formattedDate = dateCleaner(client.created_at);
          return { ...client, created_at: formattedDate };
        });
        setClients(formattedDateClients || []);

        return { ok: true, clients: formattedDateClients || [] };
      })
      .catch((err) => {
        return { ok: false, err };
      });

    setClientsLoading(false);

    return status;
  };

  const addClientLinks = async (company_links, client_id) => {
    const headers = getHeader();

    const data = { client_id, company_links: JSON.stringify(company_links) };

    return await axios
      .post(ROOT_URL + "/company_link", data, { headers })
      .then(() => {
        return { ok: true };
      })
      .catch((err) => {
        return { ok: false, err };
      });
  };

  const updateClientLink = async (link_id, _data) => {
    const headers = getHeader();

    const qs = require("qs");

    const data = qs.stringify(_data);

    return await axios
      .put(ROOT_URL + "/company_link/" + link_id, data, { headers })
      .then(() => {
        return { ok: true };
      })
      .catch((err) => {
        return { ok: false, err };
      });
  };

  const removeClientLink = async (link_id) => {
    return await updateClientLink(link_id, { status: "delete" });
    // const headers = getHeader( )

    // const qs = require("qs")

    // const data = qs.stringify( { "status": "delete" } )

    // return await axios.put( ROOT_URL + '/company_link/' + link_id, data, { headers } )
    // .then( () => {
    //     return { ok: true }
    // } )
    // .catch( err => {
    //     return { ok: false, err }
    // } )
  };

  const addClient = async (new_client) => {
    const headers = getHeader();

    const formData = new FormData();

    // Object.keys(new_client).forEach(key => formData.append(key, new_client[key]));

    Object.keys(new_client).forEach((key) => {
      if (new_client[key] !== "") {
        formData.append(key, new_client[key]);
      }
    });

    return await axios
      .post(ROOT_URL + "/client", formData, { headers })
      .then((resp) => {
        const { client } = resp.data;

        return { ok: true, client };
      })
      .catch((err) => {
        return { ok: false, err };
      });
  };

  const updateClient = async (client_id, _data) => {
    const headers = getHeader();

    // const data = qs.stringify( _data )

    // Filter out keys with empty values from _data
    const filteredData = {};
    Object.entries(_data).forEach(([key, value]) => {
      if (value !== "") {
        filteredData[key] = value;
      }
    });

    const data = qs.stringify(filteredData);

    return await axios
      .put(ROOT_URL + "/client/update/" + client_id, data, { headers })
      .then((resp) => {
        const { client } = resp.data;
        return { ok: true, client };
      })
      .catch((err) => {
        return { ok: false, err };
      });
  };

  const getClientsStats = async () => {
    const headers = getHeader();

    return await axios
      .get(ROOT_URL + "/client/stats", { headers })
      .then((resp) => {
        const stats = resp.data;
        return { ok: true, stats: stats };
      })
      .catch((err) => {
        return { ok: false, err };
      });
  };

  const { data: industries } = useQuery({
    initialData: [],
    queryKey: ["industries"],
    queryFn: async () => {
      const headers = getHeader();
      const response = await axios.get(ROOT_URL + "/client/industries", {
        headers,
      });
      return response.data.industries;
    },
  });

  return {
    clients,
    clientsLoading,
    industries,
    addClientLinks,
    updateClientLink,
    removeClientLink,
    fetchClients,
    addClient,
    updateClient,
    getClientsStats,
  };
};

export const useFetchClients = (params) => {
  const { customToast } = useContext(ToastDataContext);

  return useQuery({
    queryKey: ["clients", { params }],
    queryFn: async () => {
      const headers = getHeader();
      const response = await axios.get(ROOT_URL + "/client", {
        headers,
        params,
      });
      const formattedDateClients = response.data?.clients?.map((client) => {
        const formattedDate = dateCleaner(client.created_at);
        return { ...client, created_at: formattedDate };
      });

      return formattedDateClients;
    },
    onError: (err) => {
      customToast(err.response?.data?.message);
    },
  });
};

export const useTransferClients = () => {
  const { customToast } = useContext(ToastDataContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => {
      const headers = getHeader();
      return axios.post(ROOT_URL + "/client/transfer", payload, {
        headers,
      });
    },
    onSuccess: () => {
      customToast("Successfully transferred clients", "success");
      queryClient.invalidateQueries(["clients"]);
    },
    onError: (err) => {
      customToast(err.response?.data?.message);
    },
  });
};

export const useSendTemplateEmail = () => {
  const { customToast } = useContext(ToastDataContext);

  return useMutation({
    mutationFn: (payload) => {
      const headers = getHeader();
      return axios.post(
        ROOT_URL + `/order/send-email/${payload.order_id}`,
        payload.formData,
        {
          headers,
        }
      );
    },
    onSuccess: () => {
      customToast("Email successfully sent", "success");
    },
    onError: (err) => {
      customToast(err.response?.data?.message);
    },
  });
};

export const useGetClientReviews = (order_id) => {
  const [reviews, setReviews] = useState([]);

  const { refetch, isFetching } = useQuery({
    enabled: order_id ? true : false,
    queryKey: ["reviews", order_id],
    queryFn: async () => {
      const response = await axios.get(ROOT_URL + "/reviews/" + order_id, {
        headers: getHeader(),
      });

      setReviews(response.data.reviews);
      return response.data.reviews;
    },
    initialData: [],
  });

  return {
    reviews,
    setReviews,
    isFetching,
    refetch,
  };
};

export const useGetGoogleRatings = () => {
  const [ratings, setRatings] = useState(null);
  const { customToast } = useContext(ToastDataContext);

  const { mutateAsync: getGoogleRatings, isLoading: isFetchingRatings } =
    useMutation({
      mutationFn: (payload) => {
        return axios.post(SCRAPER_URL + "/v2/fetch-review-stats", payload, {
          headers: getHeader(),
        });
      },
      onSuccess: ({ data }) => {
        const company = {
          name: data?.placeInfo?.title,
          address: data?.placeInfo?.address,
          rating: data?.placeInfo?.rating,
          reviews: data?.placeInfo?.reviews,
          five_star: data?.reviews[0],
          four_star: data?.reviews[1],
          three_star: data?.reviews[2],
          two_star: data?.reviews[3],
          one_star: data?.reviews[4],
        };
        setRatings(company);
      },
      onError: (err) => {
        setRatings(null);
        customToast(err.response?.data?.message);
      },
    });

  const clearRatings = () => {
    setRatings(null);
  };

  return {
    ratings,
    clearRatings,
    getGoogleRatings,
    isFetchingRatings,
  };
};

export const useGetLowestReviews = () => {
  const [lowestReviews, setLowestReviews] = useState([]);
  const { customToast } = useContext(ToastDataContext);

  const { mutateAsync: getLowestReviews, isLoading: isFetchingLowestReviews } =
    useMutation({
      mutationFn: async (payload) => {
        return await axios.post(SCRAPER_URL + "/fetch-reviewers", payload, {
          headers: getHeader(),
        });
      },
      onSuccess: (data) => {
        setLowestReviews(data?.data?.reviewers);
      },
      onError: (err) => {
        customToast(err.response?.data?.message);
      },
    });

  return {
    lowestReviews,
    setLowestReviews,
    getLowestReviews,
    isFetchingLowestReviews,
  };
};

export default useClients;
