/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import MessageInboxTable from "./MessageInboxTable";

const MessageSent = () => {
    const messages = useSelector( state => state.messages.value.sent )
    const user = useSelector( state => state.user.value )

    return (
        <MessageInboxTable messages={ messages } userId={ user?.id || 0 }/>
    )
}

export default MessageSent;