/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material"
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom"
import { dateCleaner } from "../../../shared/CustomFunc"
import TextEditor from "../../../sharedComponent/TextEditor";
import './MessageInboxTable.scss';

const TABLE_HEAD = [
    "Date",
    "Subject",
    "Message"
]

const KEYS = [
    "created_at",
    "subject",
    "message"
]

const MessageInboxTable = ( { messages=[], userId } ) => {
    const navigate = useNavigate()

    const [ current_page, setCurrentPage ] = useState(0)

    const cellGenerator = ( keyI, value ) => {
        if ( keyI === 0 ) return dateCleaner( value )
        // if ( keyI === 2 ) return <TextEditor content={ value } editable={ false } />
        if ( keyI === 2 ) {
            const valSplit = value.split("</p>")
            const valTrimmed = valSplit[0]
            .replaceAll("<br>", "")
            .replaceAll("</br>", "")
            
            return <TextEditor content={ valTrimmed + "...</p>" } editable={ false } />
        }

        return value
    }

    const goToRead = useCallback( ( message ) => {
        const path = '/message/read/' + ( message.sender_id === userId ? "self" : "other" ) + '/' 
        navigate( path + message.id )
    }, [ userId ] )

    const tableBodySeenClass = useCallback( ( message ) => {
        if ( message.recipient_id === userId ) {
            if ( message.seen === 0 ) return "-new-message"
        }

        return "-seen-message"
    }, [ userId ] )

    return (
        <TableContainer
            className='table-container' 
            component={ Paper }>
                <Table className="message-inbox-table">
                    <TableHead>
                        <TableRow>
                            { TABLE_HEAD.map( ( head, i ) => <TableCell key={ i }> { head } </TableCell> ) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            messages
                            .slice(current_page * 10,current_page * 10 + 10 )
                            .map( ( message, messageI ) => <TableRow key={ messageI } className={`-clickable ${ tableBodySeenClass( message ) }`}>
                                {
                                    KEYS.map( ( key, keyI ) => <TableCell  key={ keyI } onClick={ () => goToRead( message ) } > { cellGenerator( keyI, message[key] ) } </TableCell>  )
                                }
                            </TableRow> )
                        }
                </TableBody>
            </Table>
            <TablePagination
                    component="div"
                    rowsPerPageOptions={ [] }
                    rowsPerPage={ 10 }
                    count={ messages.length }
                    page={ current_page }
                    onPageChange={ ( e, newPage ) => setCurrentPage( newPage ) }
             />
        </TableContainer>
    )
}

export default MessageInboxTable;