export const getAuthorization = () => {
    const access = localStorage.getItem("grocket_access")

    return access
}

export const cleanAuthorization = () => {
    localStorage.removeItem("grocket_access")
}

export const checkAuthorization = () => {
    const access = localStorage.getItem("grocket_access")

    return access ? true : false
}

export const saveAuthorization = access => {
    if ( !access ) return
    
    localStorage.setItem("grocket_access", access)
}

export const getHeader = ( extraField={} ) => {
    const access = getAuthorization()

    return {
        'Accept': "application/json",
        'Authorization': `Bearer ${ access }`,
        ...extraField
    }
}