import { Box, Tab, Tabs } from "@mui/material"
import { useLocation, useNavigate } from 'react-router-dom';

const MessageNav = ( ) => {
    const { pathname } = useLocation()

    const navigate = useNavigate()

    return (
        <Box className="tabBox" sx={{ width: "100%", maxWidth: "33rem", borderBottom: 1, borderColor: '#C5C5C5', marginBottom: "2rem" }}>
            <Tabs value={ pathname || "" }>
                <Tab 
                    value="/message" 
                    label="My Inbox" 
                    onClick={ () => navigate("/message") } >
                </Tab>
                <Tab 
                    value="/message/sent"
                    label="Sent Messages" 
                    onClick={ () => navigate("/message/sent") } >
                </Tab>
            </Tabs>
        </Box>
    )
}

export default MessageNav;