/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import {
  // TextField,
  Button,
  // FormControl,
  // Select,
  // MenuItem,
  // InputLabel,
  // Autocomplete,
  Tabs,
  Tab,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import {
  useContext,
  // useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
// import debounce from 'lodash.debounce';
import "./ManagementOrderModal.scss";
import { getHeader } from "../../../shared/Authorization";
import { useSelector } from "react-redux";
import { emailCheck, ErrorHandler } from "../../../shared/CustomFunc";
import { Box } from "@mui/system";
import ManagementOrderModalInfo from "./ManagementOrderModalInfo";
import ManagementOrderModalReviews from "./ManagementOrderModalReviews";
import ManagementInvoice from "./ManagementInvoice";
import ManagementOrderModalLog from "./ManagementOrderModalLog";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import ManagementOrderModalClientLinks from "./ManagementOrderModalClientLinks";
import useInputSearch from "../../../useHooks/useInputSearch";
import useOrders from "../../../useHooks/useOrders";
import useClients, { useGetClientReviews } from "../../../useHooks/useClients";
import useSellers from "../../../useHooks/useSellers";
import ManagementOrderModalInfoAdd from "./ManagementOrderModalInfoAdd";
import ManagementOrderModalSeller from "./ManagementOrderModalSeller";
import ManagementOrderModalClient from "./ManagementOrderModalClient";
import { ToastDataContext } from "../../UnderRoot";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const ROOT_URL = process.env.REACT_APP_API_URL;

const ManagementOrderModal = ({
  handleClose,
  open,
  paramsClient = {},
  order = null,
  dateStringify = null,
  fetchTableData = null,
  whichTab = null,
  current_page = null,
}) => {
  const user = useSelector((state) => state.user.value);
  const queryClient = useQueryClient();

  const { customToast } = useContext(ToastDataContext);

  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [dialog, setDialog] = useState({ message: "", open: false, type: "" });

  const closeDialogHandler = () => {
    setDialog({
      open: false,
      message: "",
      type: "",
    });
  };
  // const [ errorToastMessage, setErrorMessage ] = useState( "" )
  // const [ errorTimeout, setErrorTimeout ] = useState( null )

  // useEffect( () => {
  //     if ( errorToastMessage ) {
  //         if ( errorTimeout ) clearTimeout( errorTimeout )

  //         const _timeout = setTimeout( () => {
  //             setErrorMessage( "" )
  //             setErrorTimeout( null )
  //         }, 2000 )

  //         setErrorTimeout( _timeout )
  //     }
  // }, [ errorToastMessage ] )

  const [date, setDate] = useState("");
  const [seller_id, setSellerId] = useState(null);
  const [seller_name, setSellerName] = useState("");

  const [seller_email, setSellerEmail] = useState("");
  const [seller_email_selected, setSellerEmailSelected] = useState("");

  useEffect(() => {
    if (seller_email_selected) setSellerEmail(seller_email_selected);
  }, [seller_email_selected]);

  const [client_id, setClientId] = useState(null);
  const [client_name, setClientName] = useState("");
  const [client_email, setClientEmail] = useState("");
  const [client_email_selected, setClientEmailSelected] = useState("");

  useEffect(() => {
    if (client_email_selected) setClientEmail(client_email_selected);
  }, [client_email_selected]);

  const [client_phone, setClientPhone] = useState("");
  const [third_party_id, setThirdPartyId] = useState("");

  // const [ company_id, setCompanyId ] = useState( false )
  const [company_name, setCompanyName] = useState("");
  // const [ company_name_selected, setCompanyNameSelected ] = useState("");

  // useEffect( () => {
  //     if ( company_name_selected ) setCompanyName( company_name_selected )
  // }, [ company_name_selected ] )

  const [company_url, setCompanyUrl] = useState("");
  const [unit_cost, setUnitCost] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [payment_status, setPaymentStatus] = useState("");

  const [industry_id, setClientIndustry] = useState("");
  const [brand_code, setClientBrand] = useState("");
  const [source_id, setClientSource] = useState(1);

  // const [ sellers, setSellers ] = useState( [] )

  const [invoice_image, setInvoiceImage] = useState("");

  const { items: sellers, searchFunc: fetchSellers } =
    useInputSearch("/seller/search/");

  // const fetchSellers = _seller_email => {
  //     // fetch seller
  //     if ( !_seller_email ) return

  //     const headers = getHeader()

  //     axios.get( ROOT_URL + `/seller/search/${ _seller_email }`, { headers } )
  //     .then( resp => {
  //         setSellers( resp.data )
  //     } )
  //     .catch( err => {
  //         // console.log( err )
  //         if ( sellers.length ) setSellers( [] )
  //     } )
  // }

  const [sellerInfo, setSellerInfo] = useState({ emails: [], name: "" });
  const [fetchedInfos, setFetchedInfos] = useState(false);

  const fetchAlternateEmails = () => {
    const headers = getHeader();

    axios.get(ROOT_URL + "/user", { headers }).then((resp) => {
      const loadedUser = resp.data.user;

      const _alternate_emails = loadedUser.alternate_emails.map(
        (_item) => _item.email
      );

      setSellerInfo({
        emails: [loadedUser.email, ..._alternate_emails],
        name: loadedUser.name,
      });
    });
  };

  useEffect(() => {
    if (sellerInfo.name) setFetchedInfos(true);
  }, [sellerInfo]);

  // const debouncedFetchSellers = useCallback( debounce( fetchSellers, 500 ), [ ] )

  // auto fill for seller
  useEffect(() => {
    if (seller_email && sellers.length) {
      const foundSeller = sellers.find(
        (_seller) => _seller?.email.toLowerCase() === seller_email.toLowerCase()
      );

      if (seller_name === foundSeller?.name) return;

      if (foundSeller) {
        setSellerEmailSelected(foundSeller.email);
        setSellerName(foundSeller.name);
      }
    }
  }, [seller_email, sellers]);

  // auto complete with fetch
  // seller
  useEffect(() => {
    if (!user?.id) return;

    // if ( user?.role !== 3 ) if ( seller_email ) debouncedFetchSellers( seller_email )

    if (user?.role !== 3) if (seller_email) fetchSellers(seller_email);
  }, [seller_email, user]);

  // CLIENTS ==============================================================================

  const { items: clients, searchFunc: fetchClients } =
    useInputSearch("/client/search/");

  // Autofill the client tab if there is client passed from params
  useEffect(() => {
    if (!paramsClient?.client_id) return;

    // setClientId(paramsClient?.client_id);
    setClientEmail(paramsClient?.client_email);
    setClientEmailSelected(paramsClient?.client_email);
  }, [paramsClient]);

  // auto fill for client
  useEffect(() => {
    if ((client_email && clients.length) || paramsClient?.client_email) {
      const validClientEmail = client_email || paramsClient?.client_email;
      const foundClient = clients.find(
        (_client) =>
          _client?.email.toLowerCase() === validClientEmail.toLowerCase()
      );
      if (client_name === foundClient?.name) return;
      if (foundClient) {
        setUnitCost(Number(foundClient?.default_unit_cost || 0));
        setClientId(foundClient.id);
        setClientEmail(foundClient?.email);
        setClientEmailSelected(foundClient?.email);
        setClientName(foundClient?.name || "");
        setClientPhone(foundClient?.phone || "");
        setThirdPartyId(foundClient?.third_party_id || "");
        setClientIndustry(foundClient?.industry_id || "");
        setClientSource(foundClient?.source_id || 1);
        setClientBrand(foundClient?.brand?.code || "");

        if (foundClient?.related_company.length > 0) {
          // const related_company = foundClient.related_company[0];
          // setCompanyName( related_company?.name || "" )
          // setCompanyUrl( related_company?.url || "" )
          // setCompanies( foundClient.related_company )
        }
      }
    }
  }, [client_email, clients]);

  // auto complete with fetch
  // client
  useEffect(() => {
    if (client_email) fetchClients(client_email);
  }, [client_email]);

  const [fileName, setFileName] = useState("");
  const [fileDownload, setFileDownload] = useState("");

  useEffect(() => {
    if (invoice_image) {
      if (invoice_image?.name) {
        setFileName(invoice_image.name);
        setFileDownload("");
      } else {
        setFileName(order?.invoice_image);
        setFileDownload(order?.invoice_image_path);
      }
    } else if (!invoice_image && (fileName || fileDownload)) {
      setFileName("");
      setFileDownload("");
    }
  }, [invoice_image]);

  const [reviewIsChanged, setReviewIsChanged] = useState(false);

  // const [reviews, setReviews] = useState([]);

  const [sendConfirmation, setSendConfirmation] = useState(false);

  const {
    reviews,
    setReviews,
    refetch: fetchReviews,
  } = useGetClientReviews(order?.id);

  const [total_price, setTotalPrice] = useState(0);

  useEffect(() => {
    if (!reviews?.length || !unit_cost) return;

    setTotalPrice((reviews?.length * Number(unit_cost)).toFixed(2));
  }, [order, reviews, unit_cost]);

  useEffect(() => {
    if (open) {
      if (order?.id) {
        // fill the data here
        const dt = new Date(order.created_at);

        // we are fetching reviews here
        fetchReviews();

        setDate(dateStringify(dt));

        setSellerName(order?.user?.name);
        setSellerEmailSelected(order?.seller_email || order?.user?.email);

        setClientId(order?.client?.id);
        setClientName(order?.client?.name);
        setClientEmail(order?.client?.email);
        setClientPhone(order?.client?.phone);
        setThirdPartyId(order?.client?.third_party_id);

        // setCompanyId( order?.company.id )
        setCompanyName(order?.company?.name);
        setCompanyUrl(order?.company?.url);

        setUnitCost(order?.unit_cost);
        setRemarks(order?.remarks);
        setPaymentStatus(Number(order?.payment_status));

        setClientIndustry(order?.client?.industry_id);
        setClientSource(order?.client?.source_id);

        if (order?.invoice_image)
          setInvoiceImage(order?.invoice_image_path || "");
      } else {
        setPaymentStatus(0);
      }
    }
  }, [open, order]);

  useEffect(() => {
    if (user?.role !== 3) return;

    if (open) {
      if (!fetchedInfos) {
        fetchAlternateEmails();
        return;
      }

      if (order?.id) return;

      if (seller_name || seller_email) return;

      setSellerName(sellerInfo?.name);
      setSellerEmailSelected(sellerInfo.emails[0]);
    }
  }, [user, open, fetchedInfos]);

  const dateGenerator = (date) => {
    if (!date) return "";

    const dateDT = new Date(date);

    return dateDT.toISOString();
  };

  const { addOrder, updateOrder, deleteOrder, addReviews } = useOrders();

  const submitHandler = async (e) => {
    if (e) e.preventDefault();

    // toast.success("yo", { onOpen: () => console.log("I'm open") })
    const formData = new FormData();
    const _order_date = dateGenerator(date);

    const fields = {
      seller_name,
      seller_email,
      client_name,
      client_email,
      company_name,
      company_url,
      source_id,
      unit_cost: unit_cost ? unit_cost : 0,
      payment_status,
      send_confirmation: sendConfirmation ? 1 : 0,
    };

    let foundEmpty = false;

    Object.keys(fields).forEach((field) => {
      if (!fields[field] && Number(fields[field]) !== 0) {
        foundEmpty = true;
      } else {
        formData.append(field, fields[field]);
      }
    });

    if (foundEmpty) {
      // window.alert( "Please fill required" )
      customToast("Please fill required");
      return;
    }

    if (invoice_image?.name) {
      // fields.invoice_image = invoice_image;
      formData.append("invoice_image", invoice_image);
    }
    if (third_party_id) {
      // fields.third_party_id = third_party_id;
      formData.append("third_party_id", third_party_id);
    }
    if (client_phone) {
      // fields.client_phone = client_phone;
      formData.append("client_phone", client_phone);
    }
    if (industry_id) {
      // fields.industry_id = industry_id;
      formData.append("industry_id", industry_id);
    }

    // fields.remarks = remarks || "";
    formData.append("remarks", remarks || "");

    if (order?.id) {
      //checks if we are deleting image or not
      if (!invoice_image) {
        // fields.image_delete = true;
        formData.append("image_delete", true);
      } else {
        // fields.image_delete = false;
        formData.append("image_delete", false);
      }

      if (!_order_date) {
        customToast("Order date is required");
        return;
      } else {
        // fields.order_date = _order_date;
        formData.append("order_date", _order_date);
      }

      setSubmitSpinner(true);
      const status = await updateOrder(order.id, formData);

      if (status.ok) {
        customToast("Order updated", "success");

        // if (fetchTableData) fetchTableData(false, current_page);
        queryClient.invalidateQueries(["orders"]);

        handleClose();
      } else {
        const { message } = ErrorHandler(
          status.err,
          customToast,
          "Error updating order"
        );
        // setErrorMessage( message )

        customToast(message);
      }

      setSubmitSpinner(false);
    } else {
      if (_order_date) {
        // fields.order_date = _order_date;
        formData.append("order_date", _order_date);
      }
      if (reviews.length) {
        // fields.number_of_reviews = reviews.length;
        formData.append("number_of_reviews", reviews.length);
      }

      setSubmitSpinner(true);

      const status = await addOrder(formData);

      if (status.ok) {
        customToast("Order added", "success");

        if (reviews.length) {
          await addReviews(status.order.id, reviews, sendConfirmation);
        }

        // fetchTableData();
        queryClient.invalidateQueries(["orders"]);

        handleClose();
      } else {
        const { message } = ErrorHandler(
          status.err,
          customToast,
          "Error adding order"
        );

        customToast(message);
      }

      setSubmitSpinner(false);
    }

    return;
  };

  const deleteClickHandler = () => {
    setDialog({
      open: true,
      message: "Are you sure you want to delete this order?",
      type: "confirm",
    });
  };

  const handleDeleteOrder = async () => {
    if (!order.id) return;
    const status = await deleteOrder(order.id);

    if (status.ok) {
      customToast("Order deleted!", "success");
      handleClose();
      // fetchTableData();
      queryClient.invalidateQueries(["orders"]);
    } else {
      const { message } = ErrorHandler(
        status.err,
        customToast,
        "Error deleting order."
      );

      customToast(message);
    }
  };

  const [localTab, setLocalTab] = useState("info");

  const disabledProps = useMemo(
    () => (user?.role === 3 && order?.id ? true : false),
    [user, order]
  );

  const submitText = useMemo(() => {
    if (order?.id) return "Update Order";

    if (localTab !== "reviews") return "Next";

    return "Add Order";
  }, [localTab, order]);

  const { addClient } = useClients();

  const { addSeller } = useSellers();

  const tabSellerValid = useMemo(() => {
    if (seller_email && seller_name) {
      if (emailCheck(seller_email)) return true;
    }

    return false;
  }, [seller_email, seller_name]);

  const tabClientValid = useMemo(() => {
    if (client_email && client_name) {
      if (emailCheck(client_email)) {
        return true;
      }
    }

    return false;
  }, [client_email, client_name, unit_cost]);

  const tabClientLinksPrerequisite = useMemo(() => {
    if (order?.id) return true;
    if (tabSellerValid && tabClientValid) return true;

    return false;
  }, [tabSellerValid, tabClientValid]);

  const tabReviewsPrerequisite = useMemo(() => {
    if (order?.id) return true;

    if (!tabClientLinksPrerequisite) return false;

    if (company_name && company_url) return true;

    return false;
  }, [company_name, company_url, tabClientLinksPrerequisite]);

  const infoSellerSubmit = async () => {
    let _seller = null;

    if (!seller_email) {
      customToast("Seller email is required");
      return { ok: false };
    }

    if (user?.role === 3) {
      _seller = { id: user?.id };
    } else {
      _seller = sellers.find(
        (__seller) =>
          __seller.email.toLowerCase() === seller_email.toLowerCase()
      );

      if (_seller?.name)
        if (_seller?.name !== seller_name) setSellerName(_seller.name);
    }

    if (!seller_name && !_seller?.name) {
      customToast("Seller name is required.");
      return { ok: false };
    }

    if (!_seller?.id) {
      if (!emailCheck(seller_email)) {
        customToast("Please enter a proper email for the seller");
        return { ok: false };
      }

      setSubmitSpinner(true);

      const _seller_status = await addSeller({
        name: seller_name,
        email: seller_email,
        password: `${seller_name}.grocket_seller`,
        password_confirmation: `${seller_name}.grocket_seller`,
      });

      setSubmitSpinner(false);

      if (!_seller_status.ok) {
        let message = "Error adding new seller";

        if (_seller_status.err.response.status === 422) {
          message = _seller_status.err.response.data.message;
        }

        customToast(message);
        return { ok: false };
      }
      fetchSellers(_seller_status.seller);
      _seller = _seller_status.seller;
      setSellerEmail(_seller_status.seller.email);
      setSellerName(_seller_status.seller.name);
    }

    if (client_email && _seller.id !== seller_id) {
      setClientId(null);
      setClientEmail("");
      setClientName("");
      setUnitCost(0);
      setClientPhone("");
      setClientIndustry("");
      setClientSource("");
      setThirdPartyId("");
      setCompanyName("");
      setCompanyUrl("");
    }

    setSellerId(_seller?.id || null);
    return { ok: true };
  };

  const infoClientSubmit = async () => {
    if (!client_email) {
      // setDialog( {
      //     open: true,
      //     message: "Client email is required",
      //     type: "error"
      // } )

      customToast("Client email is required");

      return { ok: false };
    }

    const _client = clients.find(
      (__client) => __client.email.toLowerCase() === client_email.toLowerCase()
    );

    if (!client_name && !_client?.name) {
      customToast("Client name is required");
      return { ok: false };
    }

    if (!_client?.email) {
      if (!emailCheck(client_email)) {
        customToast("Please enter a proper email for the client");
        return { ok: false };
      }

      setSubmitSpinner(true);

      const clientPayload = {
        name: client_name,
        email: client_email,
        default_unit_cost: unit_cost,
        seller_id: seller_id,
        phone: client_phone,
        brand_code,
      };

      if (industry_id) {
        clientPayload.industry_id = industry_id;
      }

      if (source_id) {
        clientPayload.source_id = source_id;
      }

      const _client_status = await addClient(clientPayload);

      setSubmitSpinner(false);

      if (!_client_status.ok) {
        let message = "Error adding new client";

        if (_client_status.err.response.status === 422) {
          message = _client_status.err.response.data.message;
        }
        // setDialog( {
        //     open: true,
        //     message,
        //     type: "error"
        // } )
        customToast(message);
        return { ok: false };
      } else {
        setClientId(_client_status.client.id);
        setClientEmail(_client_status.client.email);
        setClientName(_client_status.client.name);
        // setUnitCost( _client_status.client.default_unit_cost )
        setClientPhone(_client_status.client.phone);
        // setThirdPartyId( _client_status.client.third_party_id )
        setClientIndustry(_client_status?.client.industry_id || "");
        setClientSource(_client_status?.client.source_id || 1);

        setCompanyName("");
        setCompanyUrl("");

        fetchClients(_client_status.client.email);
      }
    } else {
      setClientId(_client.id);
      setClientEmail(_client.email);
      setClientName(_client.name);
      setClientIndustry(_client?.industry_id || "");
      setClientSource(_client?.source_id || 1);
      // setUnitCost( _client.default_unit_cost )
      // setClientPhone( _client.phone )
      // setThirdPartyId( _client.third_party_id )

      setCompanyName("");
      setCompanyUrl("");
    }

    return { ok: true };
  };

  const navClickedHandler = (newValue) => {
    if (!order?.id) {
      if (localTab === "info" && newValue === "info2") {
        submitBtnClicked();
        return;
      }
      if (localTab === "info2" && newValue === "links") {
        submitBtnClicked();
        return;
      }
      if (localTab === "links" && newValue === "reviews") {
        submitBtnClicked();
        return;
      }
    }
    setLocalTab(newValue);
  };

  const submitBtnClicked = async () => {
    if (order?.id) {
      submitHandler();
      return;
    }

    if (localTab === "info") {
      const _info_seller_status = await infoSellerSubmit();

      if (!_info_seller_status.ok) return;
    }

    if (localTab === "info2") {
      const _info_client_status = await infoClientSubmit();

      if (!_info_client_status.ok) return;
    }

    if (localTab === "info") {
      setLocalTab("info2");
      return;
    }

    if (localTab === "info2") {
      setLocalTab("links");
      return;
    }

    if (localTab === "links") {
      if (!company_url) {
        customToast("Please select a company first");

        return;
      }

      setLocalTab("reviews");
      return;
    }

    submitHandler();
  };

  useEffect(() => {
    console.log(`type: ${typeof localTab}, value: ${localTab}`);
  }, [localTab]);

  const prevHandler = () => {
    if (localTab === "reviews") setLocalTab("links");
    if (localTab === "links") setLocalTab("info2");
    if (localTab === "info2") setLocalTab("info");
  };

  const MuiTabsStyle = useMemo(() => {
    const maxWidth =
      (user?.role === 1 && order?.id) || !order?.id ? "25%" : "33.33%";

    return { width: "100%", maxWidth };
  }, []);

  const submitDisabled = useMemo(() => {
    if (order?.id) return false;

    if (!tabSellerValid && localTab === "info") return true;
    if (!tabClientLinksPrerequisite && localTab === "info2") return true;
    if (!tabReviewsPrerequisite && localTab === "links") return true;
    if (!reviews.length && localTab === "reviews") return true;

    return false;
  }, [
    localTab,
    tabSellerValid,
    tabClientLinksPrerequisite,
    tabReviewsPrerequisite,
    reviews,
  ]);

  return (
    <div className="management-order-modal">
      {/* {
                errorToastMessage &&
                <p className='modal-error'>
                    { errorToastMessage }
                </p>
            } */}
      <Dialog className={`${dialog.type}-dialog-box`} open={dialog.open}>
        <DialogContent>
          <DialogContentText>
            <span style={{ marginBottom: "2rem" }}>{dialog.message}</span>
          </DialogContentText>
          <DialogActions>
            {dialog.type === "confirm" && (
              <>
                <Button
                  variant="contained"
                  style={{ marginRight: "2rem" }}
                  onClick={() => handleDeleteOrder()}
                >
                  Confirm
                </Button>
                <Button
                  className="-gray"
                  variant="contained"
                  onClick={() => closeDialogHandler()}
                >
                  Cancel
                </Button>
              </>
            )}
            {dialog.type === "error" && (
              <Button variant="contained" onClick={() => closeDialogHandler()}>
                Confirm
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Box className="tab-box" sx={{ marginBottom: "2rem" }}>
        <Tabs
          className="order-modal-tabs"
          value={localTab}
          onChange={(e, newValue) => navClickedHandler(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {order?.id && (
            <Tab
              label="Order Information"
              value="info"
              sx={{ ...MuiTabsStyle }}
            />
          )}
          {order?.id && (
            <Tab label="Client Links" value="links" sx={{ ...MuiTabsStyle }} />
          )}
          {order?.id && (
            <Tab label="Reviews" value="reviews" sx={{ ...MuiTabsStyle }} />
          )}
          {order?.id && user?.role === 1 && (
            <Tab label="Order Log" value="logs" sx={{ ...MuiTabsStyle }} />
          )}

          {!order?.id && (
            <Tab
              // label={`1. SELECT/ADD CLIENT${ user?.role === 3 ? '' : ' AND SELLER' }`}
              label={
                <span className="mui-tab-label">
                  <span className="number-icon"> 1 </span>
                  <span> {`SELECT SELLER`} </span>
                </span>
              }
              value="info"
              sx={{ ...MuiTabsStyle }}
            />
          )}

          {!order?.id && (
            <Tab
              // label={`1. SELECT/ADD CLIENT${ user?.role === 3 ? '' : ' AND SELLER' }`}
              disabled={!tabSellerValid}
              label={
                <span className="mui-tab-label">
                  <span className="number-icon"> 2 </span>
                  <span> {`SELECT CLIENT`} </span>
                </span>
              }
              value="info2"
              sx={{ ...MuiTabsStyle }}
            />
          )}

          {!order?.id && (
            <Tab
              disabled={!tabClientLinksPrerequisite}
              label={
                <span className="mui-tab-label">
                  <span className="number-icon"> 3 </span>
                  <span> SELECT CLIENT LINK </span>
                </span>
              }
              value="links"
              sx={{ ...MuiTabsStyle }}
            />
          )}

          {!order?.id && (
            <Tab
              disabled={!tabReviewsPrerequisite}
              label={
                <span className="mui-tab-label">
                  <span className="number-icon"> 4 </span>
                  <span> ADD REVIEWS </span>
                </span>
              }
              value="reviews"
              sx={{ ...MuiTabsStyle }}
            />
          )}
          {/* { order?.id && user?.role === 3 && <Tab label="Client Links" value="3" /> } */}
        </Tabs>
      </Box>

      {localTab === "info" && order?.id && (
        <ManagementOrderModalInfo
          order_id={order?.id || null}
          user_role={user?.role || null}
          {...{
            disabledProps,
            sellerInfo,
            date,
            setDate,
            sellers,
            seller_email,
            setSellerEmail,
            seller_email_selected,
            setSellerEmailSelected,
            seller_name,
            setSellerName,
            clients,
            client_email_selected,
            setClientEmailSelected,
            client_email,
            setClientEmail,
            client_name,
            setClientName,
            client_phone,
            setClientPhone,
            third_party_id,
            setThirdPartyId,
            unit_cost,
            setUnitCost,
            industry_id,
            setClientIndustry,
            source_id,
            setClientSource,

            // companies,
            // company_name_selected,
            // setCompanyNameSelected,
            // company_name,
            // setCompanyName,
            // company_url,
            // setCompanyUrl
          }}
        />
      )}

      {localTab?.includes("info") && !order?.id && (
        <ManagementOrderModalInfoAdd
          order_id={order?.id || null}
          user_role={user?.role || null}
          {...{
            disabledProps,
            date,
            setDate,
          }}
        >
          {localTab === "info" ? (
            <ManagementOrderModalSeller
              order_id={order?.id || null}
              user_role={user?.role || null}
              {...{
                sellerInfo,
                sellers,
                seller_email,
                setSellerEmail,
                seller_email_selected,
                setSellerEmailSelected,
                seller_name,
                setSellerName,
                tabSellerValid,
              }}
            />
          ) : (
            <ManagementOrderModalClient
              {...{
                disabledProps,
                clients,
                client_email,
                setClientEmail,
                client_email_selected,
                setClientEmailSelected,
                client_name,
                setClientName,
                client_phone,
                setClientPhone,
                third_party_id,
                setThirdPartyId,
                unit_cost,
                setUnitCost,
                tabClientValid,
                industry_id,
                setClientIndustry,
                source_id,
                setClientSource,
                brand_code,
                setClientBrand,
              }}
            />
          )}
        </ManagementOrderModalInfoAdd>
      )}

      {localTab === "reviews" && (
        <ManagementOrderModalReviews
          order_id={order?.id || null}
          user_role={user?.role || null}
          {...{
            fetchTableData,
            disabledProps,
            fetchReviews,
            reviews,
            setReviews,
            remarks,
            setRemarks,
            total_price,
            // unit_cost,
            // setUnitCost,
            payment_status,
            setPaymentStatus,
            setReviewIsChanged,
            sendConfirmation,
            setSendConfirmation,
            company_url,
          }}
        />
      )}
      {/* <div className='invoice-con'>
                    <input
                        id="invoice"
                        type="file"
                        onChange={ e => setInvoiceImage( e.target.files[0] ) } />
                    <p className='file-upload-link' >
                        <span onClick={ uploadHandler }>
                            { fileName || "Upload Invoice" }
                        </span>
                        {
                            fileName && 
                        <span onClick={ () => setInvoiceImage( "" )}>
                            ✖
                        </span>
                        }
                    </p>
                    { fileDownload && <a href={ fileDownload } download={ fileName.split(".")[0] } target={ '_blank' } rel="noreferrer" >Download</a>  }
            </div> */}
      {localTab === "logs" && user?.role === 1 && (
        <ManagementOrderModalLog
          order_id={order?.id || null}
          created_by={order?.created_by || 0}
        />
      )}
      {localTab === "links" && (
        <ManagementOrderModalClientLinks
          client_id={client_id}
          fetchTableData={() => fetchTableData(false, current_page)}
          new_order={order?.id ? false : true}
          {...{
            company_name,
            company_url,
            setCompanyName,
            setCompanyUrl,
            tabReviewsPrerequisite,
            // companies
          }}
        />
      )}
      {localTab === "info" && (
        <ManagementInvoice
          handleInvoiceImage={(val) => setInvoiceImage(val)}
          fileName={fileName}
          fileDownload={fileDownload}
          orderId={order?.id ? order?.id : null}
        />
      )}
      <div className="base-btns order-base-btns">
        <Button
          type="button"
          variant="contained"
          className="-gray"
          sx={{ minWidth: "8rem" }}
          onClick={() => {
            if (reviewIsChanged) {
              setReviewIsChanged(false);
              fetchTableData(false, current_page);
            }
            handleClose();
          }}
        >
          {" "}
          {order?.id ? "Close" : "Cancel"}{" "}
        </Button>
        <div className="func-btns fd">
          {order?.id && (
            <>
              <Button
                type="button"
                variant="contained"
                className="-red"
                sx={{ minWidth: "8rem" }}
                onClick={() => deleteClickHandler()}
              >
                Delete
              </Button>
              <Button
                type="button"
                variant="contained"
                className="-green"
                sx={{ minWidth: "8rem", color: "white" }}
              >
                <Link
                  to={`/task?orderId=${order?.id}&clientEmail=${client_email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Create Task
                </Link>
              </Button>
            </>
          )}
          {!order?.id && localTab !== "info" && (
            <Button
              type="button"
              variant="contained"
              className="-gray"
              sx={{ minWidth: "8rem" }}
              onClick={prevHandler}
            >
              Previous
            </Button>
          )}
          {submitSpinner ? (
            <FidgetSpinner style={{ width: "10.9375rem" }} />
          ) : (
            // <Button
            // type="submit"
            // variant="contained"
            // onClick={submitHandler}
            // className="-big"> { order?.id ? "Update Order" : "Add New Order" } </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={submitBtnClicked}
              // onClick={ () => toast.success("yow", { autoClose: 100000 })  }
              disabled={submitDisabled}
              className="-big"
            >
              {" "}
              {submitText}{" "}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagementOrderModal;
