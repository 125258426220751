import {
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getHeader } from "../../../shared/Authorization";
import { ToastDataContext } from "../../UnderRoot";
import { ErrorHandler } from "../../../shared/CustomFunc";
import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL;

const INPUT_LABEL_PROPS = {
  shrink: true,
};

const ManagementOrderModalInfo = ({
  order_id = "",
  user_role,
  disabledProps,
  sellerInfo,
  date,
  setDate,
  sellers,
  seller_email,
  setSellerEmail,
  seller_email_selected,
  setSellerEmailSelected,
  seller_name,
  setSellerName,
  clients,
  client_email_selected,
  setClientEmailSelected,
  client_email,
  setClientEmail,
  client_name,
  setClientName,
  client_phone,
  setClientPhone,
  third_party_id,
  setThirdPartyId,
  unit_cost,
  setUnitCost,
  industry_id,
  setClientIndustry,
  source_id,
  setClientSource,
}) => {
  const { customToast } = useContext(ToastDataContext);

  const [industries, setIndustries] = useState([]);
  const [sources, setSources] = useState([]);

  const fetchIndustries = async () => {
    const headers = getHeader();

    await axios
      .get(ROOT_URL + "/client/industries", { headers })
      .then((resp) => {
        setIndustries(resp.data.industries);
        return { ok: true };
      })
      .catch((err) => {
        console.log(err);
        return ErrorHandler(err, customToast, "Error fetching industries");
      });
  };

  const fetchSources = async () => {
    const headers = getHeader();

    await axios
      .get(ROOT_URL + "/client/sources", { headers })
      .then((resp) => {
        setSources(resp.data.client_sources);
        return { ok: true };
      })
      .catch((err) => {
        console.log(err);
        return ErrorHandler(err, customToast, "Error fetching origin list");
      });
  };

  useEffect(() => {
    fetchIndustries();
    fetchSources();
    // eslint-disable-next-line
  }, []);

  const industrySelectHandler = (id) => {
    const foundIndustry = industries?.find((_industry) => _industry.id === id);

    if (!foundIndustry?.id) {
      setClientIndustry("");
      return;
    }

    setClientIndustry(foundIndustry.id);
  };

  const sourceSelectHandler = (id) => {
    const foundSource = sources?.find((_source) => _source.id === id);

    if (!foundSource?.id) {
      setClientSource("");
      return;
    }

    setClientSource(foundSource.id);
  };

  return (
    <ul className="info">
      <li className="fd">
        <TextField
          label="DATE"
          value={date || ""}
          disabled={disabledProps}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={INPUT_LABEL_PROPS}
          type="date"
          required={order_id ? true : false}
          variant="standard"
        />
        {order_id && user_role !== 3 ? (
          <TextField
            label="ORDER ID"
            value={order_id || ""}
            disabled
            variant="standard"
          />
        ) : (
          <div></div>
        )}
      </li>
      <li className="fd">
        {user_role !== 3 ? (
          <>
            <Autocomplete
              id="seller-email"
              options={sellers}
              getOptionLabel={(option) => option?.email || ""}
              freeSolo
              disableClearable
              value={seller_email_selected || null}
              onChange={(e, newValue) => {
                setSellerEmailSelected(newValue.email);
              }}
              inputValue={seller_email || ""}
              onInputChange={(e, newValue, reason) => {
                if (reason === "reset" && !newValue) return;
                setSellerEmail(newValue);
              }}
              renderOption={(params, option) => (
                <li {...params} key={option.id}>
                  {option.email}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="SELLER EMAIL"
                  variant="standard"
                  required
                />
              )}
            />
            <TextField
              id="seller-name"
              label="SELLER NAME"
              value={seller_name}
              onChange={(e) => setSellerName(e.target.value)}
              variant="standard"
              required
            />
          </>
        ) : (
          <>
            <Autocomplete
              id="seller-email"
              options={sellerInfo?.emails || []}
              getOptionLabel={(option) => option || ""}
              disableClearable
              disabled={order_id ? true : false}
              value={seller_email_selected || ""}
              inputValue={seller_email_selected || ""}
              onChange={(e, newValue) => setSellerEmailSelected(newValue)}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(params, option) => (
                <li {...params} key={option}>
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="SELLER EMAIL"
                  variant="standard"
                  required
                />
              )}
            />
            <TextField
              id="seller-name"
              label="SELLER NAME"
              value={seller_name}
              variant="standard"
              disabled
              inputProps={{
                readOnly: true,
              }}
            />
          </>
        )}
      </li>
      <li className="fd">
        <Autocomplete
          id="client-email"
          options={clients}
          getOptionLabel={(option) => option?.email || ""}
          freeSolo
          disabled={disabledProps}
          disableClearable
          value={client_email_selected || null}
          onChange={(e, newValue) => setClientEmailSelected(newValue.email)}
          inputValue={client_email || ""}
          onInputChange={(e, newValue, reason) => {
            if (reason === "reset" && !newValue) return;
            setClientEmail(newValue);
          }}
          renderOption={(params, option) => (
            <li {...params} key={option.id}>
              {option.email}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="CLIENT EMAIL"
              variant="standard"
              required
            />
          )}
        />
        <TextField
          id="client-name"
          label="CLIENT NAME"
          value={client_name || ""}
          disabled={disabledProps}
          onChange={(e) => setClientName(e.target.value)}
          variant="standard"
          required
        />
      </li>
      <li className="fd">
        <TextField
          id="client-phone"
          label="CLIENT PHONE"
          disabled={disabledProps}
          value={client_phone || ""}
          onChange={(e) => setClientPhone(e.target.value)}
          variant="standard"
        />
        <TextField
          id="third-party-id"
          label="3RD PARTY ID"
          disabled={disabledProps}
          value={third_party_id || ""}
          onChange={(e) => setThirdPartyId(e.target.value)}
          variant="standard"
        />
      </li>
      <li className="fd">
        <FormControl variant="standard">
          <InputLabel id="client_industry" sx={{ p: 0 }}>
            INDUSTRY
          </InputLabel>
          <Select
            labelId="client_industry"
            variant="standard"
            disabled={disabledProps}
            value={industry_id ?? ""}
            onChange={(e) => industrySelectHandler(e.target.value)}
          >
            {industries.map((industry, i) => (
              <MenuItem key={i} value={industry.id}>
                {" "}
                {industry.name.toUpperCase()}{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard">
          <InputLabel id="client_source">CLIENT ORIGIN</InputLabel>
          <Select
            labelId="client_source"
            variant="standard"
            disabled={disabledProps}
            value={source_id ?? ""}
            onChange={(e) => sourceSelectHandler(e.target.value)}
          >
            {sources.map((_source, i) => (
              <MenuItem key={i} value={_source.id}>
                {" "}
                {_source.name.toUpperCase()}{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </li>
      <li>
        <TextField
          id="unit_price"
          label="UNIT COST"
          disabled={disabledProps}
          value={unit_cost || 0}
          InputProps={{ inputProps: { min: 0 } }}
          type="number"
          sx={{ width: "100%" }}
          onChange={(e) => setUnitCost(e.target.value)}
          variant="standard"
        />
      </li>
      {/* <li className="fd">
                <Autocomplete
                    id="company"
                    options={ companies }
                    getOptionLabel={ option => option?.name || "" }
                    freeSolo
                    disabled={ disabledProps }
                    disableClearable
                    value={ company_name_selected || "" } 
                    onChange={ ( e, newValue ) => setCompanyNameSelected( newValue.name ) }
                    inputValue={ company_name || "" } 
                    onInputChange={ ( e, newValue, reason ) => {
                        if ( reason === "reset" && !newValue  ) return 
                        setCompanyName( newValue )
                     } }
                    renderOption={ ( params, option ) => 
                        <li {...params} key={ option.id }>
                                {option.name}
                        </li>
                    }
                    renderInput={ params => 
                        <TextField
                            { ...params }
                            label="COMPANY" 
                            variant="standard"
                            required /> } />
                    <TextField
                        id="company-url"
                        label="COMPANY URL"
                        disabled={ disabledProps } 
                        value={ company_url || "" } 
                        onChange={ e => setCompanyUrl( e.target.value ) } 
                        variant="standard"
                        required />
            </li> */}
    </ul>
  );
};

export default ManagementOrderModalInfo;
