import React, { useMemo, useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import LineChart from "./LineChart";
import "./ReportsOrder.scss";
import PieChart from "./PieChart";
import useReports from "../../../useHooks/useReports";
import DataCard from "../../../sharedComponent/DataCard";
import BarChart from "./BarChart";

dayjs.extend(utc);

const originColors = [
  "#5390d9",
  "#7400b8",
  "#80ffdb",
  "#5e60ce",
  "#64dfdf",
  "#56cfe1",
  "#4ea8de",
  "#43aa8b",
];

const industryColors = [
  "#FF5733",
  "#C70039",
  "#900C3F",
  "#581845",
  "#FFC300",
  "#FFD700",
  "#4CAF50",
  "#03A9F4",
  "#9C27B0",
  "#FF5733",
  "#5E35B1",
  "#FBC02D",
  "#FF5722",
  "#039BE5",
  "#F44336",
  "#9E9E9E",
  "#673AB7",
  "#E91E63",
  "#8BC34A",
  "#00BCD4",
  "#2196F3",
  "#9C27B0",
  "#CDDC39",
  "#4CAF50",
  "#FF9800",
  "#03A9F4",
  "#F44336",
  "#FFC107",
  "#FF5722",
  "#FFD700",
  "#03A9F4",
  "#8E44AD",
  "#FF9800",
  "#4CAF50",
  "#009688",
  "#9E9E9E",
  "#FFC300",
  "#FF5733",
  "#E74C3C",
  "#795548",
  "#00BCD4",
  "#F44336",
  "#8BC34A",
  "#9C27B0",
];

const sellerColors = [
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  // "#8BC34A", "#CDDC39", "#FFC107", "#FF9800",
  // "#FF5722", "#795548", "#9E9E9E", "#607D8B", "#333333", "#000000"
];

const ReportsClients = ({ dateFrom, dateTo }) => {
  const params = {
    startDate: dateFrom.format("YYYY-MM-DD"),
    endDate: dateTo.format("YYYY-MM-DD"),
  };
  const {
    seller_email_selected,
    clientsReportCharts,
    fetchClientStats,
    fetchClientCount,
    fetchClientPercentage,
    clientStats,
  } = useReports();

  const clientsAggrevatedData = useMemo(() => {
    const _aggrevatedDatas = [];

    const _constantsData = {
      color: "#0094FF",
      src: "",
    };

    _aggrevatedDatas.push({
      label: "Total Clients",
      value: clientStats?.total_clients || 0,
      ..._constantsData,
    });

    _aggrevatedDatas.push({
      label: "Clients Logged in",
      value: clientStats?.logged_clients || 0,
      ..._constantsData,
    });

    _aggrevatedDatas.push({
      label: "New Clients (Past 24 hours)",
      value: clientStats?.new_clients || 0,
      ..._constantsData,
    });

    return _aggrevatedDatas;
  }, [clientStats]);

  const barChartData = useMemo(() => {
    const sortedClientIndustry = clientsReportCharts.client_industry.slice();
    sortedClientIndustry.sort((a, b) => b.count - a.count);

    const sortedClientOrigin = clientsReportCharts.client_origin.slice();
    sortedClientOrigin.sort((a, b) => b.count - a.count);

    const sortedSellers = clientsReportCharts.sellers.slice();
    sortedSellers.sort((a, b) => b.count - a.count);

    const clientIndustryLabels = sortedClientIndustry.map((industry) => {
      return industry.industry;
    });

    const clientOriginLabels = sortedClientOrigin.map((origin) => {
      return origin.origin;
    });

    const sellerLabels = sortedSellers.map((seller) => {
      return seller.seller;
    });

    const finalData = {
      clientIndustryCount: {
        labels: clientIndustryLabels,
        datasets: [
          {
            data: sortedClientIndustry.map((industry) => industry.count || 0),
            backgroundColor: clientsReportCharts.client_industry.map(
              (industry) => industryColors[industry.id - 1]
            ),
          },
        ],
      },
      clientOriginCount: {
        labels: clientOriginLabels,
        datasets: [
          {
            data: sortedClientOrigin.map((origin) => origin.count || 0),
            backgroundColor: clientsReportCharts.client_origin.map(
              (origin) => originColors[origin.id - 1]
            ),
          },
        ],
      },
      sellerCount: {
        labels: sellerLabels,
        datasets: [
          {
            data: sortedSellers.map((seller) => seller.count || 0),
            backgroundColor: clientsReportCharts.sellers.map((_, index) => {
              const colorIndex = index % sellerColors.length;
              return sellerColors[colorIndex];
            }),
          },
        ],
      },
    };

    return finalData;
  }, [clientsReportCharts]);

  const pieChartData = useMemo(() => {
    const sortedClientOrigin = clientsReportCharts.client_origin.slice();
    sortedClientOrigin.sort((a, b) => b.percentage - a.percentage);

    const sortedClientIndustry = clientsReportCharts.client_industry.slice();
    sortedClientIndustry.sort((a, b) => b.percentage - a.percentage);

    const sortedSellerPercentage = clientsReportCharts.sellers.slice();
    sortedSellerPercentage.sort((a, b) => b.percentage - a.percentage);

    const clientOriginLabels = sortedClientOrigin
      .filter((origin) => origin.percentage !== 0)
      .sort((a, b) => b.count - a.count)
      .map((origin) => `${origin.origin} - ${origin.percentage}%`);

    const clientIndustryLabels = sortedClientIndustry
      .filter((industry) => industry.percentage !== 0)
      .sort((a, b) => b.count - a.count)
      .map((industry) => `${industry.industry} - ${industry.percentage}%`);

    const sellerLabels = sortedSellerPercentage
      .filter((industry) => industry.percentage !== 0)
      .sort((a, b) => b.count - a.count)
      .map((seller) => `${seller.seller} - ${seller.percentage}%`);

    const finalData = {
      clientIndustry: {
        labels: clientIndustryLabels,
        datasets: [
          {
            data: sortedClientIndustry.map(
              (industry) => industry.percentage || 0
            ),
            backgroundColor: clientsReportCharts.client_industry.map(
              (industry) => industryColors[industry.id - 1]
            ),
          },
        ],
      },
      clientOrigin: {
        labels: clientOriginLabels,
        datasets: [
          {
            data: sortedClientOrigin.map((origin) => origin.percentage || 0),
            backgroundColor: clientsReportCharts.client_origin.map(
              (origin) => originColors[origin.id - 1]
            ),
          },
        ],
      },
      sellers: {
        labels: sellerLabels,
        datasets: [
          {
            data: sortedSellerPercentage.map(
              (seller) => seller.percentage || 0
            ),
            backgroundColor: clientsReportCharts.sellers.map((_, index) => {
              const colorIndex = index % sellerColors.length;
              return sellerColors[colorIndex];
            }),
          },
        ],
      },
    };

    return finalData;
  }, [clientsReportCharts]);

  const lineCharts = useMemo(() => {
    const length = Object.keys(clientsReportCharts.total_clients)?.length || 30;

    const dateRange = Array.from({ length: length }, (_, i) => {
      const date = dateTo.subtract(i, "day").format("YYYY-MM-DD");
      return date;
    }).reverse();

    const newClientsData = {
      labels: dateRange,
      datasets: [
        {
          label: "New Clients",
          data: dateRange.map(
            (date) => clientsReportCharts?.new_clients[date] || 0
          ),
          fill: true,
          borderColor: "#30A8FF",
          backgroundColor: "rgba(48, 168, 255, 0.3)",
        },
      ],
    };

    console.log(newClientsData);

    const totalClientsData = {
      labels: dateRange,
      datasets: [
        {
          label: "Total Clients",
          data: dateRange.map(
            (date) => clientsReportCharts?.total_clients[date] || 0
          ),
          borderColor: "#30A8FF",
          fill: true,
          backgroundColor: "rgba(48, 168, 255, 0.3)",
        },
      ],
    };

    return [
      { label: "New Clients", data: newClientsData },
      { label: "Total Clients", data: totalClientsData },
    ];
  }, [
    clientsReportCharts.new_clients,
    clientsReportCharts.total_clients,
    dateTo,
  ]);

  const barAndPieCharts = useMemo(() => {
    return [
      {
        label: "Client Origin Count",
        barChart: barChartData.clientOriginCount,
        pieChart: pieChartData.clientOrigin,
      },
      {
        label: "Client Industry Count",
        barChart: barChartData.clientIndustryCount,
        pieChart: pieChartData.clientIndustry,
      },
      {
        label: "Seller Count",
        barChart: barChartData.sellerCount,
        pieChart: pieChartData.sellers,
      },
    ];
  }, [barChartData, pieChartData]);

  useEffect(() => {
    fetchClientStats();
    fetchClientCount(params);
    fetchClientPercentage(params);
    //eslint-disable-next-line
  }, [dateFrom, dateTo, seller_email_selected]);

  return (
    <>
      <div className="data-card-container -client-system margin--top-standard">
        <ul className="fd">
          {clientsAggrevatedData.map((data, i) => {
            return (
              <li key={i}>
                <DataCard
                  label={data.label}
                  value={data.value}
                  color="#30A8FF"
                />
              </li>
            );
          })}
        </ul>
      </div>

      {lineCharts.map((chart, index) => (
        <div className="margin--top-standard" key={index}>
          <h4>{chart.label}</h4>
          <LineChart
            datasetIdKey={chart.label}
            displayLegend={false}
            data={chart.data}
          />
        </div>
      ))}

      {barAndPieCharts.map((chart, index) => (
        <div key={index} className="margin--top-standard charts-container">
          <div>
            <h4>{chart.label}</h4>
            <BarChart key={chart.label} data={chart.barChart} />
          </div>
          <div>
            <PieChart
              key={chart.label}
              displayLegend={false}
              data={chart.pieChart}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default ReportsClients;
