import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import { TableHeader } from "../../../shared/TableHeader";
import { StyledTableCell } from "../../../shared/CustomFunc";
import {
  useGetEmailTemplates,
  useGetProspectEmailLogs,
  useSendProspectEmail,
} from "../../../useHooks/useProspects";
import { useState } from "react";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import dayjs from "dayjs";

const ProspectModalEmail = ({ prospectId, handleClose }) => {
  const [template, setTemplate] = useState("");

  const { data: emailTemplates } = useGetEmailTemplates();

  const { data: prospectEmailLogs, isFetching } =
    useGetProspectEmailLogs(prospectId);

  const { mutateAsync: sendEmail, isLoading: isSending } =
    useSendProspectEmail();

  const handleSelectTemplate = (templateId) => {
    setTemplate(templateId);
  };

  const handleSendEmail = async () => {
    await sendEmail({
      prospectId,
      payload: {
        template_id: template,
      },
    });
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: "2rem" }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="template_email" sx={{ p: 0 }}>
            Select Email Template
          </InputLabel>
          <Select
            labelId="template_email"
            variant="standard"
            value={template}
            onChange={(e) => handleSelectTemplate(e.target.value)}
          >
            {emailTemplates &&
              emailTemplates.map((template, i) => (
                <MenuItem key={i} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          disableRipple
          type="button"
          sx={{ minWidth: "8rem" }}
          onClick={handleSendEmail}
          disabled={isSending}
        >
          {isSending ? (
            <FidgetSpinner fidgetStyle={{ height: "1rem", width: "1rem" }} />
          ) : (
            "Send"
          )}
        </Button>
      </Box>

      <TableContainer sx={{ pt: "4rem" }}>
        <Table>
          <TableHeader
            columnHead={[
              { label: "Date Sent" },
              { label: "Template" },
              { label: "Sent by" },
            ]}
          />
          <TableBody>
            {isFetching && (
              <TableRow>
                <StyledTableCell colSpan={3}>
                  <FidgetSpinner
                    fidgetStyle={{ height: "3rem", width: "3rem" }}
                  />
                </StyledTableCell>
              </TableRow>
            )}
            {prospectEmailLogs &&
              !isFetching &&
              prospectEmailLogs.map((log, index) => {
                // Remove last index logic and styling once pagination is done
                const lastIndex =
                  prospectEmailLogs.length === index + 1 ? true : false;

                return (
                  <TableRow
                    key={index}
                    sx={
                      lastIndex
                        ? { borderBottom: "solid 1px rgba(224, 224, 224, 1)" }
                        : {}
                    }
                  >
                    <StyledTableCell
                      sx={{ p: "1rem !important", whiteSpace: "nowrap" }}
                    >
                      {dayjs(log.created_at).format("DD-MM-YYYY hh:mm:ss")}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ p: "1rem !important", whiteSpace: "nowrap" }}
                    >
                      {log.template}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ p: "1rem !important", whiteSpace: "nowrap" }}
                    >
                      {log.by}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          mt: "4rem",
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Button
          variant="contained"
          type="button"
          className="-gray"
          onClick={handleClose}
          sx={{
            width: { xs: "100%", md: "unset" },
            minWidth: "8rem",
            p: "0.5rem 1.5rem",
          }}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default ProspectModalEmail;
