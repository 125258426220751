/* eslint-disable no-useless-escape */
import { TableCell, tableCellClasses } from "@mui/material";
import { cleanAuthorization } from "./Authorization";
import styled from "@emotion/styled";

export const ErrorHandler = (
  err,
  toast = null,
  defaultMessage = "Error running task"
) => {
  console.log(err);
  if (!err?.response?.data?.message) {
    if (toast) toast(defaultMessage);

    return { ok: false, message: defaultMessage };
  }

  if (
    err?.response?.status !== 408 &&
    err?.response?.status !== 422 &&
    err.response.status !== 401
  ) {
    if (toast) toast(defaultMessage);

    return { ok: false, message: defaultMessage };
  }

  if (err?.response?.status === 401) {
    // redirect here to log out

    cleanAuthorization();
    localStorage.removeItem("grocket_user");

    window.location.reload();

    return;
  }

  const message =
    err?.response?.data?.message.slice(0, 1).toUpperCase() +
    err?.response?.data?.message.slice(1);

  if (toast) toast(message);

  return { ok: false, message };
};

export const dateCleaner = (date) => {
  if (!date) return "";
  return date.replace("T", " ").slice(0, 10);
};

export const timeExtract = (date) => (date ? date.slice(11, 16) : "");

// export const emailCheck = email => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( email )
export const emailCheck = (email) => /^[^@\s]+@[^\s.]+\.[^\s]{2,}$/.test(email);

export const createdByChecker = (value) => {
  if (value === 1) {
    return "Admin";
  } else if (value === 2) {
    return "Accountant";
  } else if (value === 3) {
    return "Seller";
  } else if (value === 4) {
    return "Client";
  } else {
    return "Seller";
  }
};

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    padding: "0.5rem 1rem",
    border: "none",
  },
}));
