import { Box, Tab, Tabs } from "@mui/material"
// import ClientsModalOrder from "./ClientsModalOrder";
import './ClientsModal.scss';
// import ClientsModalInfo from "./ClientsModalInfo";
// import { getHeader } from "../../../shared/Authorization";
// import axios from "axios";
// import { ErrorHandler } from "../../../shared/CustomFunc";
// import { toast } from "react-toastify";

// const ROOT_URL = process.env.REACT_APP_API_URL

const ClientsModal = ( { children, client, localTab, handleTab } ) => {
    return (
        <>
            {   
                client?.id !== "none" &&
                <Box className="tab-box"  sx={ { marginBottom: "2rem" } }>
                    <Tabs value={ localTab } onChange={ (e, newValue) => handleTab( newValue ) }>
                        {/* <Tab label="New Order" value="1"></Tab> */}
                        <Tab label="Client Information" value="2"></Tab>
                        <Tab label="Order History" value="3"></Tab>
                        {/* <Tab label="Generate New Password" value="4"></Tab> */}
                    </Tabs>
                </Box>
            }
            { children }
            {/* {
                localTab === "1"  && <ClientsModalInfo client={ client } addClientHandler={ addClientHandler } />
            }
            {
                localTab === "3" && user?.role === 3 && <ClientsModalOrder client={ client } />
            } */}
        </>
    )
}

export default ClientsModal