/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { getHeader } from "../../../shared/Authorization";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import TextEditor from "../../../sharedComponent/TextEditor";
import "./ManagementOrderModalLog.scss";
import { ToastDataContext } from "../../UnderRoot";

const ROOT_URL = process.env.REACT_APP_API_URL;

const ManagementOrderLog = ({ order_id = null, created_by = 0 }) => {
  const { customToast } = useContext(ToastDataContext);

  const [fetchSpinner, setSpinner] = useState(false);
  const headers = getHeader();
  const [logs, setLogs] = useState("<p></p>");

  const fetchLogs = async () => {
    setSpinner(true);

    await axios
      .get(ROOT_URL + "/order/log/" + order_id, { headers })
      .then((resp) => {
        let _logs = "";

        resp.data.logs.forEach(
          (item) =>
            (_logs += `<p>${item.date} - ${
              item.action === "status mail sent"
                ? `Status Mail - ${
                    item.email_type.charAt(0).toUpperCase() +
                    item.email_type.slice(1)
                  } sent`
                : item.action.charAt(0).toUpperCase() + item.action.slice(1)
            } by ${item.by}</p>`)
        );

        setLogs(_logs);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        customToast("Error getting logs");
      });
  };

  useEffect(() => {
    if (order_id) fetchLogs();
  }, [order_id]);

  return (
    <div>
      {fetchSpinner ? (
        <FidgetSpinner />
      ) : (
        <TextEditor
          className="textfield-multi-logs"
          content={logs}
          editable={false}
        />
      )}
    </div>
  );
};

export default ManagementOrderLog;
