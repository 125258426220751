/* eslint-disable react-hooks/exhaustive-deps */
// import axios from 'axios';
import {  useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../store/userSlice";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import HeaderLogged from "./HeaderLogged";
import { Visibility,VisibilityOff } from "@mui/icons-material";
import { ToastDataContext } from "../UnderRoot";
import FidgetSpinner from "../../sharedComponent/FidgetSpinner";

const HeaderLogin = () => {
    const dispatch = useDispatch()

    const { customToast } = useContext( ToastDataContext )

    const [ email, setEmail ] = useState( "" )
    const [ password, setPassword ] = useState( "" )

    const [ showPassword, setShow ] = useState( false );

    const [ isLoading, setIsLoading ] = useState( false );

    const submitHandler = async e => {
        e.preventDefault()
        
        if ( !email || !password ) {
            customToast("Fields cannot be empty")
            return
        }

        setIsLoading(true);

        const status = await dispatch( login( { email, password } ) )

        if ( !status?.ok ) {
            customToast( status.message )
        }

        setIsLoading(false);
    }

    return (
        <HeaderLogged submitHandler={ submitHandler }>
            <TextField 
                sx={ { 
                    display: "block",
                    marginBottom: "2rem",
                } }
                fullWidth
                type={ "email" }
                label="Email" 
                variant="standard"
                value={ email }
                required
                onChange={ (e) => setEmail( e.target.value ) }
                InputLabelProps={ {
                    style: {
                        color: "white"
                    },
                    required: false
                } } />
            <TextField 
                sx={ { 
                    display: "block",
                    marginBottom: "2rem"
                } }
                fullWidth
                type={ showPassword ? "text" : "password" }
                label="Password" 
                variant="standard"
                value={ password }
                required
                onChange={ (e) => setPassword( e.target.value ) }
                InputLabelProps={
                    {
                        style: {
                            color: "white"
                        },
                        required: false
                    }
                }
                InputProps={ {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={ () => setShow( !showPassword ) }>
                                { showPassword ? <Visibility /> : <VisibilityOff /> }
                            </IconButton>
                        </InputAdornment>
                    )
                } } />
            <Button
                className="-green"
                variant="contained"
                type="submit"
                disabled={isLoading}
                fullWidth>
                    { isLoading ?
                        <FidgetSpinner
                            fidgetStyle={{ width: '1rem', height: '1rem' }}
                        /> : 'LOGIN'
                    }
            </Button>
            <Link className="reset-password-link" to="/reset_password/step1"> Forgot your password? </Link>
        </HeaderLogged>
    )
}

export default HeaderLogin;