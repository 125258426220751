import {
  Box,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import {
  useCreateBrand,
  useGetSelectedBrand,
  useUpdateBrand,
  useUpdateBrandImage,
} from "../../../useHooks/useBrands";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ToastDataContext } from "../../UnderRoot";

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const THUMBNAIL_STYLE = {
  display: "flex",
  height: 75,
  minWidth: 0,
  overflow: "hidden",
};

const IMG_STYLE = {
  display: "block",
  width: "auto",
  height: "100%",
};

const IMAGE_BTN_STYLE = {
  p: "0.25rem 1.5rem !important",
  textTransform: "unset",
  background: "none !important",
  borderRadius: "15px",
  border: "1px solid #30A8FF",
  minHeight: "unset !important",
  fontSize: "0.75rem",
};

const CURRENCIES = [
  {
    id: "USD",
    name: "USD",
  },
  { id: "EUR", name: "EUR" },
];

const INITIAL_DATA = {
  name: "",
  address: "",
  code: "",
  currency: "",
  image: "",
};

const BrandModal = ({ brandId, handleClose }) => {
  const { customToast } = useContext(ToastDataContext);
  const [formState, setFormState] = useState(INITIAL_DATA);
  const [imageFile, setImageFile] = useState(null);

  const {
    data: selectedBrand,
    isInitialLoading: isFetchingBrand,
    isError,
  } = useGetSelectedBrand(brandId);

  const { mutateAsync: createBrand, isLoading: isAdding } = useCreateBrand();

  const { mutateAsync: updateBrand, isLoading: isUpdating } = useUpdateBrand();

  const { mutateAsync: updateImage } = useUpdateBrandImage();

  const { open, acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "image/*": [],
      },
      onDrop: (acceptedFiles) => {
        const file = acceptedFiles[0];
        setImageFile(
          Object.assign(file, { preview: URL.createObjectURL(file) })
        );

        if (brandId) {
          const formData = new FormData();
          formData.append("image", acceptedFiles[0]);

          updateImage({ id: brandId, payload: formData });
        }
      },
    });

  const previewImage = useMemo(() => {
    if (imageFile) {
      if (acceptedFiles[0]?.name) {
        return imageFile.preview;
      }
    }

    if (selectedBrand && selectedBrand?.image) {
      return STORAGE_URL + selectedBrand.image;
    }

    return null;
  }, [selectedBrand, imageFile, acceptedFiles]);

  const handleChange = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCurrencySelect = (currencyId) => {
    setFormState((prev) => ({
      ...prev,
      currency: currencyId,
    }));
  };

  const handleDeleteImage = () => {
    if (!window.confirm("Do you want to delete brand image?")) return;

    if (selectedBrand?.image) {
      // handle delete image to the API later
      const formData = new FormData();
      formData.append("image_delete", true);

      updateImage({ id: brandId, payload: formData });
    }

    setImageFile(null);
  };

  const handleSave = async () => {
    if (!formState.name) {
      customToast("Brand name is required");
      return;
    }

    if (!formState.code) {
      customToast("Brand code is required");
      return;
    }

    if (!formState.currency) {
      customToast("Currency field is required");
      return;
    }

    const formData = new FormData();

    formData.append("name", formState.name);
    formData.append("code", formState.code);
    formData.append("currency", formState.currency);

    if (formState.address) {
      formData.append("address", formState.address);
    }

    if (!brandId && acceptedFiles[0]?.name) {
      formData.append("image", acceptedFiles[0]);
    }

    let result;

    if (!brandId) {
      result = await createBrand(formData);
    } else {
      result = await updateBrand({
        id: brandId,
        payload: { ...formState },
      });
    }

    if (result.status === 200 || result.status === 201) {
      handleClose();
    }
  };

  useEffect(() => {
    if (selectedBrand) {
      setFormState({
        name: selectedBrand.name,
        address: selectedBrand?.address || "",
        code: selectedBrand.code,
        currency: selectedBrand.currency,
        image: selectedBrand?.image || "",
      });
    }
    //eslint-disable-next-line
  }, [selectedBrand]);

  // Close modal if fetch errors
  if (isError) handleClose();
  // Spinner when fetching
  if (isFetchingBrand) {
    return (
      <Box sx={{ padding: "2rem" }}>
        <FidgetSpinner fidgetStyle={{ width: "3rem", height: "3rem" }} />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: { xs: "1rem", md: "2rem" } }}>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
          <TextField
            fullWidth
            variant="standard"
            label="Brand Name"
            value={formState.name}
            onChange={(e) => handleChange("name", e.target.value)}
            inputProps={{ style: { fontSize: "1.5rem" } }}
            autoComplete="off"
          />

          <TextField
            fullWidth
            variant="standard"
            label="Address"
            value={formState.address}
            onChange={(e) => handleChange("address", e.target.value)}
            inputProps={{ style: { fontSize: "1.5rem" } }}
            autoComplete="off"
          />

          <TextField
            fullWidth
            variant="standard"
            label="Brand Code"
            value={formState.code}
            onChange={(e) => handleChange("code", e.target.value)}
            multiline
            autoComplete="off"
          />

          <FormControl variant="standard" fullWidth>
            <InputLabel id="client_industry" sx={{ p: 0 }}>
              Currency
            </InputLabel>
            <Select
              labelId="brand_currency"
              variant="standard"
              value={formState.currency}
              onChange={(e) => handleCurrencySelect(e.target.value)}
            >
              {CURRENCIES.map((currency, i) => (
                <MenuItem key={i} value={currency.id}>
                  {" "}
                  {currency.name.toUpperCase()}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box>
            <Typography fontSize={"0.75rem"} color={"rgba(0, 0, 0, 0.6)"}>
              Brand Logo
            </Typography>

            <Box
              {...getRootProps()}
              sx={{
                p: previewImage ? "0" : "2rem",
                mt: "0.5rem",
                border: previewImage ? "none" : "2px dotted #000000",
                display: "flex",
                justifyContent: "center",
                maxWidth: previewImage
                  ? "fit-content"
                  : { xs: "100%", md: "50%" },
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                {previewImage ? (
                  <Box sx={THUMBNAIL_STYLE}>
                    <img
                      src={previewImage}
                      style={IMG_STYLE}
                      alt={"Preview email header"}
                      // Revoke data uri after image is loaded
                      onLoad={() => {
                        URL.revokeObjectURL(previewImage);
                      }}
                    />
                  </Box>
                ) : (
                  <FontAwesomeIcon
                    icon={solid("upload")}
                    size="lg"
                    style={{
                      color: "#30A8FF",
                    }}
                  />
                )}

                {previewImage || imageFile?.name ? null : isDragActive ? (
                  <Typography variant="body1" fontSize="0.875rem">
                    Drop your file here
                  </Typography>
                ) : (
                  <Typography variant="body1" fontSize="0.875rem">
                    Click or drag here to upload file
                  </Typography>
                )}
              </Box>
            </Box>

            {previewImage && (
              <Box sx={{ mt: "1rem", display: "flex", gap: "1rem" }}>
                <Button
                  type="button"
                  disableRipple
                  onClick={open}
                  sx={IMAGE_BTN_STYLE}
                >
                  Replace
                </Button>
                <Button
                  type="button"
                  disableRipple
                  onClick={handleDeleteImage}
                  sx={{
                    ...IMAGE_BTN_STYLE,
                    color: "red",
                    borderColor: "red",
                  }}
                >
                  Delete
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            mt: "2rem",
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Button
            variant="contained"
            type="button"
            className="-gray"
            onClick={handleClose}
            sx={{
              width: { xs: "100%", md: "unset" },
              minWidth: "8rem",
              p: "0.5rem 1.5rem",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="button"
            disabled={isAdding || isUpdating}
            onClick={handleSave}
            sx={{
              width: { xs: "100%", md: "unset" },
              minWidth: "8rem",
              p: "0.5rem 1.5rem",
            }}
          >
            {isAdding || isUpdating ? (
              <FidgetSpinner fidgetStyle={{ width: "1rem", height: "1rem" }} />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </DialogContent>
    </Box>
  );
};

export default BrandModal;
