import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cleanAuthorization, saveAuthorization } from "../shared/Authorization";

const rootUrl = process.env.REACT_APP_API_URL

export const userSlice = createSlice( {
    name: 'user',
    initialState: {
        value: { }
    },
    reducers: {
        loadUser: ( state ) => {
            const user = localStorage.getItem( "grocket_user" )

            if ( user ) {
                const userParsed = JSON.parse( user )
                
                state.value = {
                    id: userParsed.id,
                    role: userParsed.role
                }
            } else state.value = {}
        },
        logout: state => {

            cleanAuthorization()
            localStorage.removeItem("grocket_user")

            state.value = {  }
        }
    }
} )

export const { loadUser, logout } = userSlice.actions

export const login = credentials => async dispatch => {
    return await axios.post( rootUrl + '/user/login', credentials )
    .then( resp => {
        const user = resp.data.user;

        if ( user.status === 0 ) {
            return { ok: false, message: "User does not exists" }
        }

        const localStorageObject = {
            id: user.id,
            role: user.role
        }

        saveAuthorization( resp.data?.access_token )
        
        localStorage.setItem( "grocket_user", JSON.stringify( localStorageObject ) )

        dispatch( loadUser() )

        return { ok: true, message: "" }
    } )
    .catch( err => {
        console.log( err )

        const message = err.response.status === 401 ? err.response.data.message : "Error logging in. Please try again later..."

        return { ok: false, message }
    } )
}

export default userSlice.reducer