/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import qs from 'qs';
import { useContext, useEffect, useState } from "react";
import { getHeader } from "../../../shared/Authorization";
import { dateCleaner, ErrorHandler } from "../../../shared/CustomFunc";
import ManagementApplicationModal from "./ManagementApplicationModal";
import ManagementApplicationTable from "./ManagementApplicationTable";
import { ToastDataContext } from "../../UnderRoot";

const ROOT_URL = process.env.REACT_APP_API_URL

const INIT_APPLICATION_PAGINATION = {
    total: 0,
    current_page: 1
}

const ManagementApplicationSystem = () => {
    const [ applicationPagination, setApplicationPagination ] = useState( INIT_APPLICATION_PAGINATION )
    const [ applications, setApplications ] = useState( [] )

    const { customToast } = useContext( ToastDataContext )

    const headers = getHeader()

    const arrayMaker = arr => {
        return arr.map( _item => {
            return {
                ..._item,
                date: dateCleaner( _item.created_at ),
                status_text: _item.status === 0 ? "Declined" : ( _item.status === 1 ? "Pending" : ( _item.status === 2 ? "Approved" : "Cancelled" ) ),
                // status_text: _item.status === 0 ? "Declined" : ( _item.status === 1 ? "Pending" : "Approved" ),
                tier_text: _item.tier === 1 ? "Premium" : "VIP"
            }
        } )
    }

    const fetchApplications = async ( new_page=1 ) => {
        const params = {
            page: new_page,
            per_page: 10
        }

        axios.get( ROOT_URL + '/client/upgrades', { headers, params } )
        .then( resp => {
            const _applications = resp.data.upgrades?.data ? arrayMaker( resp.data.upgrades.data ) : []

            setApplicationPagination( {
                total: resp.data.upgrades?.total || 0,
                current_page: resp.data.upgrades?.current_page || 1
            } )

            setApplications(  _applications )
        } )
        .catch( err => {
            console.log( err )
            
            ErrorHandler( err, customToast, "Error fetching accounts" )
        } )
    }
    
    useEffect( () => {
        fetchApplications()

        return () => {
            setApplications( [] )
            setApplicationPagination(INIT_APPLICATION_PAGINATION)
        }
    }, [] )

    const [ application, setApplication ] = useState( {} )

    const rowClickHandler = application => {
        setApplication( application )
    }

    const updateHandler = async _data => {
        const data = qs.stringify( _data )

        if ( !application?.id ) return

        const headers = getHeader()

        return await axios.put( ROOT_URL + '/client/upgrade-account/' + application.id, data, { headers } )
        .then( resp => {
            const { application:_application } = resp.data

            fetchApplications()

            setApplication( {
                ...application,
                ..._application,
                // date: dateCleaner( _application.created_at ),
                status_text: _application.status === 0 ? "Declined" : ( _application.status === 1 ? "Pending" : ( _application.status === 2 ? "Approved" : "Cancelled" ) ),
                // status_text: _application.status === 0 ? "Declined" : ( _application.status === 1 ? "Pending" : "Approved" ),
                tier_text: _application.tier === 1 ? "Premium" : "VIP"
            } )

            return { ok: true }
        } )
        .catch( err => {
            console.log( err )

            ErrorHandler( err, customToast, "Error updating account. Please try again later..." )
        } )
    }

    return (
        <>
            <h2>Account Upgrade Requests</h2>
            <Modal open={ application?.id ? true : false }
                className="modal-parent">
                <Box className="modal-box" sx={ { maxWidth: "30rem" } }>
                    <ManagementApplicationModal application={ application } handleClose={ () => setApplication( {} ) } updateHandler={ updateHandler }  />
                </Box>
            </Modal>
            <ManagementApplicationTable 
                applications={ applications } 
                applicationPagination={ applicationPagination }
                fetchTableData={ fetchApplications }
                rowClickHandler={ rowClickHandler } />
        </>
    )
}

export default ManagementApplicationSystem;