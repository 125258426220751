/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom"
import './MessageNavigate.scss';
import { useMemo } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
// import { fetchMessages } from "../../store/messagesSlice";

const MessageNavigate = ( { messages = [] } ) => {
    // const user = useSelector( state => state.user.value )
    // // const messages = useSelector( state => state.messages.value )
    // const dispatch = useDispatch()

    // useEffect( () => {
    //     if ( user.id ) dispatch( fetchMessages() )
    // }, [ user ] )

    const notification = useMemo( () => {
        let _notification = 0
        messages.forEach( message => {
            if ( message.seen === 0 ) _notification += 1
        } )

        return _notification
    }, [ messages ] )

    return (
        <Link className="message-navigate-button fd" to={"/message"}>
            {
                notification > 0 && 
                <div className="new-messages-notification">
                    { notification }
                </div>
            }
            <FontAwesomeIcon className="message-icon" icon={ solid('message') } />
        </Link>
    )
}

export default MessageNavigate;