/* eslint-disable react-hooks/exhaustive-deps */
// import { Check, Visibility, VisibilityOff } from "@mui/icons-material";
// import { Button, Dialog, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, ToggleButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import CompanyLinksTable from "./CompanyLinksTable";
import ClientsPasswordDialog from "./ClientsPasswordDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ToastDataContext } from "../../UnderRoot";
import { getHeader } from "../../../shared/Authorization";
import axios from "axios";
import { ErrorHandler } from "../../../shared/CustomFunc";
import { useGetBrands } from "../../../useHooks/useBrands";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ROOT_API_URL = process.env.REACT_APP_API_URL;

const ClientsModalInfo = ({
  children,
  client,
  sellers,
  addClientHandler,
  updateClientHandler,
  addCompanyLinksHandler,
  updateClientLinkHandler,
  removeClientLinkHandler,
}) => {
  // const [ current_client, setCurrentClient ] = useState( "none" );
  const user = useSelector((state) => state.user.value);
  const { customToast } = useContext(ToastDataContext);

  const [email, setClientEmail] = useState("");
  const [name, setClientName] = useState("");
  const [third_party_id, setThirdPartyId] = useState("");
  const [default_unit_cost, setDefaultUnitCost] = useState(0);
  const [phone, setClientPhone] = useState("");
  const [access, setAccess] = useState(0);
  const [sent_offer, setSentOffer] = useState(0);
  const [company_links, setCompanyLinks] = useState([]);
  const [password, setPassword] = useState("");
  const [showPassword, setShow] = useState(false);
  const [seller_id, setSellerId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [industry_id, setClientIndustry] = useState("");
  const [source_id, setClientSource] = useState(2);
  const [brand_code, setClientBrand] = useState("");

  useEffect(() => {
    if (password) setPassword("");
    if (client?.id === "none") {
      // setCurrentClient( "none" )
      setClientEmail("");
      setClientName("");
      setThirdPartyId("");
      setClientPhone("");
      setSentOffer(0);
      setCompanyLinks([]);
      setRemarks("");
      setClientIndustry("");
      setClientSource(2);
      setAccess(0);

      setDefaultUnitCost(0);
    } else {
      // setCurrentClient( client?.id )
      setClientEmail(client?.email);
      setClientName(client?.name);
      setThirdPartyId(client?.third_party_id || "");
      setClientPhone(client?.phone || "");
      setSentOffer(Number(client?.sent_offer || 0));
      setCompanyLinks(client?.companies || []);
      setRemarks(client?.remarks || "");
      setClientIndustry(
        client?.industry_id ? parseInt(client.industry_id) : ""
      );
      setClientSource(client?.source_id ? parseInt(client.source_id) : 2);
      setClientBrand(client?.brand?.code);

      const _access = isNaN(client?.access) ? 0 : Number(client.access);

      setAccess(_access);

      setDefaultUnitCost(Number(client?.default_unit_cost || 0));
    }
  }, [client]);

  const companyLinksSubmit = (company_link) => {
    if (client?.id === "none") {
      const id =
        company_links.length > 0
          ? company_links[company_links?.length - 1]?.id + 1
          : 0;

      setCompanyLinks([...company_links, { id, ...company_link }]);
    } else addCompanyLinksHandler([company_link], client?.id, true, true);
  };

  const updateLinksSubmit = async (link_id, data) => {
    if (client?.id === "none") {
      const updatedClientInput = company_links.map((_clientInput) => {
        if (link_id === _clientInput.id) {
          return {
            ..._clientInput,
            ...data,
          };
        }
        return _clientInput;
      });

      setCompanyLinks(updatedClientInput);
      return { ok: true };
    } else return await updateClientLinkHandler(link_id, data);
  };

  const removeLinksSubmit = async (link_id) => {
    if (client?.id === "none") {
      const _company_links = company_links.filter(
        (_client_link) => _client_link.id !== link_id
      );

      setCompanyLinks(_company_links);
    } else await removeClientLinkHandler(link_id);
  };

  const [submitSpinner, setSubmitSpinner] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!name) {
      customToast("Client email must not be empty");
      return;
    }

    if (!email) {
      customToast("Client email must not be empty");
      return;
    }

    if (client.id === "none" && !brand_code) {
      customToast("Brand code must not be empty");
      return;
    }

    const _client = { name, email };

    _client.third_party_id = third_party_id || "";
    _client.phone = phone || "";
    _client.default_unit_cost = default_unit_cost || 0;
    _client.remarks = remarks || "";
    _client.sent_offer = sent_offer || 0;
    _client.industry_id = industry_id || "";
    _client.source_id = source_id || 1;

    // if ( third_party_id !== client?.third_party_id )
    // if ( phone !== client?.phone ) if ( phone )
    // if ( default_unit_cost !== client?.default_unit_cost ) if ( default_unit_cost )

    setSubmitSpinner(true);

    if (client.id === "none") {
      if (password) _client.password = password;

      _client.brand_code = brand_code;

      if (user?.role !== 3) {
        if (!seller_id) {
          customToast("Must select a seller for this client");
          setSubmitSpinner(false);
          return;
        }

        _client.seller_id = seller_id;
      }

      await addClientHandler(_client, company_links);
    } else {
      if (
        name === client?.name &&
        email === client?.email &&
        phone === client?.phone &&
        third_party_id === client?.third_party_id &&
        default_unit_cost === client?.default_unit_cost &&
        industry_id === client?.industry_id &&
        source_id === client?.source_id
      ) {
        // && sent_offer === Number( client?.sent_offer ) ) {
        customToast("No change detected");
        return;
      }
      await updateClientHandler(_client);
    }

    setSubmitSpinner(false);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const [accessSpinner, setAcessSpinner] = useState(false);

  const userAccessHandler = async () => {
    const _client = { name, email };

    if (third_party_id) _client.third_party_id = third_party_id;
    if (phone) _client.phone = phone;

    const new_access = (_client.access = access === 0 ? 1 : 0);

    setAcessSpinner(true);

    await updateClientHandler(_client, true, { access: new_access });

    customToast("Successfully updated client", "success");

    setAcessSpinner(false);
  };

  const [industries, setIndustries] = useState([]);

  const fetchIndustries = async () => {
    const headers = getHeader();

    await axios
      .get(ROOT_API_URL + "/client/industries", { headers })
      .then((resp) => {
        setIndustries(resp.data.industries);
        return { ok: true };
      })
      .catch((err) => {
        console.log(err);
        return ErrorHandler(err, customToast, "Error fetching industries");
      });
  };

  const [sources, setSources] = useState([]);

  const fetchSources = async () => {
    const headers = getHeader();

    await axios
      .get(ROOT_API_URL + "/client/sources", { headers })
      .then((resp) => {
        setSources(resp.data.client_sources);
        return { ok: true };
      })
      .catch((err) => {
        console.log(err);
        return ErrorHandler(err, customToast, "Error fetching origin list");
      });
  };

  useEffect(() => {
    fetchIndustries();
    fetchSources();
  }, []);

  const { data: brands } = useGetBrands();

  const industrySelectHandler = (id) => {
    const industry = industries?.find((_industry) => _industry.id === id);

    if (!industry?.id) {
      setClientIndustry("");
      return;
    }

    setClientIndustry(industry.id);
  };

  const sourceSelectHandler = (id) => {
    const foundSource = sources?.find((_source) => _source.id === id);

    if (!foundSource?.id) {
      setClientSource(2);
      return;
    }

    setClientSource(foundSource.id);
  };

  const brandSelectHandler = (code) => {
    const foundBrand = brands?.find((_brand) => _brand.code === code);

    if (!foundBrand?.code) {
      setClientBrand(2);
      return;
    }

    setClientBrand(foundBrand.code);
  };

  const handleLoginClient = () => {
    if (!window.confirm(`Login using ${client?.name}'s account?`)) return;

    const CUSTOMER_LOGIN_URL = process.env.REACT_APP_CUSTOMER_LOGIN_URL;
    const data = window.btoa(client?.email);
    window.open(CUSTOMER_LOGIN_URL + `/${data}`);
  };

  return (
    <Box sx={{ display: "flex", flexFlow: "column nowrap", gap: "2rem" }}>
      <div className="clients-modal-info">
        <div>
          <TextField
            id="client_email"
            label="CLIENT EMAIL"
            variant="standard"
            value={email}
            onChange={(e) => setClientEmail(e.target.value)}
          />
          <TextField
            id="client_name"
            label="CLIENT NAME"
            variant="standard"
            value={name}
            onChange={(e) => setClientName(e.target.value)}
          />
        </div>
        <div>
          <TextField
            id="third_party_id"
            label="3RD PARTY ID"
            variant="standard"
            value={third_party_id}
            onChange={(e) => setThirdPartyId(e.target.value)}
          />
          <div
            className="fd phone"
            style={{ alignItems: "center", gap: "1rem" }}
          >
            <TextField
              id="client_phone"
              label="CLIENT PHONE"
              variant="standard"
              style={{ flex: 1 }}
              value={phone}
              onChange={(e) => setClientPhone(e.target.value)}
            />
            {phone && (
              <a href={`tel:${phone}`} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={solid("phone")} />
              </a>
            )}
          </div>
        </div>
        <div>
          <FormControl variant="standard">
            <InputLabel id="customer_industry">INDUSTRY</InputLabel>
            <Select
              labelId="customer_industry"
              variant="standard"
              // label="INDUSTRY"
              value={industry_id}
              onChange={(e) => industrySelectHandler(e.target.value)}
            >
              {industries.map((industry, i) => (
                <MenuItem key={i} value={industry.id}>
                  {" "}
                  {industry.name.toUpperCase()}{" "}
                </MenuItem>
              ))}
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="client_source">CLIENT ORIGIN</InputLabel>
            <Select
              labelId="client_source"
              variant="standard"
              value={source_id}
              onChange={(e) => sourceSelectHandler(e.target.value)}
            >
              {sources.map((_source, i) => (
                <MenuItem key={i} value={_source.id}>
                  {" "}
                  {_source.name.toUpperCase()}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {
          // this means we are adding new client
          client?.id === "none" ? (
            <>
              <div>
                <TextField
                  sx={{
                    display: "block",
                  }}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  label="PASSWORD"
                  variant="standard"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShow(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {
                  // for new clients
                  // if user is already a seller we don't need to make them select a seller
                  // therefore we just let them choose the sent offer here
                  user?.role === 3 ? (
                    <div className="fd sent-offer-field">
                      <p>SENT OFFER:</p>
                      <Switch
                        checked={sent_offer === 1 ? true : false}
                        onChange={() => setSentOffer(sent_offer === 1 ? 0 : 1)}
                      />
                    </div>
                  ) : (
                    <FormControl variant="standard">
                      <InputLabel id="seller_select">SELLER</InputLabel>
                      <Select
                        labelId="seller_select"
                        label="SELLER"
                        variant="standard"
                        value={seller_id}
                        onChange={(e) => setSellerId(e.target.value)}
                      >
                        {sellers.map((seller, sellerI) => (
                          <MenuItem value={seller.id} key={sellerI}>
                            {seller.email}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )
                }
              </div>
              <div>
                <FormControl variant="standard" sx={{ mb: "2rem" }}>
                  <InputLabel id="client_brand" sx={{ p: 0 }}>
                    BRAND
                  </InputLabel>
                  <Select
                    labelId="client_brand"
                    variant="standard"
                    value={brand_code ?? ""}
                    onChange={(e) => brandSelectHandler(e.target.value)}
                  >
                    {brands.map((brand, i) => (
                      <MenuItem key={i} value={brand.code}>
                        {" "}
                        {brand.name.toUpperCase()}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <TextField
                  id="unit_price"
                  label="UNIT PRICE"
                  variant="standard"
                  type="number"
                  value={default_unit_cost}
                  onChange={(e) => setDefaultUnitCost(e.target.value)}
                />
                {
                  // for new clients
                  // if the user is not a seller, then we display the sent offer here
                  // since the position above is occupied by seller dropdown
                  user?.role !== 3 && (
                    <div className="fd sent-offer-field">
                      <p>SENT OFFER:</p>
                      <Switch
                        checked={sent_offer === 1 ? true : false}
                        onChange={() => setSentOffer(sent_offer === 1 ? 0 : 1)}
                      />
                    </div>
                  )
                }
              </div>
              {
                // if the user is a seller, we can let them remark on a new client
                user?.role === 3 && (
                  <div>
                    <TextField
                      id="remarks"
                      label="CLIENT REMARKS"
                      variant="standard"
                      type="text"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </div>
                )
              }
            </>
          ) : (
            // we are opening an existing client on the modal
            <>
              <div>
                <TextField
                  id="unit_price"
                  label="UNIT PRICE"
                  variant="standard"
                  type="number"
                  value={default_unit_cost}
                  onChange={(e) => setDefaultUnitCost(e.target.value)}
                />
                <div className="fd sent-offer-field">
                  <p>SENT OFFER:</p>
                  <Switch
                    checked={sent_offer === 1 ? true : false}
                    onChange={() => setSentOffer(sent_offer === 1 ? 0 : 1)}
                  />
                </div>
              </div>
              {
                // if the user is a seller, we can let them remark on an existing client
                user?.role === 3 && (
                  <div>
                    <TextField
                      id="remarks"
                      label="CLIENT REMARKS"
                      variant="standard"
                      type="text"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </div>
                )
              }
            </>
          )
        }
      </div>

      {client?.id !== "none" && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "1rem",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Button
            type="button"
            variant="contained"
            className="-green"
            sx={{ minWidth: "8rem", color: "white", whiteSpace: "nowrap" }}
            onClick={() =>
              window.open(
                BASE_URL + `/task?clientEmail=${email}`,
                "_blank"
                // "rel=noopener noreferrer"
              )
            }
          >
            Create Task
            {/* <Link 
                            to={`/task?clientEmail=${email}`}
                            target="_blank" rel="noopener noreferrer"
                        >
                            Create Task
                        </Link> */}
          </Button>
          <Button
            type="button"
            variant="contained"
            // className="-blue"
            sx={{ minWidth: "8rem", whiteSpace: "nowrap" }}
            onClick={() =>
              window.open(
                BASE_URL +
                  `/dashboard?clientEmail=${email}&clientId=${client?.id}&clientName=${name}&phone=${phone}&3rdPartyId=${third_party_id}&unitCost=${default_unit_cost}`,
                "_blank"
                // "rel=noopener noreferrer"
              )
            }
          >
            Add New Order
            {/* <Link 
                            to={`/dashboard?clientEmail=${email}&clientId=${client?.id}&clientName=${name}&phone=${phone}&3rdPartyId=${third_party_id}&unitCost=${default_unit_cost}`}
                            target="_blank" rel="noopener noreferrer"
                        >
                            Add New Order
                        </Link> */}
          </Button>

          <Button
            type="button"
            variant="contained"
            className="-orange"
            sx={{ whiteSpace: "nowrap" }}
            onClick={() => setDialogOpen(true)}
          >
            Generate New Password
          </Button>
          {accessSpinner ? (
            <FidgetSpinner
              fidgetClass={` -btn ${
                access === 0 ? "-green" : "-red"
              } -activate-btn`}
              style={{ width: "100%", maxWidth: "9rem" }}
            />
          ) : (
            <Button
              variant="contained"
              className={`${access === 0 ? "-green" : "-red"} -activate-btn`}
              onClick={userAccessHandler}
              sx={{ whiteSpace: "nowrap", minWidth: "9rem" }}
            >
              {access === 0 ? "ACTIVATE USER" : "DEACTIVATE USER"}
            </Button>
          )}
        </Box>
      )}

      <CompanyLinksTable
        existing={client?.id === "none" ? false : true}
        company_links={company_links}
        companyLinksSubmit={companyLinksSubmit}
        updateLinksSubmit={updateLinksSubmit}
        removeLinksSubmit={removeLinksSubmit}
      />
      <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
        <Box className="dialog-box">
          <ClientsPasswordDialog
            client={client}
            handleClose={() => setDialogOpen(false)}
          />
        </Box>
      </Dialog>
      <Box
        className="fd"
        sx={{
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          gap: "1rem",
        }}
      >
        {children}

        <Box
          className="fd"
          sx={{
            gap: "1rem",
            width: "100%",
            justifyContent: "flex-end",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {client?.id !== "none" && user?.role === 1 && (
            <Button
              type="button"
              variant="contained"
              sx={{
                maxWidth: { xs: "unset", md: "15rem" },
                width: "100%",
                whiteSpace: "nowrap",
                display: "flex",
                gap: "0.5rem",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleLoginClient}
            >
              Login to Client
            </Button>
          )}

          {submitSpinner ? (
            <FidgetSpinner
              fidgetClass="-btn"
              style={{ maxWidth: { xs: "unset", md: "10rem" }, width: "100%" }}
            />
          ) : (
            <Button
              type="button"
              sx={{ maxWidth: { xs: "unset", md: "10rem" }, width: "100%" }}
              variant="contained"
              onClick={submitHandler}
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ClientsModalInfo;
