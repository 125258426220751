/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { getHeader } from "../../../shared/Authorization"
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner"
import { ToastDataContext } from "../../UnderRoot"
import './ClientsPasswordDialog.scss'

const ROOT_URL = process.env.REACT_APP_API_URL

const ClientsPasswordDialog = ( { client={}, handleClose=()=>"" } ) => {
    const { customToast } = useContext( ToastDataContext )

    const [ passwordStage, setPasswordStage ] = useState( 0 )
    const [ newPassword, setNewPassword ] = useState("")

    const [ generateSpinner, setGenerateSpinner ] = useState( false )
    const [ sendSpinner, setSendSpinner ] = useState( false )

    const generateHandler = async () => {
        const headers = getHeader()

        setGenerateSpinner( true )

        axios.put( ROOT_URL + '/client/generate-passcode/' + client.id, {}, { headers }  )
        .then( resp => {
            setGenerateSpinner( false )
            setNewPassword( resp.data.password_text )
        } )
        .catch( err => {
            console.log( err )
            setGenerateSpinner( false )
            customToast( "Error generating password" )
        } )
    }

    useEffect( () => {
        if ( newPassword && passwordStage === 0 ) {
            setPasswordStage( 1 )
        } 
    }, [ newPassword ] )

    const sendEmailHandler = () => {
        const headers = getHeader()

        setSendSpinner( true )

        axios.post( ROOT_URL + '/client/send-email/' + client.id, { password: newPassword }, { headers } )
        .then( () => {
            setNewPassword("")
            setPasswordStage( 2 ) 
            setSendSpinner( false )
        } )
        .catch( err => {
            console.log( err )
            customToast( "Error sending email" )
            setSendSpinner( false )
        } )
    }

    const closeHandler = () => {
        handleClose()
    }

    return (
        <div className="client-password-dialog">
            {
                passwordStage === 0 &&
                <>
                    <p>Are you sure you want to generate a password for this user?</p>
                    <div className="dialog-base-btns fd">
                        {
                            generateSpinner ? 
                            <FidgetSpinner fidgetClass="-btn" style={ { width: "8rem" } } /> :
                            <Button variant="contained" sx={ { width: "8rem" } } onClick={ generateHandler }>
                                Generate
                            </Button>

                        }
                        <Button variant="contained" className="-gray" sx={ { width: "8rem" } } onClick={ handleClose }>
                            Cancel
                        </Button>
                    </div>
                </>
            }
            {
                passwordStage === 1 &&
                <>
                    <p>New password generated. Send to { client?.email }</p>
                    <p className="password-generated">{ newPassword }</p>
                    <div className="dialog-base-btns fd">
                        {
                            sendSpinner ?
                            <FidgetSpinner fidgetClass="-btn" style={ { width: "8rem" } } /> :
                            <Button 
                                variant="contained" 
                                sx={ { width: "8rem" } } 
                                onClick={ sendEmailHandler }>
                                Send
                            </Button>
                        }
                        <Button variant="contained" className="-gray" sx={ { width: "8rem" } } onClick={ handleClose }>
                            Cancel
                        </Button>
                    </div>
                </>
            }
            {
                passwordStage === 2 &&
                <>
                    <p className="confirm-access">Access sent to { client?.email }</p>
                    <div className="dialog-base-btns fd">
                        <Button 
                            variant="contained" 
                            sx={ { width: "8rem", marginLeft: "auto", marginTop: "1.5rem" } } 
                            onClick={ closeHandler }>
                                Close
                        </Button>
                    </div>
                </>
            }
        </div>
    )
}

export default ClientsPasswordDialog