import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data, height='20rem', displayLegend=true }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // scales: {
    //   x: {
    //     beginAtZero: true,
    //     // max: 5,
    //     ticks: {
    //       autoSkip: true,
    //       maxTickLimit: 5,
    //     },
    //   },
    //   y: {
    //     grid: {
    //       display: false
    //     },
    //   },
    // },
    // elements: {
    //   bar: {
    //     borderWidth: 2,
    //   },
    // },
    plugins: {
      legend: {
        display: displayLegend,
      },
      title: {
        display: false,
      },
    },
  };

  return(
    <div style={{ height }}>
      <Pie options={options} data={data} />
    </div>
  );
}

export default PieChart;