import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ToastDataContext } from "../../UnderRoot"
import TextArray from "../../../sharedComponent/TextArray"

const UserManagementModalTransfer = ({ handleClose, handleTransferOrder }) => {
    const { customToast } = useContext( ToastDataContext );
    const isLargeDisplay = useMediaQuery('(min-width:900px)');

    // emails/orders that will be transferred
    const [ transferredEmails, setTransferredEmails ] = useState([]);
    // the email that we will transfer the orders to
    const [ email, setEmail ] = useState('');

    const [ isEditingEmails, setIsEditingEmails ] = useState( false );

    const handleEditingEmails = val => setIsEditingEmails( val )

    const addEmail = emailToAdd => {
        setTransferredEmails( [ ...transferredEmails, emailToAdd ] )
    }

    const deleteAlternate = email => {
        const filteredEmails = transferredEmails.filter( _email => _email !== email )

        setTransferredEmails( filteredEmails )
    }

    return (
        <Box sx={{ display: 'flex', flexFlow: 'column nowrap', gap: '1rem' }}>
            <Typography component='h2' fontSize='1.5rem'>Transfer Orders</Typography>
            <Box sx={{ display: 'grid', gap: '2rem', gridTemplateColumns: isLargeDisplay ? '1fr 36px 1fr' : 'minmax(0, 1fr)' }}>
                <Box>
                    <InputLabel 
                        htmlFor="order_transfer_emails"
                        sx={{ display: 'flex', gap: '0.5rem' }}>
                            <Box sx={{ height: '24px', width: '24px', border: '2px solid', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>1</Box>
                            <Typography component={'span'} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                Emails to transfer
                            </Typography>
                    </InputLabel>
                    <TextArray 
                        id="order_transfer_emails" 
                        boxStyle="standard"
                        arrayValue={ transferredEmails }
                        handleAdd={ addEmail }
                        handleDelete={ deleteAlternate }
                        handleError={ customToast }
                        isEditing={ isEditingEmails }
                        handleIsEditing={ handleEditingEmails } />
                    <Typography 
                        component={'span'}
                        sx={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '0.7rem' }}>* Press enter or spacebar after typing email to add</Typography>   
                    <Typography 
                        component={'span'}
                        sx={{ color: '#FF0000', fontSize: '0.7rem', display: 'block' }}>* All accounts added will be archived after successful submission</Typography>        
                </Box>
                <Box sx={{ 
                    display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: isLargeDisplay ? '-2rem' : undefined }}>
                    { isLargeDisplay
                        ? <ArrowForwardIcon
                            sx={{ width: '3rem', height: '3rem', color: '#30A8FF' }} />
                        : <ArrowDownwardIcon 
                            sx={{ width: '3rem', height: '3rem', color: '#30A8FF' }}/>
                    }
                </Box>
                <Box sx={{ marginBottom: '0.5rem' }}>
                    <InputLabel 
                        htmlFor="new_seller_email"
                        sx={{ display: 'flex', gap: '0.5rem', marginBottom: '1rem' }}>
                            <Box sx={{ height: '24px', width: '24px', border: '2px solid', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>2</Box>
                            <Typography component={'span'} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                New seller email
                            </Typography>
                    </InputLabel>
                    <TextField 
                        fullWidth
                        id='new_seller_email'
                        variant='standard'
                        value={email}
                        sx={{ padding: '4px 4px 5px' }}
                        onChange={(e) => setEmail(e.target.value)}/>
                    <Box sx={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                        <Button sx={{ color: 'white', padding: '0 1rem' }}
                            type='submit'
                            fullWidth
                            onClick={() => handleTransferOrder(transferredEmails, email)}>
                                Submit
                        </Button>
                        <Button className='-gray' fullWidth variant="contained" type='button'
                            onClick={() => handleClose()}>
                                Close
                        </Button>
                    </Box>
                </Box>
            </Box>
           
        </Box>
    )
}

export default UserManagementModalTransfer