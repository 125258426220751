import { useState, useContext, useEffect }  from 'react'
import axios from "axios"

import useInputSearch from './useInputSearch';
import { ToastDataContext } from '../component/UnderRoot';
import { getHeader } from '../shared/Authorization';
import useClients from './useClients';

const ROOT_URL = process.env.REACT_APP_API_URL;

const useReports = () => {
  const { customToast } = useContext( ToastDataContext );

  const [ seller_email, setSellerEmail ] = useState( "" );
  const [ seller_email_selected, setSellerEmailSelected ] = useState("");

  const { items:sellers, searchFunc: fetchSellers } = useInputSearch('/seller/search/');
  const { getClientsStats } = useClients();

  useEffect(() => {
    if ( seller_email ) fetchSellers( seller_email );
  }, [seller_email, fetchSellers]);

  useEffect( () => {
    if ( seller_email && sellers.length ) {
      const foundSeller = sellers.find( _seller => _seller?.email.toLowerCase() === seller_email.toLowerCase() )

      if ( foundSeller ) {
        setSellerEmailSelected( foundSeller.email )
      }
    }
  }, [ seller_email, sellers ] )

  const [ revenueReportStats, setRevenueReportStats ] = useState( [] );
  const [ revenueReportCharts, setRevenueReportCharts ] = useState ( { paid_counts: {}, paid_amounts: {}} )
  const [ orderReportStats, setOrderReportStats ] = useState( [] );
  const [ orderReportCharts, setOrderReportCharts ] = useState( { orders: {}, paid_orders: {}, order_payment_status: [], order_review_status: [] } );
  const [ clientStats, setClientStats ] = useState( { logged_clients: 0, new_clients: 0, total_clients: 0 } )
  const [ clientsReportCharts, setClientsReportCharts ] = useState ( { new_clients: {}, total_clients: {}, client_origin: [], client_industry: [], sellers: [] } )

  const fetchRevenueStats = (params, notify = true) => {
    const headers = getHeader();

    if (seller_email_selected) {
      const foundSeller = sellers.find( _seller => _seller?.email.toLowerCase() === seller_email.toLowerCase() )

      if ( foundSeller ) {
          params.seller_id = foundSeller.id
      }
    }

    notify && customToast( "Updating data", "info" )

    axios.get( ROOT_URL + '/seller_revenue', { headers, params } )
    .then( resp => {
        notify && customToast( "Updated data successfully.", "success" )
        
        const revenue_reports = resp.data.seller_reports
            .map((report) => ({
                ...report,
                total_revenue: parseFloat(report?.total_revenue),
                average_price: parseFloat(report?.average_price),
                average_customer_value: parseFloat(report?.average_customer_value),
            }));
        
        setRevenueReportStats( revenue_reports )
    } )
    .catch( err => {
        console.log( err )
        customToast("Error fetching reports")
    } )
  }

  const fetchRevenuePaid = (params) => {
    const headers = getHeader();

    if (seller_email_selected) {
      const foundSeller = sellers.find( _seller => _seller?.email.toLowerCase() === seller_email.toLowerCase() )

      if ( foundSeller ) {
        params.seller_id = foundSeller.id
      }
    }

    axios.get( ROOT_URL + '/seller_revenue/graph', { headers, params } )
    .then( resp => {            
      const paidCountChart = resp.data?.paid_counts
      const paidAmountChart = resp.data?.paid_amounts
      
      setRevenueReportCharts( {
        paid_counts: paidCountChart,
        paid_amounts: paidAmountChart
      })
    })
    .catch( err => {
        console.log( err )
        customToast("Error fetching reports")
    })
  }

  const fetchOrderStats = (params, notify = true) => {
    const headers = getHeader();

    if (seller_email_selected) {
      const foundSeller = sellers.find( _seller => _seller?.email.toLowerCase() === seller_email.toLowerCase() )

      if ( foundSeller ) {
        params.seller_id = foundSeller.id
      }
    }

    notify && customToast( "Updating data", "info" )

    axios
    .get( ROOT_URL + '/order_report', { headers, params } )
    .then( resp => {
      notify && customToast( "Updated data successfully.", "success" )      
      setOrderReportStats( resp.data.order_reports )
    })
    .catch( err => {
      console.log( err )
      customToast("Error fetching reports")
    })
  }

  const fetchOrderCount = (params) => {
    const headers = getHeader();

    if (seller_email_selected) {
      const foundSeller = sellers.find( _seller => _seller?.email.toLowerCase() === seller_email.toLowerCase() )

      if ( foundSeller ) {
        params.seller_id = foundSeller.id
      }
    }

    axios
    .get( ROOT_URL + '/order_report/graph', { headers, params } )
    .then( resp => {            
      const orders = resp.data?.orders
      const paid_orders = resp.data?.paid_orders
      
      setOrderReportCharts(prev => ({
        ...prev,
        orders,
        paid_orders
      }))
    })
    .catch( err => {
      console.log( err )
      customToast("Error fetching chart data")
    })
  };

  const fetchOrderPaymentAndReviewStatus = (params) => {
    const headers = getHeader();

    if (seller_email_selected) {
      const foundSeller = sellers.find( _seller => _seller?.email.toLowerCase() === seller_email.toLowerCase() )

      if ( foundSeller ) {
        params.seller_id = foundSeller.id
      }
    }

    axios
    .get( ROOT_URL + '/order_report/pie-graph', { headers, params } )
    .then( resp => {            
      const order_payment_status = resp.data?.order_payment_status
      const order_review_status = resp.data?.order_review_status

      setOrderReportCharts(prev => ({
        ...prev,
        order_payment_status,
        order_review_status
      }))
    })
    .catch( err => {
      console.log( err )
      customToast("Error fetching chart data")
    })
  };

  const fetchClientStats = async () => {
      const status = await getClientsStats()

      if ( status.ok ) {
          setClientStats(status.stats)
      } else customToast( "Error fetching stats" )
  }

  const fetchClientCount = (params) => {
    const headers = getHeader();

    if (seller_email_selected) {
      const foundSeller = sellers.find( _seller => _seller?.email.toLowerCase() === seller_email.toLowerCase() )

      if ( foundSeller ) {
        params.seller_id = foundSeller.id
      }
    }

    axios.get( ROOT_URL + '/client/client_report', { headers, params } )
    .then( resp => {            
      setClientsReportCharts(prev => ( {
        ...prev,
        new_clients: resp.data?.new_clients,
        total_clients: resp.data?.total_clients
      }))
    })
    .catch( err => {
        console.log( err )
        customToast("Error fetching client count")
    })
  }

  const fetchClientPercentage = (params) => {
    const headers = getHeader();

    if (seller_email_selected) {
      const foundSeller = sellers.find( _seller => _seller?.email.toLowerCase() === seller_email.toLowerCase() )

      if ( foundSeller ) {
        params.seller_id = foundSeller.id
      }
    }

    axios.get( ROOT_URL + '/client/client_report/pie-chart', { headers, params } )
    .then( resp => {            
      setClientsReportCharts(prev => ( {
        ...prev,
        client_origin: resp.data?.groupedBy_origin,
        client_industry: resp.data?.groupedBy_industry,
        sellers: resp.data?.groupedBy_seller
      }))
    })
    .catch( err => {
        console.log( err )
        customToast("Error fetching client count")
    })
  }

  return { 
    sellers,
    seller_email,
    seller_email_selected,
    setSellerEmail,
    setSellerEmailSelected,
    fetchRevenueStats,
    fetchRevenuePaid,
    fetchOrderStats,
    fetchOrderCount,
    fetchOrderPaymentAndReviewStatus,
    fetchClientStats,
    fetchClientCount,
    fetchClientPercentage,
    revenueReportStats,
    revenueReportCharts,
    orderReportStats, 
    orderReportCharts,
    clientStats,
    clientsReportCharts,
  }

}

export default useReports