/* eslint-disable react-hooks/exhaustive-deps */
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Modal,
  Box,
  Select,
  MenuItem,
  TablePagination,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { DataContext } from "./ManagementSystem";
import ManagementOrderModal from "./ManagementOrderModal";
import "./ManagementTable.scss";
import { FunctionContext } from "../Main";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import { createdByChecker } from "../../../shared/CustomFunc";
import { useSelector } from "react-redux";
import { useUpdatePaymentStatus } from "../../../useHooks/useOrders";

const TABLE_HEAD = [
  {
    label: "DATE",
  },
  {
    label: "ORDER ID",
  },
  {
    label: "CREATED BY",
  },
  {
    label: "COMPANY NAME",
  },
  {
    label: "PRICE",
  },
  {
    label: "REVIEWS",
  },
  {
    label: "PAYMENT STATUS",
  },
  {
    label: "REMARKS",
  },
  // {
  //     label: "ACTIONS"
  // }
];

const KEYS = [
  "created_at",
  "id",
  "created_by",
  "company",
  "total_price",
  "order_review",
  "payment_status",
  "remarks",
];

// const orderStatusDropDown = [
//     {
//         value: 0,
//         label: "New"
//     }, {
//         value: 1,
//         label: "Seen"
//     }, {
//         value: 2,
//         label: "Preparing"
//     }, {
//         value: 3,
//         label: "Finished" }
// ]

const PAYMENT_STATUS_DROPDOWN = [
  {
    value: 0,
    label: "New",
  },
  {
    value: 1,
    label: "Sent Invoice",
  },
  {
    value: 2,
    label: "Paid",
  },
  {
    value: 3,
    label: "Unpaid",
  },
  {
    value: 11,
    label: "Payment Reminder 1",
  },
  {
    value: 12,
    label: "Payment Reminder 2",
  },
];

const ManagementTable = ({ ordersLoading = true, params }) => {
  const { mutateAsync: updatePaymentStatus } = useUpdatePaymentStatus();

  // function context
  const user = useSelector((state) => state.user.value);

  const { dateStringify, modalOpen, numberWithCommas } =
    useContext(FunctionContext);

  // function context

  const { orders, fetchTableData } = useContext(DataContext);

  const [order, setOrder] = useState(null);
  const [updateOrderOpen, setUpdateOrderOpen] = useState(false);
  const [whichTab, setWhichTab] = useState(null);

  useEffect(() => {
    if (whichTab && !updateOrderOpen) setUpdateOrderOpen(true);
  }, [whichTab]);

  useEffect(() => {
    if (!updateOrderOpen && whichTab) setWhichTab(null);
  }, [updateOrderOpen]);

  useEffect(() => modalOpen(updateOrderOpen), [updateOrderOpen]);

  const handleUpdateOrderOpen = (id) => {
    // find order and set it
    if (!orders?.data.length) return;

    const orderFound = orders?.data.find(
      (_order) => Number(_order.id) === Number(id)
    );

    if (orderFound) setOrder(orderFound);
  };
  const handleUpdateOrderClose = () => setOrder(null);

  useEffect(() => {
    if (order?.id) setUpdateOrderOpen(true);
    else setUpdateOrderOpen(false);
  }, [order]);

  // const handleDeleteOrder = ( product_id ) => {
  //     const deletOrder = toast.success("ARE YOU SURE YOU WANT TO DELETE THIS ORDER?")

  //     if ( !deletOrder ) return

  //     const headers = getHeader()

  //     axios.delete( `${rootUrl}/order/${product_id}`, { headers } )
  //     .then( () => {
  //         toast.success( "Order deleted!" )
  //         fetchTableData()
  //     } )
  //     .catch( err => ErrorHandler( err, customToast, "Error deleting order." ) )
  // }

  // const handleOrderUpdate = ( newStatus, id, successCall=null ) => {
  //     // update here
  //     const url = rootUrl + `/order/order_status/${id}`

  //     const headers = getHeader()

  //     axios.put( url, { order_status: newStatus }, { headers } )
  //     .then( () => {
  //         toast.success("Order updated!")
  //         if ( successCall ) successCall();
  //     } )
  //     .catch( err => ErrorHandler( err, customToast, "Error updating order." ) )
  // }

  const handlePaymentUpdate = (newStatus, id, successCall = null) => {
    // update here
    // const url = ROOT_URL + `/order/payment_status/${id}`;

    // const headers = getHeader();

    // axios
    //   .put(url, { payment_status: newStatus }, { headers })
    //   .then(() => {
    //     customToast("Order updated!", "success");
    //     queryClient.invalidateQueries(["orders"]);
    //     // fetchTableData(false, orders?.current_page);
    //   })
    //   .catch((err) => ErrorHandler(err, customToast, "Error updating order."));

    const payment_status = newStatus;
    updatePaymentStatus({ params, payload: { id, payment_status } });
  };

  const cellGenerator = (product, i, key, keyI) => {
    if (keyI === 0) {
      const dateDt = new Date(product?.[key]);

      return dateStringify(dateDt);
    }

    if (keyI === 2) {
      return createdByChecker(product?.[key]);
    }

    if (keyI === 3) {
      return product?.[key]?.name || "";
    }

    if (keyI === 4) {
      return numberWithCommas(product?.[key] || 0);
    }

    if (keyI === 5) {
      return (
        <span onClick={() => setWhichTab("2")}>{product?.[key]?.length}</span>
      );
    }

    if (keyI === 6) {
      let indicatorClass = "indicator-grey";

      const payment_status = Number(product?.[key]);

      if (payment_status === 1) indicatorClass = "indicator-yellow";
      if (payment_status === 2) indicatorClass = "indicator-green";
      if (payment_status === 3) indicatorClass = "indicator-orange";
      if (payment_status === 11) indicatorClass = "indicator-black";
      if (payment_status === 12) indicatorClass = "indicator-red";

      return (
        <>
          <span className={`payment-status-indicator ${indicatorClass}`}></span>
          {user?.role === 3 ? (
            <span style={{ fontWeight: "500" }}>
              {PAYMENT_STATUS_DROPDOWN.find(
                (paymentStatus) =>
                  paymentStatus.value === Number(product?.[key])
              )?.label || ""}
            </span>
          ) : (
            <Select
              id={`payment_status${i}`}
              label="PAYMENT STATUS"
              //   sx={{ minWidth: "max-content" }}
              fullWidth
              variant="standard"
              onClick={(e) => e.stopPropagation()}
              value={Number(product?.[key])}
              onChange={(e) =>
                handlePaymentUpdate(e.target.value, product.id, fetchTableData)
              }
            >
              {PAYMENT_STATUS_DROPDOWN.map((paymentStatus, paymentI) => (
                <MenuItem key={paymentI} value={paymentStatus.value}>
                  {paymentStatus.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      );
    }

    return product?.[key];
  };

  useEffect(() => {
    if (!order?.id) return;
    const foundOrder = orders?.data?.find((_order) => _order.id === order.id);
    if (!foundOrder) return;
    setOrder(foundOrder);
  }, [orders]);

  return (
    <>
      <Modal
        open={updateOrderOpen}
        onClose={handleUpdateOrderClose}
        className="modal-parent -order -not-empty"
      >
        <Box className="modal-box" sx={{ width: "950px" }}>
          <ManagementOrderModal
            open={updateOrderOpen}
            handleClose={handleUpdateOrderClose}
            order={order}
            dateStringify={dateStringify}
            fetchTableData={fetchTableData}
            whichTab={whichTab}
            current_page={orders?.current_page || 0}
          />
        </Box>
      </Modal>
      <TableContainer
        className="margin--top-standard management-table-container"
        component={Paper}
      >
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((head, i) => (
                <TableCell key={i} align="left">
                  {head.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersLoading ? (
              <TableRow>
                <TableCell colSpan={KEYS.length + 1}>
                  <FidgetSpinner fidgetClass="table-fidget" />
                </TableCell>
              </TableRow>
            ) : (
              orders?.data?.map((product, i) => (
                <TableRow
                  key={i}
                  className={"clickable-row"}
                  onClick={() => handleUpdateOrderOpen(product.id)}
                >
                  {KEYS.map((key, keyI) => {
                    const style =
                      keyI === 6
                        ? { display: "flex", alignItems: "center" }
                        : {};
                    return (
                      <TableCell key={keyI} align="left">
                        <Box sx={style}>
                          {cellGenerator(product, i, key, keyI)}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          rowsPerPage={10}
          showFirstButton
          showLastButton
          count={orders?.total || 0}
          page={orders?.current_page - 1 || 0}
          onPageChange={(e, newPage) => fetchTableData(false, newPage + 1)}
        />
      </TableContainer>
    </>
  );
};

export default ManagementTable;
