import { createContext } from 'react';
import { Outlet } from 'react-router-dom'
import './Main.scss';

const FunctionContext = createContext( );

const Main = () => {
    const dateStringify = date => {
        const month = ( date.getMonth() + 1 ) > 9 ? date.getMonth() + 1 : `0${ date.getMonth() + 1 }`
        const day = date.getDate() > 9 ? date.getDate() : `0${ date.getDate() }`

        return `${ date.getFullYear() }-${ month }-${ day }`
    }

    const modalOpen = open => {
        const root = document.querySelector("#root");
        const body = document.querySelector("body");

        if ( open && root.position !== "fixed" ) {
            root.style.position = "fixed"
            if ( !body?.className?.includes("-modal-open") ) body.classList.toggle("-modal-open")
        } else if ( !open && root.position !== "static" ) {
            root.style.position = "static"
            if ( body?.className?.includes("-modal-open") ) body.classList.toggle("-modal-open")
        }
    }

    const numberWithCommas = num => {
        const _num = `${num}`.split(".")

        return _num[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (_num?.[1] ? `.${_num[1].slice(0, 2)}` : "")
    };
    
    return (
        <FunctionContext.Provider
            value={ {
                dateStringify,
                modalOpen,
                numberWithCommas
            } }>    
            <main>
                <Outlet />
            </main>
        </FunctionContext.Provider>
    )
}

export default Main;
export { FunctionContext };