import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StarsIcons from "../clientSystem/StarsIcons";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";

const COLUMNS = [
  { label: "NAME" },
  { label: "RATING" },
  { label: "REVIEW" },
  { label: "ACTION" },
];

const ModalNegativeReviewsTable = ({
  lowestReviews,
  addReview,
  isFetching = false,
}) => {
  return (
    <TableContainer sx={{ maxHeight: "300px", overflow: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            {COLUMNS.map((col, index) => (
              <TableCell key={index}>{col.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {lowestReviews.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: "center" }} colSpan={4}>
                {isFetching ? (
                  <FidgetSpinner
                    fidgetStyle={{ width: "2rem", height: "2rem" }}
                  />
                ) : (
                  <>
                    No reviews found.
                    <br />
                    Please use valid Google Maps URL.
                  </>
                )}
              </TableCell>
            </TableRow>
          )}
          {lowestReviews.map((review, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{review.name}</TableCell>
                <TableCell>
                  <StarsIcons stars={review.rating} showLabels={false} />
                </TableCell>
                <TableCell>{review.description}</TableCell>
                <TableCell
                  sx={{
                    color: "#39a758",
                  }}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                    onClick={(e) =>
                      addReview(e, review.name, review.google_review_id)
                    }
                  >
                    Add Review
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ModalNegativeReviewsTable;
