/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import SectionWrapper from "../../../wrapper/SectionWrapper";
import "./ReportsSystem.scss";
import { Autocomplete, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import ReportsRevenue from "./ReportsRevenue";
import ReportsOrders from "./ReportsOrders";
import ReportsClients from "./ReportsClients";
import useReports from "../../../useHooks/useReports";
import { useNavigate, useLocation, Route, Routes, Navigate } from "react-router-dom";

dayjs.extend(utc);

// const views = {
//   revenue: ReportsRevenue,
//   orders: ReportsOrders,
//   clients: ReportsClients,
// };

const PATHS = [
  { path: "revenue", label: "Revenue" },
  { path: "orders", label: "Orders" },
  { path: "clients", label: "Clients" }
];


// const buttons = [{ name: "revenue" }, { name: "orders" }, { name: "clients" }];

const ReportsSystem = () => {
  const [dateFrom, setDateFrom] = useState(
    dayjs.utc().subtract(30, "day").startOf("day")
  );
  const [dateTo, setDateTo] = useState(dayjs.utc().startOf("day"));

  // const [activeTab, setActiveTab] = useState("revenue");

  // const CurrentView = views[activeTab];
  const navigate = useNavigate();
  const location = useLocation();

  
  const handleNavigate = (pathname) => {
   
    navigate(`/reports/${pathname}`);
  };

  const {
    sellers,
    seller_email,
    seller_email_selected,
    setSellerEmail,
    setSellerEmailSelected,
  } = useReports();

  return (
    <SectionWrapper sectionClass={"reports-system"}>
      <div className="margin--top-standard top-control">
        <div className="reports-refresh fd">
          <div style={{ display: "flex", gap: "1rem" }}>
          {PATHS.map(({ path, label }, i) => (
  <Button
    key={i}
    variant="contained"
    className={`${
      location.pathname.includes(path) ? "-black" : "-blue"
    }`}
    onClick={() => handleNavigate(path)}
  >
    {label}
  </Button>
))}
          </div>
          <div className="filter-reports fd">
            <div className="date-pickers fd">
              <DatePicker
                label="From"
                value={dateFrom}
                onChange={(newValue) => {
                  setDateFrom(dayjs.utc(newValue));
                  // const diff = dayjs.utc(newValue).diff(dateTo, 'day')

                  // if (Math.abs(diff) > 90) {
                  //     setDateTo(dayjs.utc(newValue).add(90, 'day'));
                  // }
                }}
                format="YYYY/MM/DD"
              />
              <DatePicker
                label="To"
                value={dateTo}
                onChange={(newValue) => {
                  setDateTo(dayjs.utc(newValue));
                  // const diff = newValue.diff(dateFrom, 'day')

                  // if (Math.abs(diff) > 90) {
                  //     setDateFrom(dayjs.utc(newValue).subtract(90, 'day'));
                  // }
                }}
                format="YYYY/MM/DD"
              />
              <Autocomplete
                id="seller-email"
                options={sellers}
                getOptionLabel={(option) => option?.email || ""}
                freeSolo
                disableClearable
                sx={{ minWidth: "250px" }}
                value={seller_email_selected || null}
                onChange={(e, newValue) => {
                  setSellerEmailSelected(newValue.email);
                }}
                inputValue={seller_email || ""}
                onInputChange={(e, newValue, reason) => {
                  if (reason === "reset" && !newValue) return;
                  setSellerEmail(newValue);
                }}
                renderOption={(params, option) => (
                  <li {...params} key={option.id}>
                    {option.email}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Seller Email"
                    variant="standard"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <Routes>
          <Route index element={<Navigate to="revenue" replace />} />
          <Route path="revenue" element={<ReportsRevenue dateFrom={dateFrom} dateTo={dateTo}/>} />
          <Route path="orders" element={<ReportsOrders dateFrom={dateFrom} dateTo={dateTo}/>} />
          <Route path="clients" element={<ReportsClients dateFrom={dateFrom} dateTo={dateTo}/>} />
        </Routes>
      {/* <CurrentView dateFrom={dateFrom} dateTo={dateTo} /> */}
    </SectionWrapper>
  );
};

export default ReportsSystem;
