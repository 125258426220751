/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import FidgetSpinner from '../../../sharedComponent/FidgetSpinner'
import { ToastDataContext } from "../../UnderRoot";
import './ManagementApplicationModal.scss';

const STATUS_SELECT = [
    {
        label: "Declined",
        value: 0
    },
    {
        label: "Pending",
        value: 1
    },
    {
        label: "Approved",
        value: 2
    },
    {
        label: "Cancelled",
        value: 3
    }
]

const TIER_SELECT = [
    {
        label: "Premium",
        value: 1
    },
    {
        label: "VIP",
        value: 2
    }
]

const ManagementApplicationModal = ( { application={}, handleClose=()=>"", updateHandler=()=>"" } ) => {
    const { customToast } = useContext( ToastDataContext )

    const [ status, setStatus ] = useState(1)
    const [ tier, setTier ] = useState(1)
    const [ remarks, setRemarks ] = useState( "" )
    const [ application_id, setApplicationId ] = useState( null )

    useEffect( () => {
        if ( application_id !== application.id ) {
            setApplicationId( application.id )
            setStatus( application.status )
            setTier( application.tier )
            setRemarks( application.remarks )
        }
    }, [ application ] )

    const [ submitSpinner, setSubmitSpinner ] = useState( false )

    const submitHandler = async e => {
        e.preventDefault()

        const data = { }

        // if ( Number( application.status ) !== status ) data.status = status
        data.status = status
        data.tier = tier
        // if ( Number( application.tier ) !== tier ) 
        if ( application.remarks !== remarks ) data.remarks = remarks
        
        setSubmitSpinner( true )

        const updateStatus = await updateHandler( data )

        setSubmitSpinner( false )

        if ( updateStatus?.ok ) {
            customToast( "Successfully updated application", "success" ) 
            handleClose()
        }
        else customToast( updateStatus?.message || "Error updating application" )
    }

    return <>
        <form className="application-modal" onSubmit={ submitHandler }>
            <ul className="fd">
                <li><TextField variant="standard" label="ID" fullWidth value={ application?.id } disabled /></li>
                <li><TextField variant="standard" label="CLIENT EMAIL" fullWidth value={ application?.email } disabled /></li>
                <li>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="status_select_label">
                            STATUS
                        </InputLabel>
                        <Select
                            id="status"
                            labelId="status_select_label"
                            label="STATUS"
                            value={ status }
                            onChange={ e => setStatus( e.target.value ) }>
                                {
                                    STATUS_SELECT.map( ( _status, _statusI ) => 
                                        <MenuItem value={ _status.value } key={ _statusI }>
                                            {
                                                _status.label
                                            }
                                        </MenuItem> )
                                }
                        </Select>
                    </FormControl>
                </li>
                <li>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="tier_select_label">
                            TIER
                        </InputLabel>
                        <Select
                            id="tier"
                            labelId="tier_select_label"
                            label="TIER"
                            value={ tier }
                            onChange={ e => setTier( e.target.value ) }>
                                {
                                    TIER_SELECT.map( ( _tier, _tierI ) => 
                                        <MenuItem value={ _tier.value } key={ _tierI }>
                                            {
                                                _tier.label
                                            }
                                        </MenuItem> )
                                }
                        </Select>
                    </FormControl>
                </li>
                <li>
                    <TextField 
                        id="application_remarks"
                        label="Remarks"
                        variant="standard"
                        rows={2}
                        multiline
                        fullWidth
                        value={remarks||""}
                        onChange={ e => setRemarks( e.target.value ) }
                    />
                </li>
            </ul>
            <div className="base-btns fd">
                <Button variant="contained" className="-gray" sx={ { width: "8rem" } }  onClick={ handleClose }>
                    Close
                </Button>
                {
                    submitSpinner ?
                    <FidgetSpinner fidgetClass="-btn" style={ { width: "8rem" } } /> :
                    <Button variant="contained" type="submit" sx={ { width: "8rem" } }>
                        Submit
                    </Button>
                }
            </div>
        </form>
    </>
}

export default ManagementApplicationModal   