/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios';
import { useEffect, useState } from "react";
import { getHeader } from '../../../shared/Authorization';
import SectionWrapper from "../../../wrapper/SectionWrapper"
import ArchiveTable from "./ArchiveTable";

const ROOT_URL = process.env.REACT_APP_API_URL

const ArchiveSystem = () => {
    const [ archivedOrders, setArchivedOrders ] = useState( [] )

    const headers = getHeader()

    const fetchArchivedOrders = ( init = false ) => {
        if ( archivedOrders.length && init ) return

        const url = ROOT_URL + '/deleted'

        axios.get( url, { headers } )
        .then( resp => setArchivedOrders( resp.data ) )
        .catch( err => console.log( err ) )
    }

    useEffect( () => {
        fetchArchivedOrders( true )
    }, [] )
    
    return (
        <SectionWrapper sectionClass={ "archive-system" }>
            <ArchiveTable orders={ archivedOrders } fetchTableData={ fetchArchivedOrders } />
        </SectionWrapper>
    )
}

export default ArchiveSystem;