import { Box, Switch, Button, Link, Typography } from "@mui/material";
import { useEffect, useState, useContext, useMemo } from "react";
import axios from "axios";

import { getHeader } from "../../../shared/Authorization";
import SectionWrapper from "../../../wrapper/SectionWrapper";
import TaskTable from "./TaskTable";
import { ToastDataContext } from "../../UnderRoot";
import { dateCleaner } from "../../../shared/CustomFunc";
import { useSelector } from "react-redux";
import TaskModal from "./TaskModal";

const ROOT_URL = process.env.REACT_APP_API_URL;

const TaskSystem = () => {
  const { value } = useSelector((state) => state.user);
  const { customToast } = useContext(ToastDataContext);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showFutureTasks, setShowFutureTasks] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isTasksLoading, setIsTasksLoading] = useState(false);
  const [tasksPagination, setTasksPagination] = useState([]);
  const [contactUrl, setContactUrl] = useState("");

  const role = useMemo(() => {
    if (value.role) return value.role;
    return "";
  }, [value]);

  const fetchTasks = async (new_page = 1) => {
    setIsTasksLoading(true);
    const headers = getHeader();

    const params = {
      page: new_page,
      per_page: 10,
    };

    let tasksUrl = "/todo/lists";

    if (showCompleted === false) {
      // tasksUrl = `/todo/lists?status[]=1`
      params["status[]"] = 1;
    }

    if (showFutureTasks) {
      params.futureTasks = "true";
    }

    await axios
      .get(ROOT_URL.concat(tasksUrl), { headers, params })
      .then((resp) => {
        const _tasks = resp.data.lists?.data;
        const newTasks = _tasks?.map((task) => {
          const formattedDate = dateCleaner(task.task_date);

          return { ...task, task_date: formattedDate };
        });

        // Reverse the array if its seller since backend sorting is different for seller and accountant
        if (role === 3) {
          newTasks.reverse();
        }

        setTasksPagination({
          total: resp.data.lists?.total || 0,
          current_page: resp.data.lists?.current_page || 1,
        });
        setTasks(newTasks);
      })
      .catch((err) => {
        customToast(err?.response?.data?.message);
      });
    setIsTasksLoading(false);
  };

  const fetchUser = async () => {
    const headers = getHeader();

    await axios
      .get(ROOT_URL + "/user", { headers })
      .then((resp) => {
        const _user = resp.data.user;
        setContactUrl(_user?.contact_url);
      })
      .catch((err) => {
        customToast(err?.response?.data?.message);
      });
  };

  const toggleShowCompleted = () => {
    setShowCompleted(!showCompleted);
  };

  const toggleShowFutureTasks = () => {
    setShowFutureTasks(!showFutureTasks);
  };

  useEffect(() => {
    fetchTasks();
    fetchUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line
  }, [showCompleted, showFutureTasks]);

  // Task form default values
  const [task, setTask] = useState({});

  // Modal
  const [openTasksModal, setOpenTasksModal] = useState(false);
  const [order, setOrder] = useState({ _order_id: "", _clientEmail: "" });

  const handleOpenTaskModal = async (id) => {
    if (!id) {
      setOpenTasksModal(true);
      return;
    }

    const headers = getHeader();

    const url = role === 2 ? "/todo/accountant" : "/todo";

    await axios
      .get(ROOT_URL + url + `/${id}`, { headers })
      .then((resp) => {
        const task = resp.data.todo;
        setTask(task);
      })
      .catch((err) => {
        customToast(err?.response?.data?.message);
        return;
      });

    setOpenTasksModal(true);
  };

  const handleCloseTaskModal = () => {
    setOpenTasksModal(false);
  };

  // Autofill order_id and client_email if user came from order modal
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get("orderId");
    const clientEmail = urlParams.get("clientEmail");

    if (!orderId && !clientEmail) return;

    setOrder({ _order_id: orderId, _clientEmail: clientEmail });
    setOpenTasksModal(true);
  }, []);

  return (
    <SectionWrapper sectionClass={"task-system"}>
      <Box
        sx={{
          display: "flex",
          padding: "0 0 2rem",
          width: "100%",
          alignItems: "baseline",
          gap: "1rem 2rem",
          flexWrap: "wrap",
        }}
      >
        <Typography
          sx={{ fontSize: "2rem", width: { xs: "100%", sm: "unset" } }}
        >
          My Tasks
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem 2rem",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box>
            <Switch
              checked={showCompleted}
              onChange={toggleShowCompleted}
              inputProps={{ "aria-label": "controlled" }}
              disabled={isTasksLoading}
            />
            <span>Show Completed</span>
          </Box>
          <Box>
            <Switch
              checked={showFutureTasks}
              onChange={toggleShowFutureTasks}
              inputProps={{ "aria-label": "controlled" }}
              disabled={isTasksLoading}
            />
            <span>Show Future Tasks</span>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          {role === 3 && (
            <Button
              variant="contained"
              className="-gray"
              sx={{ width: { xs: "100%", md: "unset" }, p: "0.5rem 1.5rem" }}
            >
              <Link
                href={`${contactUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                color="inherit"
              >
                Go to My Calendar
              </Link>
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => fetchTasks(tasksPagination.current_page)}
            sx={{ p: "0.5rem 1.5rem" }}
          >
            Refresh Data
          </Button>
          <Button
            variant="contained"
            onClick={() => handleOpenTaskModal(null)}
            sx={{ p: "0.5rem 1.5rem" }}
          >
            Add Task
          </Button>
        </Box>
      </Box>

      <TaskTable
        role={role}
        showCompleted={showCompleted}
        tasks={tasks}
        fetchTasks={fetchTasks}
        tasksPagination={tasksPagination}
        handleOpenModal={handleOpenTaskModal}
      />

      <TaskModal
        role={role}
        task={task}
        fetchTasks={fetchTasks}
        open={openTasksModal}
        handleClose={handleCloseTaskModal}
        _order_id={order._order_id}
        _clientEmail={order._clientEmail}
      />
    </SectionWrapper>
  );
};

export default TaskSystem;
