import React, { useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import { StyledTableCell } from "../../../shared/CustomFunc";
import { useDeleteProspect } from "../../../useHooks/useProspects";

const TableHeader = ({ headers }) => {
  return (
    <TableHead>
      <TableRow sx={{ borderTop: "solid 1px rgba(224, 224, 224, 1)" }}>
        {headers?.map((header) => {
          return (
            <StyledTableCell key={header.id} sx={{ whiteSpace: "nowrap" }}>
              {header.label}
            </StyledTableCell>
          );
        })}
        <StyledTableCell>
          <span style={{ marginLeft: "0.5rem" }}>Actions</span>
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

const ProspectTable = ({ handleEdit, prospects = [] }) => {
  const [identifier, setIdentifier] = useState("");

  const { mutateAsync: deleteProspect, isLoading: isDeleting } =
    useDeleteProspect();

  const handleEditClick = (id) => {
    handleEdit(id);
  };

  const handleDeleteClick = (id) => {
    if (!window.confirm("Do you want to delete this prospect?")) return;
    setIdentifier(id);
    deleteProspect(id);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHeader
            headers={[
              { id: "id", label: "ID" },
              { id: "google_url", label: "Google Map URL" },
              { id: "business_name", label: "Business Name" },
              { id: "website", label: "Website" },
              { id: "phone", label: "Phone" },
              { id: "seller", label: "Assigned Seller" },
            ]}
          />
          <TableBody>
            {prospects.map((prospect, index) => {
              // Remove last index logic and styling once pagination is done
              const lastIndex = prospects.length === index + 1 ? true : false;

              return (
                <TableRow
                  key={index}
                  sx={
                    lastIndex
                      ? { borderBottom: "solid 1px rgba(224, 224, 224, 1)" }
                      : {}
                  }
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {prospect.id}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      whiteSpace: "nowrap",
                      maxWidth: "18.75rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {prospect.url}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {prospect.business_name}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      whiteSpace: "nowrap",
                      maxWidth: "18.75rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {prospect.website}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {prospect.phone}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {prospect.user_email}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEditClick(prospect.id)}
                      >
                        <EditIcon sx={{ color: "#0094FF" }} />
                      </IconButton>

                      {isDeleting && identifier === prospect.id ? (
                        <FidgetSpinner
                          fidgetStyle={{ height: "1rem", width: "1rem" }}
                        />
                      ) : (
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteClick(prospect.id)}
                        >
                          <DeleteIcon sx={{ color: "#FF0000" }} />
                        </IconButton>
                      )}
                    </Box>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        sx={{ borderTop: "solid 1px rgba(224, 224, 224, 1)", borderBottom: "solid 1px rgba(224, 224, 224, 1)" }}
        rowsPerPageOptions={[]}
        component="div"
        rowsPerPage={rowsPerPage}
        showFirstButton
        showLastButton
        count={tasksPagination?.total || 0}
        page={tasksPagination?.current_page - 1 || 0}
        onPageChange={(e, newPage) => {
          fetchTasks(newPage + 1);
        }}
      /> */}
    </>
  );
};

export default ProspectTable;
