/* eslint-disable react-hooks/exhaustive-deps */
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

const TABLE_HEAD = [
    "ID",
    "COMPANY",
    "REVIEWS",
    "AMOUNT",
    "PAYMENT STATUS"
]

const KEYS = [
    "id",
    "companies",
    "order_review",
    "total_price",
    "payment_status"
]

const PAYMENT_STATUS_DROPDOWN = [
    { 
        value: 0,
        label: "New"
    }, { 
        value: 1,
        label: "Sent Invoice"
    }, { 
        value: 3,
        label: "Unpaid"
    }, { 
        value: 2,
        label: "Paid"
    }, { 
        value: 11,
        label: "Payment Reminder 1"
    }, { 
        value: 12,
        label: "Payment Reminder 2"
    }
]

const ClientsModalHistory = ( { children, client } ) => {
    const [ orders, setOrders ] = useState( [] );

    const loadOrders = () => {

        if ( client?.orders?.length < 1 ) {
            setOrders( [] )
            return
        }

        const notArchivedOrders = client?.orders?.filter( order => order?.is_archived !== 1 )

        setOrders( notArchivedOrders )
    }

    useEffect( () => {
        loadOrders()
    }, [ client ] )

    const cellGenerator = ( order, key ) => {
        if ( key === "company" ) {
            return order?.[key]?.name
        }

        if ( key === "order_review" ) {
            return order?.[key]?.length || 0
        }

        if ( key === "total_price" ) {
            const total_price = ( Number( order?.order_review?.length ) * Number( order?.unit_cost ) ).toFixed( 2 )
            return total_price
        }

        if ( key === "payment_status" ) {
            return PAYMENT_STATUS_DROPDOWN.find( PAYMENT_STATUS => PAYMENT_STATUS.value === Number( order?.[key] ) )?.label || ""
        }

        return order?.[key] || ""
    }

    const [ current_page, setCurrentPage ] = useState( 0 )

    const total_amount = useMemo( () => {
        if ( orders?.length < 1 ) {
            return 0
        }

        return orders?.reduce( ( subTotal, order ) => {
            const order_total = ( Number( order?.order_review?.length ) * Number( order?.unit_cost ) )

            return subTotal + order_total
        }, 0 ).toFixed( 2 )
    }, [ orders ] )

    return (
        <>
            <TableContainer
                className="table-container -client-orders-history"
                component={ Paper }>
                    <Table>
                        <TableHead>
                            <TableRow>
                                { TABLE_HEAD.map( ( label, i ) =>  <TableCell key={ i }> { label } </TableCell> ) }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                orders?.length > 0 &&
                                orders
                                .slice( current_page * 5, current_page * 5 + 5 )
                                .map( ( order, orderI ) => <TableRow key={ orderI }>
                                    {
                                        KEYS.map( ( key, keyI ) => <TableCell key={ keyI } > 
                                            { cellGenerator( order, key ) } 
                                        </TableCell> )
                                    }
                                </TableRow> )
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={ [] }
                        rowsPerPage={ 5 }
                        count={ orders?.length }
                        page={ current_page }
                        onPageChange={ ( e, newPage ) => setCurrentPage( newPage ) }
                    />
            </TableContainer>
            <div className="clients-order-history-extra">
                <p>
                    <span>Total Orders:</span>
                    <span> { orders?.length || 0 } </span>
                </p>
                <p>
                    <span>Total Amount:</span>
                    <span> { total_amount || 0 } </span>
                </p>
            </div>
            <div className="base-btns">
                { children }
            </div>
        </>
    )
}

export default ClientsModalHistory;