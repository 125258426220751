/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import TextEditor, { useNewEditor } from "../../../sharedComponent/TextEditor";
import { cleanClients, fetchMessageClients, fetchSentMessages, sendMessage } from "../../../store/messagesSlice";
import { ToastDataContext } from "../../UnderRoot";
import './MessageModal.scss';

const MessageModal = ( { isOpen=false, handleClose=()=>"", defaultSubject="", sendToDefault=null } ) => {
    const dispatch = useDispatch()

    const { customToast } = useContext( ToastDataContext )

    const clients = useSelector( state => state.messages.value.clients )

    const editor = useNewEditor( "<p></p>", "Message" )

    const [ submitSpinner, setSubmitSpinner ] = useState( false )
    const [ subject, setSubject ] = useState( "" )
    const [ recipient_email, setRecipient ] = useState( "" )
    // const [ messageBody, setMessageBody ] = useState( "" )

    const resetEverything = () => {
        setSubject( defaultSubject )
        setRecipient("")
        // setMessageBody("")
        setSubmitSpinner(false)
    }

    useEffect( () => {
        if ( isOpen ) {
            resetEverything()
            dispatch( fetchMessageClients() )
        }

        return () => dispatch( cleanClients() )
    }, [ isOpen ] )

    const sendHandler = async e => {
        e.preventDefault()

        if ( !subject ) {
            customToast( "Subject cannot be empty" )
            return
        }

        if ( editor.isEmpty ) {
            customToast( "Message cannot be empty" )
            return
        }

        const message = editor.getHTML()

        if ( !recipient_email && !sendToDefault ) {
            customToast( "Recipient cannot be empty" )
            return
        }

        setSubmitSpinner( true )

        const status = await dispatch( sendMessage( { subject, message, recipient_email: sendToDefault || recipient_email } ) )
        setSubmitSpinner( false )

        if ( status.ok ) {
            customToast( status.message, "success" )
            dispatch( fetchSentMessages() )
            handleClose()
        }
    }

    return (
        <>
            <h3>New Message</h3>
            <form className="message-form" onSubmit={ sendHandler }>
                <ul>
                    <li>
                        <TextField 
                            label="Subject"
                            value={ subject }
                            onChange={ e => setSubject( e.target.value ) }
                            fullWidth
                            variant="standard" />
                    </li>
                    <li>
                        <FormControl 
                            variant="standard"
                            fullWidth>
                            <InputLabel id="recepient_label">
                                Recipient
                            </InputLabel>
                            <Select
                                labelId="recepient_label"
                                id="recepient"
                                disabled={ sendToDefault ? true : false }
                                value={ sendToDefault || recipient_email }
                                defaultValue={ sendToDefault || "" }
                                onChange={ e => setRecipient( e.target.value ) }
                                MenuProps={ { style: { height: "16.5rem", maxWidth: "10rem" } } }
                            > 
                                {
                                    [ "", ...clients ].map( ( client, clientI ) => 
                                    <MenuItem 
                                        key={ clientI }
                                        value={ client?.email || "" }>
                                            { client?.email }
                                    </MenuItem> )
                                }
                            </Select>
                        </FormControl>
                    </li>
                    <li>
                        { editor && 
                            <TextEditor 
                                editable={ true } 
                                editor={ editor }
                                editorHeight={10}
                                hasMaxHeight={true} /> }
                    </li>
                </ul>
                <div className="base-btns fd">
                    <Button 
                        variant="contained" 
                        sx={ { width: "8rem" } } 
                        onClick={ () => handleClose() } 
                        className="-gray">
                        Cancel
                    </Button>
                    { submitSpinner ?
                        <FidgetSpinner style={ { width: "8rem" } }></FidgetSpinner> :
                        <Button 
                            variant="contained" 
                            sx={ { width: "8rem" } }
                            type={ "submit" }>
                            Send
                        </Button>
                     }
                </div>
            </form>
        </>
    )
}

export default MessageModal;