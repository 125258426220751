import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ProspectModalDetails from "./ProspectModalDetails";
import ProspectModalEmail from "./ProspectModalEmail";

const generateView = (activeTab, props) => {
  if (activeTab === "prospect_details") {
    return (
      <ProspectModalDetails
        prospectId={props.prospectId}
        handleClose={props.handleClose}
        role={props.role}
      />
    );
  } else if (activeTab === "email") {
    return (
      <ProspectModalEmail
        prospectId={props.prospectId}
        handleClose={props.handleClose}
      />
    );
  }
};

const ProspectModal = ({ prospectId, handleClose, role }) => {
  const [activeTab, setActiveTab] = useState("prospect_details");

  const viewProps = { prospectId, handleClose, role };
  const CurrentView = generateView(activeTab, viewProps);

  return (
    <Box sx={{ padding: "2rem" }}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Typography
            variant="body1"
            fontSize="1.5rem"
            onClick={() => setActiveTab("prospect_details")}
            fontWeight={700}
            sx={{
              cursor: "pointer",
              color: activeTab === "prospect_details" ? "black" : "#0094FF",
            }}
          >
            Prospect Details
          </Typography>
          <Typography
            variant="body1"
            fontSize="1.5rem"
            onClick={() => setActiveTab("email")}
            fontWeight={700}
            sx={{
              cursor: "pointer",
              color: activeTab === "email" ? "black" : "#0094FF",
            }}
          >
            Email
          </Typography>
        </Stack>
        {activeTab === "prospect_details" && (
          <Typography variant="body1" fontSize="1rem">
            {`Added on ${"2023-09-19 19:01:23"}`}
          </Typography>
        )}
      </DialogTitle>

      <DialogContent sx={{ mt: "2rem" }}>{CurrentView}</DialogContent>
    </Box>
  );
};

export default ProspectModal;
