/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { getHeader } from "../../../shared/Authorization";
import { useContext, useEffect, useState } from "react";
import { ErrorHandler } from "../../../shared/CustomFunc";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import "./ManagementOrderModalClientLinks.scss";
import useClients, { useGetGoogleRatings } from "../../../useHooks/useClients";
import { ToastDataContext } from "../../UnderRoot";
import CompanyRating from "../clientSystem/CompanyRating";
import Loading from "../../../sharedComponent/Loading";
import CompanyLinksTable from "../clientSystem/CompanyLinksTable";

const ROOT_URL = process.env.REACT_APP_API_URL;

const ManagementOrderModalClientLinks = ({
  new_order = false,
  client_id = null,
  company_name = "",
  company_url = "",
  setCompanyName = () => "",
  setCompanyUrl = () => "",
  fetchTableData = () => "",
  tabReviewsPrerequisite,
}) => {
  const { customToast } = useContext(ToastDataContext);

  const [client, setClient] = useState({});
  const [companies, setCompanies] = useState([]);
  const [company_id, setCompanyId] = useState(null);

  const [formState, setFormState] = useState({
    name: "",
    url: "",
  });

  const { addClientLinks, updateClientLink, removeClientLink } = useClients();

  const { ratings, clearRatings, getGoogleRatings, isFetchingRatings } =
    useGetGoogleRatings();

  const [fetchSpinner, setFetchSpinner] = useState(false);

  const fetchClient = async (update = false, updateId = null) => {
    const headers = getHeader();

    setCompanies([]);
    setFetchSpinner(true);

    const status = await axios
      .get(ROOT_URL + "/client/get/" + client_id, { headers })
      .then((resp) => {
        const _client = resp.data.client;

        setClient(_client);
        setCompanies(_client?.companies || []);

        const foundCompany = _client?.companies.find(
          (_company) => _company.id === company_id
        );

        if (foundCompany) {
          if (foundCompany.name !== company_name)
            setCompanyName(foundCompany.name);
          if (foundCompany.url !== company_url) setCompanyUrl(foundCompany.url);
        }

        return { ok: true };
      })
      .catch((err) => {
        console.log(err);
        return ErrorHandler(err, customToast, "Error fetching client links");
      });

    setFetchSpinner(false);

    if (update) {
      if (status.ok) {
        if (!new_order) await fetchTableData();
      }
    }
  };

  useEffect(() => {
    if (client?.id !== client_id) if (client_id) fetchClient();
    return () => {
      setClient({});
      setCompanies([]);
      clearRatings();
    };
  }, [client_id]);

  useEffect(() => {
    if (!company_url) return;
    console.log(company_url);
    getGoogleRatings({ url: company_url });
  }, [company_url]);

  const companySelectHandler = (name) => {
    const company = companies?.find((_company) => _company.name === name);

    if (!company?.name) {
      setCompanyName("");
      setCompanyUrl("");
      setCompanyId(null);
      return;
    }

    setCompanyName(company.name);
    setCompanyUrl(company.url);
    setCompanyId(company.id);
  };

  const handleChange = (field, e) => {
    setFormState((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const companyLinksSubmit = async () => {
    const foundLinkExisting = companies?.find(
      (c_link) => c_link.url === formState.url
    );

    if (foundLinkExisting) {
      customToast("URL already exists for this client");
      return;
    }

    const status = await addClientLinks([formState], client_id);

    if (status.ok) {
      fetchClient();
      setFormState({ name: "", url: "" });
    } else {
      ErrorHandler(status.err, customToast, status.err.response?.data?.message);
      setCompanies(client?.companies || []);
    }
  };

  const updateLinksSubmit = async (link_id, _data, successMessage = "") => {
    const status = await updateClientLink(link_id, _data);

    if (status.ok) {
      if (successMessage) customToast(successMessage, "success");
      else customToast("Successfully updated client link", "success");

      fetchClient(true, link_id);

      return { ok: true };
    } else {
      return ErrorHandler(
        status.err,
        customToast,
        "Error updating client link"
      );
    }
  };

  const removeLinksSubmit = async (link_id) => {
    const status = await removeClientLink(link_id);

    if (status.ok) {
      customToast("Successfully deleted client link", "success");

      fetchClient(true, link_id);

      if (link_id === company_id) companySelectHandler("");

      return { ok: true };
    } else {
      return ErrorHandler(
        status.err,
        customToast,
        "Error updating client link"
      );
    }
  };

  return (
    <>
      <div className="fd company-selection">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: "1rem",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="company_label">COMPANY</InputLabel>
            <Select
              labelId="company_label"
              variant="standard"
              label="COMPANY"
              value={company_name ?? ""}
              onChange={(e) => companySelectHandler(e.target.value)}
            >
              {companies.map((_company, i) => (
                <MenuItem key={i} value={_company.name}>
                  {" "}
                  {_company.name}{" "}
                </MenuItem>
              ))}
              <MenuItem value={""}>NONE</MenuItem>
            </Select>
            {!tabReviewsPrerequisite && (
              <span
                style={{
                  fontSize: "0.75rem",
                  color: "red",
                  marginTop: "0.25rem",
                }}
              >
                Please select company
              </span>
            )}
          </FormControl>
          <TextField
            id="company-url"
            label="COMPANY URL"
            value={company_url || ""}
            disabled
            fullWidth
            // onChange={ e => setCompanyUrl( e.target.value ) }
            variant="standard"
            required
          />
        </Box>
      </div>

      <Box sx={{ p: "2rem 0", display: "flex", justifyContent: "center" }}>
        {isFetchingRatings ? (
          <Loading />
        ) : ratings ? (
          <CompanyRating ratings={ratings} />
        ) : (
          <Typography color="rgba(0, 0, 0, 0.38)">
            Use valid Google Maps URL to preview rating.
          </Typography>
        )}
      </Box>

      <CompanyLinksTable
        existing={true}
        company_links={companies || []}
        tableSpinner={fetchSpinner}
        {...{
          companyLinksSubmit,
          updateLinksSubmit,
          removeLinksSubmit,
        }}
      />

      <Box
        sx={{
          pt: "2rem",
          display: "flex",
          gap: "1rem",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          onChange={(e) => handleChange("name", e)}
        />
        <TextField
          fullWidth
          label="URL"
          variant="outlined"
          onChange={(e) => handleChange("url", e)}
        />
        <Button
          variant="contained"
          sx={{ minWidth: "6rem" }}
          type="button"
          onClick={companyLinksSubmit}
        >
          Add
        </Button>
      </Box>
    </>
  );
};

export default ManagementOrderModalClientLinks;
