import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import { StyledTableCell } from "../../../shared/CustomFunc";
import { useState } from "react";
import { useDeleteEmailTemplate } from "../../../useHooks/useProspects";

const TableHeader = ({ headers }) => {
  return (
    <TableHead>
      <TableRow sx={{ borderTop: "solid 1px rgba(224, 224, 224, 1)" }}>
        {headers?.map((header) => {
          return (
            <StyledTableCell key={header.id} sx={{ whiteSpace: "nowrap" }}>
              {header.label}
            </StyledTableCell>
          );
        })}
        <StyledTableCell>
          <span style={{ marginLeft: "0.5rem" }}>Actions</span>
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

const EmailTemplatesTable = ({
  emailTemplates = [],
  handleEdit,
  setOpenTemplateModal,
}) => {
  const [identifier, setIdentifier] = useState("");

  const { mutateAsync: deleteTemplate, isLoading: isDeleting } =
    useDeleteEmailTemplate();

  const handleNewTemplate = () => {
    setOpenTemplateModal(true);
  };

  const handleEditClick = (id) => {
    handleEdit(id);
  };

  const handleDeleteClick = (id) => {
    if (!window.confirm("Do you want to delete this email template?")) return;
    setIdentifier(id);
    deleteTemplate(id);
  };

  return (
    <TableContainer>
      <Box
        sx={{
          pb: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography fontWeight={700}>Email Templates</Typography>
        <Button
          type="button"
          onClick={handleNewTemplate}
          disableRipple
          sx={{
            p: "0.5rem 1.5rem !important",
            textTransform: "unset",
            background: "none !important",
            borderRadius: "10px",
            border: "1px solid #30A8FF",
            minHeight: "unset !important",
            fontSize: "0.875rem",
          }}
        >
          New Template
        </Button>
      </Box>
      <Table>
        <TableHeader
          headers={[
            { id: "id", label: "ID" },
            { id: "name", label: "Template Name" },
            { id: "sent_count", label: "Sent" },
            { id: "open_count", label: "Opens" },
            { id: "click_count", label: "Clicks" },
            { id: "open_rate", label: "Open Rate" },
            { id: "ctr", label: "CTR" },
          ]}
        />
        <TableBody>
          {emailTemplates.map((template, index) => {
            // Remove last index logic and styling once pagination is done
            const lastIndex =
              emailTemplates.length === index + 1 ? true : false;

            return (
              <TableRow
                key={index}
                sx={
                  lastIndex
                    ? { borderBottom: "solid 1px rgba(224, 224, 224, 1)" }
                    : {}
                }
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {template.id}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "18.75rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {template.name}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {template.sent_count}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "18.75rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {template.open_count}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {template.click_count}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {template.open_rate * 100}%
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {template.ctr * 100}%
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditClick(template.id)}
                    >
                      <EditIcon sx={{ color: "#0094FF" }} />
                    </IconButton>

                    {isDeleting && identifier === template.id ? (
                      <FidgetSpinner
                        fidgetStyle={{ height: "1rem", width: "1rem" }}
                      />
                    ) : (
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteClick(template.id)}
                      >
                        <DeleteIcon sx={{ color: "#FF0000" }} />
                      </IconButton>
                    )}
                  </Box>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmailTemplatesTable;
