/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { Chip, Input } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import './TextArray.scss';

const regexExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const TextArray = ( { id="", arrayValue=[], handleAdd=null, handleDelete=null, handleError=null, sx=null, containerClass="", isEditing=false, handleIsEditing=null } ) => {
    const inputRef = createRef()
    const [ inputValue, setInputValue ] = useState("");

    const handleChange = e => {
        const value = e.target.value;

        if ( value[ value.length - 1 ] === " " ) {
            const strippedValue = value.replace(/ /g, '');

            if ( !strippedValue ) {
                if ( handleError ) handleError( "No whitespace for email." )
                return
            }

            if ( !regexExp.test( inputValue ) ) {
                if ( handleError ) handleError( "Value must be an email." )
                return
            }

            const findValue = arrayValue.find( item => item === strippedValue );
            
            if ( findValue ) {
                if ( handleError ) handleError( "This email already exists." )
                setInputValue( "" )
                return
            }

            handleAdd( strippedValue )
            setInputValue( "" )
            
        } else {
            setInputValue( value )
        }
    }

    const [ activeClass, setActiveClass ] = useState("");

    useEffect( () => {
        if ( !handleIsEditing ) return

        // if ( inputValue && !isEditing ) handleIsEditing( true )
        // else if ( !inputValue && isEditing ) handleIsEditing( false )

        if ( activeClass && !isEditing ) handleIsEditing( true )
        else if ( !activeClass && isEditing ) handleIsEditing( false )

    // }, [ inputValue, isEditing ] )
    }, [ activeClass, isEditing ] )

    const enterHandler = e => {
        if ( e.keyCode === 13 ) {
            if ( inputValue ) handleAdd( inputValue )
            setInputValue("")
        }
    }

    return (
        <div className={ `text-array-container fd ${ containerClass } ${ activeClass }` } style={ sx || {} } onClick={ () => inputRef.current.firstChild.focus()}>
            {
                arrayValue.map( ( item, i ) => {
                    return <Chip 
                        size="small" 
                        onDelete={ () => handleDelete( item ) } 
                        label={ item?.email || item } 
                        key={ i }
                        style={
                            {
                                backgroundColor: "#48D16F",
                                color: "white"
                            }
                        }
                        />
                } )
            }
            <Input
                id={ id }
                ref={ inputRef }
                value={ inputValue }
                onChange={ handleChange }
                onKeyUp={ enterHandler }
                onFocus={ () => setActiveClass( "-text-array-focused" ) } 
                onBlur={ () => setActiveClass( "" ) } />
        </div>
    )
}

export default TextArray;