import React, { useMemo, useState } from "react";
import SectionWrapper from "../../../wrapper/SectionWrapper";
import { Box, Button, Dialog, IconButton, TextField } from "@mui/material";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import ProspectTable from "./ProspectTable";
import ProspectModal from "./ProspectModal";
import ImportCsvModal from "./ImportCsvModal";
import {
  useAddProspect,
  useGetEmailTemplates,
  useGetProspects,
} from "../../../useHooks/useProspects";
import FidgetSpinner from "../../../sharedComponent/FidgetSpinner";
import EmailTemplatesTable from "./EmailTemplatesTable";
import EmailTemplateModal from "./EmailTemplateModal";

const PATHS = [
  { path: "my_prospects", label: "My Prospects" },
  { path: "email_templates", label: "Email Templates" },
];

const ProspectSystem = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (pathname) => {
    navigate(`/prospects/${pathname}`);
  };

  const user = useSelector((state) => state.user.value);

  const [url, setUrl] = useState("");
  const [prospectId, setProspectId] = useState("");
  const [templateId, setTemplateId] = useState(null);
  const [csvModal, setCsvModal] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);

  const { data: prospects } = useGetProspects();
  const { data: emailTemplates } = useGetEmailTemplates();
  const { mutateAsync: addProspect, isLoading: isCreating } = useAddProspect();

  const modalOpen = useMemo(() => {
    if (prospectId) return "prospectModal";
    if (csvModal) return "csvModal";
    if (templateId) return "templateModal";
    if (openTemplateModal) return "templateModal";

    return "";
  }, [prospectId, csvModal, templateId, openTemplateModal]);

  const handleAddProspect = () => {
    addProspect({ url });
  };

  const handleImport = () => {
    setCsvModal(true);
  };

  const handleProspectEdit = async (id) => {
    setProspectId(id);
  };

  const handleTemplateEdit = async (id) => {
    setTemplateId(id);
  };

  const handleClose = () => {
    setProspectId("");
    setTemplateId(null);
    setCsvModal(false);
    setOpenTemplateModal(false);
  };

  return (
    <SectionWrapper sectionClass={"prospect-system"}>
      <Box
        sx={{
          display: "flex",
          padding: "0 0 2rem",
          width: "100%",
          alignItems: "center",
          gap: "1rem 2rem",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            mr: "auto",
            display: "flex",
            gap: "1rem",
            width: { xs: "100%", sm: "unset" },
          }}
        >
          {PATHS.map((path, index) => {
            if (user?.role !== 1 && path.path === "email_templates")
              return null;

            return (
              <Button
                key={index}
                variant="contained"
                onClick={() => handleNavigate(path.path)}
                className={`${
                  location.pathname.includes(path.path) ? "-black" : "-blue"
                }`}
              >
                {path.label}
              </Button>
            );
          })}
        </Box>

        {location.pathname.includes("my_prospects") && (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "1rem",
            }}
          >
            <TextField
              label="Enter Google URL"
              value={url}
              variant="standard"
              onChange={(e) => setUrl(e.target.value)}
              sx={{ minWidth: "18rem" }}
            />
            <Button
              variant="contained"
              type="button"
              disabled={isCreating}
              onClick={handleAddProspect}
              sx={{
                width: { xs: "100%", md: "unset" },
                minWidth: "8rem",
                p: "0.5rem 1.5rem",
              }}
            >
              {isCreating ? (
                <FidgetSpinner
                  fidgetStyle={{ width: "1rem", height: "1rem" }}
                />
              ) : (
                "Add Prospect"
              )}
            </Button>
            <IconButton
              variant="contained"
              disableRipple
              onClick={handleImport}
              sx={{
                backgroundColor: "#30A8FF",
                alignSelf: "center",
                p: "0.75rem",
              }}
            >
              <FontAwesomeIcon
                icon={solid("upload")}
                size="1x"
                style={{
                  color: "#ffffff",
                }}
              />
            </IconButton>
          </Box>
        )}
      </Box>

      <Routes>
        <Route index element={<Navigate to="my_prospects" replace />} />
        <Route
          path="my_prospects"
          element={
            <ProspectTable
              handleEdit={handleProspectEdit}
              prospects={prospects}
            />
          }
        />
        <Route
          path="email_templates"
          element={
            user?.role === 1 ? (
              <EmailTemplatesTable
                emailTemplates={emailTemplates || []}
                handleEdit={handleTemplateEdit}
                setOpenTemplateModal={setOpenTemplateModal}
              />
            ) : (
              <Navigate to="my_prospects" replace />
            )
          }
        />
      </Routes>

      <Dialog
        open={modalOpen.length > 0}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        scroll="body"
      >
        {modalOpen === "csvModal" && (
          <ImportCsvModal handleClose={handleClose} />
        )}
        {modalOpen === "prospectModal" && (
          <ProspectModal
            prospectId={prospectId}
            handleClose={handleClose}
            role={user?.role}
          />
        )}
        {modalOpen === "templateModal" && (
          <EmailTemplateModal
            templateId={templateId}
            handleClose={handleClose}
          />
        )}
      </Dialog>
    </SectionWrapper>
  );
};

export default ProspectSystem;
